"use client";
import { useRef, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { postPageView } from "@pulsex/services";
export default function usePageViewCollector(config, documentId, type, pathname) {
    const analyticsSentRef = useRef();
    function generateUserId() {
        return `user-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    }
    const userId = pathname && documentId && type ? generateUserId() : undefined;
    const query = useQuery(["PAGE_VIEW", pathname], () => postPageView(config, userId, documentId, type), {
        cacheTime: 1000 * 60 * 10,
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: !!userId,
    });
    useEffect(() => {
        if (query.isSuccess) {
            analyticsSentRef.current = pathname;
        }
    }, [query.isSuccess, pathname]);
    return {
        isLoading: query.isLoading,
        data: query.isSuccess ? query.data : null,
    };
}
