import CameraIcon from "@/icons/CameraIcon";
import { PlayIcon } from "@/icons/PlayIcon";
import { cn } from "@pulsex/utils";
import React from "react";

type MediaProps = {
  className?: string;
  url?: string;
  type?: "video" | "camera";
  color?: string;
};

export const MediaIcon = ({
  className,
  url,
  type = "camera",
  color,
}: MediaProps) => {
  return (
    <>
      <div
        className={cn(
          "h-7 w-7 flex items-center justify-center bg-masterRed rounded-full",
          className
        )}
        style={{ backgroundColor: color }}
      >
        {type === "camera" ? <CameraIcon size={15} /> : <PlayIcon size={16} />}
      </div>
    </>
  );
};
