"use client";
import { WidgetProps } from "@/types/types";
import SectionWrap from "@/components/SectionWrap/Index";
import { Card } from "@/components/Card/Index";
import pulseConfig from "@/pulsex.config";
import { cn } from "@pulsex/utils";
import { AsideMoreColumn } from "./Aside";
import { Logo } from "@/icons/Logo";
import { useLoadMore } from "@pulsex/hooks";
import SpinnerIcon from "@/icons/SpinnerIcon";
import { locale } from "@/locale";

export const SubSectionWidget = ({
  data,
  hideCategory,
  pageData,
  dailyPdf,
}: WidgetProps) => {
  const LoadQ = useLoadMore(pulseConfig, data?.loadMoreLink, data?.posts);
  const fetchNext = () => {
    if (LoadQ.hasNextPage && !LoadQ.isFetchingNextPage) LoadQ.fetchNextData();
    else console.log("no more data");
  };
  if (!data?.posts?.length) {
    return (
      <>
        <div className="flex items-center h-screen flex-col space-y-4 max-md:space-y-6 py-10">
          <Logo className="fill-black" size={70} />

          <div className="text-3xl font-bold max-md:text-2xl">
            {locale.emptyPage}
          </div>

          <p className="text-lg text-[#4c4c4c] flex max-md:px-4 max-md:text-center">
            {locale.emptyPageMessage}
          </p>
          <a
            href="/"
            className="text-masterRed text-xl border-none rtl:mr-1 ltr:ml-1 "
          >
            {locale.HomePage}
          </a>
        </div>
      </>
    );
  }
  const videoData = data?.mainPost?.postType === "video";
  const title = pageData?.title?.includes("-")
    ? pageData?.title.split("-")[0]
    : "";

  const colorTheme = title ? `var(--theme-${title.replace(/ /g, "-")})` : "";

  return (
    <SectionWrap className="pt-4 pb-14 items-stretch md:flex">
      <AsideMoreColumn
        data={data}
        pageTitle={pageData?.title}
        dailyPdf={dailyPdf}
      />

      <div className="flex flex-col ltr:items-end max-md:py-8 w-full">
        <div
          className="grid grid-cols-2 lg:grid-cols-4 w-full md:pt-10 lg:pt-0"
          dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
        >
          {LoadQ?.data &&
            LoadQ?.data?.map((item: any, index: any) => (
              <Card
                widgetTitle={data?.title}
                widgetColor={
                  data?.widgetColor ||
                  (pageData?.title === "كاريكاتور" && "var(--theme-opinion)") ||
                  (pageData?.title === "كومكس" && "var(--theme-comics)") ||
                  (pageData?.title === "قضايا وآراء" &&
                    "var(--theme-opinion)") ||
                  colorTheme ||
                  item?.tag?.color
                }
                className={cn(
                  "mr-4 max-md:mr-3 max-md:mb-3 mb-4",

                )}
                cardOrientation="v"
                data={item}
                customSummary="line-clamp-4"
                cropSize={
                  item?.postType === "opinion-caricature" ? "square" : "wide"
                }
                customCategory="mb-1.5"
                key={index}
                showCategory={hideCategory}
                showMediaIcon={videoData}
                showAuthor={!videoData}
                resolution={1.5}
                customTitle="max-lg:text-2xl"
              />
            ))}
          {LoadQ?.isLoading
            ? Array.from({ length: 8 }).map((_, index) => (
                <div
                  key={index}
                  className="h-[253px] w-full bg-greyBackground animate-pulse"
                />
              ))
            : ""}
        </div>
        {/* <div className=" flex space-x-2  rtl:space-x-reverse  w-fit pt-4">
          {renderPageButtons()}
          {isFetchingNextPage || (isLoading && <p>...</p>)}
        </div> */}
        {LoadQ.hasNextPage && (
          <div className="py-6">
            <div
              className={cn(
                "flex space-x-2.5 w-fit cursor-pointer mx-auto",
                "text-lg text-black px-3 py-2",
                "border border-black",
                LoadQ?.isFetching
                  ? "border-transparent cursor-auto"
                  : "lg:hover:border-masterRed lg:hover:text-masterRed"
              )}
              onClick={() => fetchNext()}
            >
              {LoadQ?.isFetching ? (
                <SpinnerIcon className={"fill-masterRed"} />
              ) : (
                locale.showMore
              )}
            </div>
          </div>
        )}
        {!LoadQ.isLoading && !LoadQ.hasNextPage && (
          <div className={cn("flex space-x-2.5 w-fit py-8 mx-auto")}>
            <div className="text-lg text-black px-3 py-2">{locale.noMore} </div>
          </div>
        )}
      </div>
    </SectionWrap>
  );
};
