import pulseConfig from "@/pulsex.config";
import { MangoXImage } from "@pulsex/ui";

type ImageProps = {
  data: any;
  cropSize?: string;
  className?: string;
  needsMediaURL?: boolean;
  resolution?: number;
  priority?: boolean;
};

interface ImageSize {
  width: number;
  height: number;
  paddingTop: any;
}
const ImageUI = ({
  data,
  className,
  cropSize = "medium",
  needsMediaURL = false,
  resolution,
  priority,
}: ImageProps) => {
  if (data?.image && data?.image === pulseConfig.MEDIA_URL) return;
  return (
    <MangoXImage
      config={pulseConfig}
      data={data}
      className={className}
      cropSize={cropSize}
      needsMediaURL={needsMediaURL}
      resolution={resolution}
      priority={priority}
    />
  );
};

export default ImageUI;
