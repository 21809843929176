"use client";
import { cn } from "@pulsex/utils";
import { Category } from "@/ui/Category/Index";
import Link from "next/link";
import Author from "@/ui/Author/Index";
import { Time } from "@/ui/Time/Index";
import ImageUI from "@/ui/Image/Index";
import { Summary } from "@/ui/Summary/Index";

interface CardProps {
  className?: string;
  showSummary?: boolean;
  showImage?: boolean;
  showAuthor?: boolean;
  showCategory?: boolean;
  customBackground?: string;
  showMediaIcon?: boolean;
  customImage?: string;
  customTitle?: string;
  mediatype?: "video" | "podcast";
  data?: any;
  customCategory?: string;
  customTime?: string;
  showTime?: boolean;
  customSummary?: string;
  customAuthor?: string;
  authorTheme?: string;
  cropSize?: string;
  resolution?: number;
  isMobile?: any;
  widgetTitle?: any;
}
export const OpinionCard = ({
  showSummary = true,
  showCategory = true,
  showAuthor = true,
  showImage = true,
  showMediaIcon = false,
  className,
  customBackground,
  customImage,
  customTitle,
  data,
  customCategory,
  customTime,
  showTime = false,
  customSummary,
  customAuthor,
  cropSize,
  resolution,
  widgetTitle,
}: CardProps) => {
  const TermValue =
    data?.terms?.[0]?.title === "على الغلاف"
      ? data?.terms?.[1]
      : data?.terms?.[0];
  const CategoryValue =
    data?.tag?.title !== widgetTitle?.trim() ? data?.tag : TermValue;
  if (!data) return;
  return (
    <div
      className={cn(
        "flex flex-col  relative group overflow-hidden bg-[#c9b058] p-2.5",
        className
      )}
    >
      <div
        className={cn(
          "flex flex-col rtl:items-start ltr:items-end max-sm:ml-0",
          customBackground
        )}
      >
        {(showCategory && CategoryValue) || true ? (
          <Category item={CategoryValue} className={cn("pb-1 !text-white")} />
        ) : (
          ""
        )}

        {showAuthor ? (
          <Author
            className="cursor-pointer text-2xl lg:text-3xl font-medium text-black font-amine-light"
            item={data?.author}
          />
        ) : (
          ""
        )}

        <Link
          aria-label={data?.title}
          href={
            data?.url
              ? `${data?.url}`
              : data?.publicUrl
              ? `${data?.publicUrl}`
              : " "
          }
          className={cn(
            "cursor-pointer line-clamp-2 relative max-sm:my-1.5 lg:mt-2",
            "text-white text-2xl max-sm:text-xl font-black leading-6",
            customTitle
          )}
        >
          {data?.title}
        </Link>

        {(showSummary && (data?.summary || data?.textContent)) || true ? (
          <Summary
            className={cn(
              "rtl:pl-1.5 ltr:pr-1.5 max-w-full line-clamp-4",
              customSummary
            )}
          >
            {data?.summary ? data?.summary : data?.textContent}
          </Summary>
        ) : (
          ""
        )}
      </div>

      {(showTime && data?.displayDate) || true ? (
        <Time
          item={data?.displayDate}
          className={cn(
            "text-white text-xs max-md:text-[10px] mt-auto mr-auto pt-4",
            customTime
          )}
        />
      ) : (
        ""
      )}
    </div>
  );
};
