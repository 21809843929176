import { WidgetProps } from "@/types/types";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { InsideBanner } from "@/components/InsideBanner/Index";
import { OpinionCard } from "@/components/OpinionCard/Index";
import pulseConfig from "@/pulsex.config";
import { cn } from "@pulsex/utils";

export const OpinionHomeWidget = ({
  data,
  isMobile,
  insideBanners,
}: WidgetProps) => {
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} />
      <div
            className=" w-full flex mt-4 max-md:mt-2 max-md:flex-col"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            <div className="flex md:w-1/2">
            {data.posts.slice(0, 2).map((post: any, index: number) => (
             <OpinionCard
             widgetTitle={data?.title}
             key={index}
             className={cn(index > 0 && "md:mr-4 max-md:mr-2","w-1/2" )}
             data={post}
             isMobile={isMobile}
             customSummary="lg:line-clamp-4 line-clamp-3"
           />
            ))}
            </div>
            <div className="flex md:w-1/2 max-md:mt-2 md:mr-4">
            <OpinionCard
            widgetTitle={data?.title}
            className={"w-1/2"}
            data={data?.posts[2]}
            isMobile={isMobile}
            customSummary="lg:line-clamp-4 line-clamp-3"
          />
            {data.posts.length > 1
              ? insideBanners &&
                insideBanners?.opinionWidgetInsideBanners &&
                insideBanners?.opinionWidgetInsideBanners.map(
                  (banner: any, index: number) => (
                    <InsideBanner
                      isMobile={isMobile}
                      data={banner}
                      type="tall"
                      key={index}
                      className="w-1/2 md:mr-4 max-md:mr-2"
                    />
                  )
                )
              : ""}  </div>
          </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
