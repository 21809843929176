"use client";
import { useQuery } from "@tanstack/react-query";
import { getTrending } from "@pulsex/services";
export default function useTrending(config, size, type, pathname) {
    var _a, _b, _c;
    const clientId = config["CLIENT_ID"];
    const query = useQuery(["TRENDING", clientId, type, pathname], () => getTrending(config, size, type), {
        cacheTime: 1000 * 60 * 10,
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: !!type,
    });
    const mappedPosts = [];
    if (query.isSuccess && query.data && ((_a = query.data) === null || _a === void 0 ? void 0 : _a.data)) {
        (_c = (_b = query === null || query === void 0 ? void 0 : query.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.map((item) => {
            mappedPosts === null || mappedPosts === void 0 ? void 0 : mappedPosts.push(item);
        });
        return {
            isLoading: query.isLoading,
            data: mappedPosts,
        };
    }
}
