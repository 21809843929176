import * as React from "react";
import { cn } from "@pulsex/utils";

export interface SectionWrapProps {
  children: React.ReactNode;
  className?: string;
  ref?: any;
}

export default function SectionWrap({
  children,
  className,
  ref,
}: SectionWrapProps) {
  return (
    <section
      ref={ref}
      className={cn(
        "block min-w-full items-stretch gap-5 border-t-2 border-t-black lg:flex ltr:lg:flex-row-reverse pt-12 pb-8 lg:pt-0 lg:pb-5 max-md:pt-3 max-md:pb-4",
        className
      )}
    >
      {children}
    </section>
  );
}
