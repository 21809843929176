"use client";
import React from "react";
import { WidgetProps } from "@/types/types";
import SectionWrap from "@/components/SectionWrap/Index";
import { Card } from "@/components/Card/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import pulseConfig from "@/pulsex.config";

export const MediaCardsFourColumnGridWidget = ({ data }: WidgetProps) => {
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="pt-4 pb-14">
      <AsideTitleColumn data={data} />

      <div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full"
        dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
      >
        {data?.posts?.map((item: any, index: any) => (
          <Card
            widgetColor={data?.widgetColor}
            className={""}
            data={item}
            cardOrientation="v"
            showSummary={false}
            cropSize="wide"
            key={index}
            showMediaIcon={true}
            showAuthor={false}
          />
        ))}
      </div>
    </SectionWrap>
  );
};
