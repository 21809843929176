"use client";
import { cn } from "@pulsex/utils";
import { Category } from "@/ui/Category/Index";
import Link from "next/link";
import Author from "@/ui/Author/Index";
import { Time } from "@/ui/Time/Index";
import ImageUI from "@/ui/Image/Index";
import { Summary } from "@/ui/Summary/Index";
import { MediaIcon } from "@/ui/MediaIcon/Index";
import { getNewspaperDate } from "@/utils/getNewspaperDate";
import { formatArabicDate } from "@/utils/formatDate";
import { isSea } from "node:sea";

function sliceURL(url: any) {
  const prefix = "http://alpha.al-akhbar.com";
  if (url && url?.startsWith(prefix)) {
    return url?.substring(prefix.length);
  }
  return url || "";
}
interface CardProps {
  cardOrientation?: string;
  className?: string;
  showSummary?: boolean;
  showImage?: boolean;
  showAuthor?: boolean;
  showCategory?: boolean;
  customBackground?: string;
  showMediaIcon?: boolean;
  customImage?: string;
  customTitle?: string;
  mediatype?: "video" | undefined;
  data?: any;
  customCategory?: string;
  customTime?: string;
  showTime?: boolean;
  customSummary?: string;
  customAuthor?: string;
  authorTheme?: string;
  cropSize?: string;
  resolution?: number;
  isMobile?: any;
  customMetaFlex?: any;
  widgetColor?: any;
  widgetTitle?: any;
  isSearch?: any;
}
export const Card = ({
  cardOrientation = "h",
  showSummary = true,
  showCategory = true,
  showAuthor = true,
  showImage = true,
  showMediaIcon = false,
  className,
  customBackground,
  customImage,
  customTitle,
  data,
  customCategory,
  customTime,
  showTime = true,
  customSummary,
  customAuthor,
  cropSize,
  resolution,
  mediatype,
  customMetaFlex,
  widgetColor,
  widgetTitle,
  isSearch = false,
}: CardProps) => {
  if (!data) return;

  const dateValue = getNewspaperDate(data);
  const TermValue =
    data?.terms?.[0]?.title === "على الغلاف"
      ? data?.terms?.[1]
      : data?.terms?.[0];

  const CategoryValue =
    data?.tag?.title !== widgetTitle?.trim() ? data?.tag : TermValue;

  const cardUrl = data?.url || data?.publicUrl || " ";

  const animatorMeta = data?.metas?.find(
    (item: { type: string; url: string; key?: string; value?: string }) => {
      const isAnimatorKey = item.key === "animator";
      return isAnimatorKey && item.value;
    }
  );

  const isUrgentNews = data?.postType === "أخبار عاجلة";

  return (
    <div
      className={cn(
        "flex relative group overflow-hidden bg-greyBackground",
        cardOrientation === "v" ? "flex-col " : "flex-col md:flex-row ",
        cardOrientation === "h" && "max-md:flex-row ltr:flex-row-reverse",

        className
      )}
    >
      {showImage ? (
        isUrgentNews ? (
          <div
            className={cn(
              "overflow-hidden relative",
              cardOrientation === "h"
                ? "md:min-w-[56%] md:max-w-[56%] min-w-[47%] max-w-[47%] max-sm:w-full sm:w-full sm:mb-2 md:mb-0"
                : "w-full",
              customImage
            )}
          >
            <ImageUI
              data={data}
              cropSize={cropSize ? cropSize : "wide"}
              resolution={resolution}
              className="rounded-none w-full"
            />
          </div>
        ) : (
          <Link
            href={isSearch ? sliceURL(cardUrl) : cardUrl}
            aria-label={data?.title}
            className={cn(
              "overflow-hidden relative cursor-pointer",
              cardOrientation === "h"
                ? "md:min-w-[56%] md:max-w-[56%] min-w-[47%] max-w-[47%] max-sm:w-full sm:w-full sm:mb-2 md:mb-0"
                : "w-full",
              customImage
            )}
          >
            <ImageUI
              data={data}
              cropSize={cropSize ? cropSize : "wide"}
              resolution={resolution}
              className="rounded-none w-full"
            />
          </Link>
        )
      ) : (
        ""
      )}

      <div
        className={cn(
          "flex flex-col items-start max-sm:ml-0",
          cardOrientation === "h" ? "rtl:lg:mr-1.5 ltr:lg:ml-1.5 flex-1" : "flex-1",
          cardOrientation === "v" && showImage && "max-sm:mt-0",
          !showImage && "rtl:md:mr-0 ltr:md:ml-0  w-full",
          customBackground
        )}
      >
        <div
          className={cn(
            "h-full w-full flex flex-col ltr:items-end p-2 max-md:p-1.5",
            cardOrientation === "h" && "mt-0"
          )}
        >
          {showCategory && CategoryValue ? (
            <Category
              color={widgetColor || data?.tag?.color}
              item={CategoryValue}
              className={cn(
                "pb-[2px] max-md:pb-0",
                cardOrientation === "h" && "lg:mt-0",
                customCategory
              )}
            />
          ) : showCategory ? (
            <label className="h-[22px]" />
          ) : (
            <label className="h-[10px]" />
          )}

          <Link
            aria-label={data?.title}
            href={isSearch ? sliceURL(cardUrl) : cardUrl}
            className={cn(
              "cursor-pointer line-clamp-2 relative max-sm:my-1",
              "text-2xl max-lg:text-xl max-sm:text-xl max-md:!leading-6 leading-6 max-lg:leading-6 text-black",
              cardOrientation === "v"
                ? "rtl:pl-1.5 rtl:max-sm:pl-0 ltr:pr-1.5 ltr:max-sm:pr-0"
                : "rtl:lg:pl-4 ltr:lg:pr-4",
              cardOrientation === "h" &&
                showImage &&
                !data?.summary &&
                "md:line-clamp-3",
              customTitle
            )}
          >
            {data?.title}
          </Link>

          {showSummary && (data?.summary || data?.textContent) ? (
            <Summary
              className={cn(
                "rtl:pl-1.5 ltr:pr-1.5 max-w-full max-md:text-sm max-md:mt-0 rtl:max-md:pl-1 ltr:max-md:pr-1 cursor-text select-none",
                cardOrientation === "h" && "max-md:line-clamp-2",
                "text-[#545454]",
                customSummary
              )}
            >
              {data?.summary ? data?.summary : data?.textContent}
            </Summary>
          ) : (
            ""
          )}

          <div
            className={cn(
              "flex space-x-1 rtl:space-x-reverse ltr:flex-row-reverse items-center justify-between w-full h-fit mt-auto pt-4",
              cardOrientation === "h" && showImage && "pt-2.5",
              customMetaFlex
            )}
          >
            {showMediaIcon ? (
              <MediaIcon
                color={widgetColor || data?.tag?.color}
                type={
                  mediatype
                    ? mediatype
                    : data?.postType === "video"
                    ? "video"
                    : "camera"
                }
              />
            ) : (
              ""
            )}

            {showAuthor &&
            !animatorMeta &&
            data?.postType !== "opinion-caricature" ? (
              <Author item={data?.author} className={cn("", customAuthor)} />
            ) : (
              ""
            )}
            {data?.postType === "opinion-caricature" && animatorMeta ? (
              <div
                className={cn(
                  "text-sm w-fit cursor-pointer text-minorTheme",
                  className
                )}
              >
                {animatorMeta?.value}
              </div>
            ) : (
              ""
            )}

            {showTime && data?.publishDate && !isSearch && !dateValue ? (
              <p
                className={cn(
                  "text-[#848484] text-[10px] font-semibold",
                  customTime
                )}
                style={{ fontFamily: '"Helvetica Bold", sans-serif' }}
              >
                {formatArabicDate({
                  arabicDate: data?.publishDate,
                  needDay: false,
                })}
              </p>
            ) : (
              ""
            )}
            {showTime && isSearch && data?.displayDate ? (
              <p
                className={cn(
                  "text-[#848484] text-[10px] font-semibold",
                  customTime
                )}
                style={{ fontFamily: '"Helvetica Bold", sans-serif' }}
              >
                {data?.displayDate}
              </p>
            ) : (
              ""
            )}
            {showTime && data?.publishDate && !isSearch && dateValue ? (
              <p
                className={cn(
                  "text-[#848484] text-[10px] font-semibold",
                  customTime
                )}
                style={{ fontFamily: '"Helvetica Bold", sans-serif' }}
              >
                {formatArabicDate({
                  arabicDate: dateValue || data?.publishDate,
                  needDay: false,
                })}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
