"use client";
import { useQuery } from "@tanstack/react-query";
import { getMangoTracker } from "@pulsex/services";
export default function useMangopulseTracker(config, documentId, pathname) {
    const query = useQuery(["SUBSCRIBE", documentId, pathname], () => getMangoTracker(config, documentId), {
        cacheTime: 1000 * 60 * 1,
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: !!documentId,
    });
    return {
        isLoading: query.isLoading,
        data: query === null || query === void 0 ? void 0 : query.data,
    };
}
