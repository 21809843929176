import Link from "next/link";
import { cn } from "@pulsex/utils";
import Author from "@/ui/Author/Index";
import { Category } from "@/ui/Category/Index";
import { Summary } from "@/ui/Summary/Index";
import { WidgetProps } from "@/types/types";
import ImageUI from "@/ui/Image/Index";
import { ArrowRightIcon } from "@/icons/ArrowRightIcon";

export const Hero = ({
  data,
  isMobile,
  handlePrevious,
  handleNext,
  className,
  template,
  priority,
  colorTheme,
  widgetTitle,
  customText,
}: WidgetProps) => {
  if (!data) return;

  const TermValue =
    data?.terms?.[0]?.title === "على الغلاف"
      ? data?.terms?.[1]
      : data?.terms?.[0];

  const CategoryValue =
    data?.tag?.title !== widgetTitle?.trim() ? data?.tag : TermValue;

  return (
    <>
      <div
        className={cn(
          "relative w-full group overflow-hidden home-section",
          className
        )}
      >
        {template !== "section" ? (
          <div className="relative bg-greyBackground hidden max-md:flex px-4 py-3 flex-col rtl:items-start ltr:items-end overflow-hidden w-full max-sm:p-3 max-sm:rounded-t-none max-sm:pb-5">
            {CategoryValue ? (
              <Category
                color={colorTheme || data?.tag?.color}
                item={CategoryValue}
                className="bg-primary border-primary select-none"
              />
            ) : (
              <label className="h-[22px]" />
            )}

            <Link
              href={data?.url}
              aria-label={data?.title}
              className={cn(
                "select-none font-semibold sm:font-normal cursor-pointer w-fit text-5xl max-sm:text-2xl line-clamp-2"
              )}
            >
              {data?.title}
            </Link>

            {data?.author?.name ? <Author item={data?.author} /> : ""}
          </div>
        ) : (
          ""
        )}
        <div className="relative w-full h-fit overflow-hidden">
          {handleNext ? (
            <div
              onClick={handleNext}
              className="select-none absolute group/right max-md:right-0 right-2 top-1/2 -translate-y-1/2 z-10 p-2"
            >
              <ArrowRightIcon
                size={isMobile ? 40 : 50}
                className="fill-white cursor-pointer group-hover/right:fill-masterRed"
              />
            </div>
          ) : (
            ""
          )}

          {handlePrevious ? (
            <div
              onClick={handlePrevious}
              className="select-none absolute group/left max-md:left-0 left-2 top-1/2 -translate-y-1/2 z-10 p-2"
            >
              <ArrowRightIcon
                size={isMobile ? 40 : 50}
                className="rotate-180 fill-white cursor-pointer group-hover/left:fill-masterRed"
              />
            </div>
          ) : (
            ""
          )}

          <Link
            href={data?.url ? `${data?.url}` : " "}
            className={cn(
              "overflow-hidden w-full block pt-[58.7%] relative z-[2]",
              template !== "section" && "smooth-image-gradient"
            )}
          >
            {template !== "section" ? (
              <div className="max-md:hidden absolute top-2.5 right-0  bg-transparent lg:max-w-[90%] px-6 py-2 flex flex-col items-start overflow-hidden w-full max-sm:p-3 max-sm:rounded-t-none max-sm:pb-5 max-md:px-3 z-10">
                {CategoryValue ? (
                  <label
                    className={cn(
                      "h-fit w-fit cursor-pointer text-xl font-amine-light",
                      "bg-primary select-none lg:text-2xl",
                      className
                    )}
                    style={{
                      color:
                        colorTheme || data?.tag?.color || CategoryValue?.color,
                    }}
                  >
                    {CategoryValue?.title}
                  </label>
                ) : (
                  <label className="h-[22px]" />
                )}

                <h1
                  // href={data?.url}
                  // aria-label={data?.title}
                  className={cn(
                    "select-none text-white font-semibold sm:font-normal cursor-pointer w-fit text-5xl max-sm:text-2xl line-clamp-2 leading-[1.3]"
                  )}
                >
                  {data?.title}
                </h1>

                {/* {data?.author?.name ? <Author item={data?.author} /> : ""} */}
              </div>
            ) : (
              ""
            )}
            <ImageUI
              data={data}
              cropSize={"wide"}
              resolution={isMobile ? 2 : 2.6}
              priority={priority}
              className="rounded-none absolute left-0 top-0 w-full h-full"
            />
          </Link>
        </div>
        {template === "section" ? (
          <div className="relative bg-greyBackground px-4 py-3 flex flex-col rtl:items-start ltr:items-end overflow-hidden w-full max-sm:p-3 max-sm:rounded-t-none max-sm:pb-5">
            {CategoryValue ? (
              <Category
                color={colorTheme || data?.tag?.color}
                item={CategoryValue}
                className="bg-primary border-primary select-none"
              />
            ) : (
              ""
            )}

            <Link
              href={data?.url}
              aria-label={data?.title}
              className={cn(
                "select-none font-semibold sm:font-normal cursor-pointer w-fit text-4xl max-sm:text-2xl line-clamp-2 md:line-clamp-1",
                customText
              )}
            >
              {data?.title}
            </Link>
            {data?.summary ? (
              <Summary
                className={cn(
                  "font-amine-light text-xl line-clamp-1",
                  "rtl:lg:pl-6 rtl:pl-1.5 ltr:lg:pr-6 ltr:pr-1.5",
                  "text-[#545454]"
                )}
              >
                {data?.summary}
              </Summary>
            ) : (
              <div className="h-7 mt-1" />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
