"use client";
import { searchModel } from "@pulsex/models";
import { postSearch } from "@pulsex/services";
import { useInfiniteQuery } from "@tanstack/react-query";
export default function useSearch(config, options) {
    const query = useInfiniteQuery({
        queryKey: [
            "SEARCH",
            options.q,
            options.orderBy,
            options.types,
            options.page,
            options.from,
            options.to,
            options.authors,
            options.categories,
        ],
        queryFn: ({ pageParam = options.page || 1 }) => postSearch(config, Object.assign({}, options), pageParam),
        refetchOnWindowFocus: false,
        enabled: !!options.q,
        getNextPageParam: (lastPage, pages) => {
            var _a;
            if (((_a = lastPage === null || lastPage === void 0 ? void 0 : lastPage.posts) === null || _a === void 0 ? void 0 : _a.length) && lastPage.posts.length < 20) {
                return undefined;
            }
            return pages.length + 1;
        },
        retry: 1,
    });
    let mappedData = {
        count: 0,
        posts: [],
        postTypeCounts: null,
    };
    if (query.isSuccess && query.data) {
        query.data.pages.forEach((responseData) => {
            mappedData.posts.push(...(searchModel(config, responseData) || []));
            mappedData.count = responseData.count;
            mappedData.postTypeCounts = responseData.postTypeCounts;
        });
    }
    return {
        isLoading: query.isLoading,
        data: mappedData,
        fetchNextData: query.fetchNextPage,
        isFetching: query.isFetching,
        isFetchingNextPage: query.isFetchingNextPage,
        hasNextPage: mappedData.posts.length > 0 && query.hasNextPage,
    };
}
