import { WidgetProps } from "@/types/types";
import { Hero } from "@/components/Hero/Index";
import { useState } from "react";

export const HeroSlider = ({
  data,
  isMobile,
  template,
  colorTheme,
  customText,
}: WidgetProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleNext = () => {
    setCurrentIndex((prevIndex: number) =>
      prevIndex === data.posts.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex: number) =>
      prevIndex === 0 ? data.posts.length - 1 : prevIndex - 1
    );
  };
  if (!data || !data?.posts) return;

  return (
    <>
      {data && data?.posts?.length > 1 ? (
        data.posts.map(
          (item: any, index: number) =>
            currentIndex === index && (
              <Hero
                key={index}
                data={item}
                isMobile={isMobile}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                className="flex-1"
                template={template}
                priority={index === 0 ? true : undefined}
                colorTheme={data?.widgetColor || colorTheme}
                customText={customText}
              />
            )
        )
      ) : (
        <Hero
          data={data?.mainPost}
          className="flex-1"
          template={template}
          colorTheme={data?.widgetColor || colorTheme}
        />
      )}
    </>
  );
};
