export const IS_LOCAL =
  typeof window !== "undefined" &&
  window.location &&
  window.location.hostname.startsWith("localhost");

export const IS_BETA = process.env.isbeta == "false";

const isMobileUserAgent =
  typeof navigator !== "undefined" &&
  /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
    navigator.userAgent
  );

export const WINDOW_MOBILE =
  typeof window !== "undefined" &&
  (window.innerWidth < 780 || isMobileUserAgent);

export const LOCAL_URL = "http://localhost:3001";
export const SUB_CODE = "ALAKHBAR";
export const QUALITY_MAGNIFY = 2;

export const ALAKHBAR_GOOGLE_ANALYTICS_ID = "G-QWFRVYDJD5";
export const ALAKHBAR_GOOGLE_TAG_MANAGER_ID = "GTM-5QP253V3";

export const ALAKHBAR_CLARITY_ID = "pborfadryd";
export const ALAKHBAR_DOMAIN = "https://www.al-akhbar.com";
export const ALAKHBAR_URL = "https://www.al-akhbar.com";

export const ALAKHBAR_TELEGRAM = "https://t.me/alakhbar_news";
export const ALAKHBAR_INSTAGRAM = "https://www.instagram.com/alakhbarnews";
export const ALAKHBAR_X = "https://www.x.com/AlakhbarNews";
export const ALAKHBAR_FACEBOOK = "https://www.facebook.com/AlakhbarNews/";
export const ALAKHBAR_YOUTUBE = "https://www.youtube.com/@alakhbarnews";

export const ALAKHBAR_TIKTOK = "https://www.tiktok.com/";
export const ALAKHBAR_WHATSAPP =
  "https://www.whatsapp.com/channel/0029VaD90NqBFLgYaJj13625";

export const CURRENT_MONTH = "2006-07-01";

export const SMALL_CARD_RESOLUTION_FACTOR = 1;
export const MEDIUM_CARD_RESOLUTION_FACTOR = 1.5;


