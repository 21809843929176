import { IconProps } from "../types/types";

export const ArrowRightIcon = ({ className, size = 20,onClick }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 1.875 1.875"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path d="M1.5 0.938 0.5 0v1.875z" fill="" />
    </svg>
  );
};
