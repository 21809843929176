export const handleFacebookShare = (title, url) => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${title}`;
    window.open(facebookShareUrl, "_blank");
};
export const handleTwitterShare = (title, url) => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${title}&url=${url}`;
    window.open(twitterShareUrl, "_blank");
};
export const handleWhatsAppShare = (title, url) => {
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${title}%20${url}`;
    window.open(whatsappShareUrl, "_blank");
};
export const handleTelegramShare = (title, url) => {
    const telegramShareUrl = `https://telegram.me/share/url?url=${url}&text=${title}`;
    window.open(telegramShareUrl, "_blank");
};
