"use client";
import { ArrowRightIcon } from "@/icons/ArrowRightIcon";
import React from "react";
import { cn } from "@pulsex/utils";
import PaginateButton from "@/ui/PaginationButton/Index";
import pulseConfig from "@/pulsex.config";
import { ArrowLeftIcon } from "@/icons/ArrowLeftIcon";

const Pagination = ({
  currentPage,
  setPage,
  numberOfPages,
  handlePrev,
  handleNext,
  className,
}: any) => {
  const array = [];

  for (let i = 0; i < numberOfPages; i++) {
    array.push(i + 1);
  }

  return (
    <nav
      className={cn("py-8 mt-auto pt-4", className)}
      dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
    >
      <ul className="flex flex-wrap items-center justify-start h-6 text-xs dark:text-black">
        <li>
          <button
            onClick={handlePrev}
            className="ml-1 h-8 w-8 aspect-square flex items-center justify-center rounded-full border-minorTheme border disabled:bg-transparent disabled:cursor-not-allowed"
            disabled={currentPage === 1}
          >
            <ArrowRightIcon className="fill-minorTheme" size={15} />
          </button>
        </li>

        {numberOfPages < 7 ? (
          array.map((page, i) => (
            <li key={i}>
              <PaginateButton
                page={page}
                currentPage={currentPage}
                onClick={() => setPage(page)}
                className="ml-1.5"
              />
            </li>
          ))
        ) : currentPage < 6 ? (
          <>
            {array.slice(0, 6).map((page, i) => (
              <li key={i}>
                <PaginateButton
                  page={page}
                  currentPage={currentPage}
                  onClick={() => setPage(page)}
                  className="ml-1.5"
                />
              </li>
            ))}
            <button
              onClick={() => setPage(currentPage + 5)}
              className="py-1 pl-1 rounded text-lg tracking-widest text-minorTheme ml-1.5"
            >
              ...
            </button>
            <PaginateButton
              page={numberOfPages}
              currentPage={currentPage}
              active={currentPage === numberOfPages}
              onClick={() => setPage(numberOfPages)}
              className="ml-1.5"
            />
          </>
        ) : currentPage > numberOfPages - 5 ? (
          <>
            <PaginateButton
              page={1}
              currentPage={currentPage}
              active={currentPage === 1}
              onClick={() => setPage(1)}
              className="ml-1.5"
            />
            <button
              onClick={() => setPage(numberOfPages - 5)}
              className="py-1 px-2 rounded text-lg tracking-widest text-minorTheme ml-1.5"
            >
              ...
            </button>
            {array.slice(numberOfPages - 6, numberOfPages).map((page, i) => (
              <li key={i}>
                <PaginateButton
                  page={page}
                  currentPage={currentPage}
                  onClick={() => setPage(page)}
                  className="ml-1.5"
                />
              </li>
            ))}
          </>
        ) : (
          <>
            <PaginateButton
              page={1}
              currentPage={currentPage}
              onClick={() => setPage(1)}
              active={currentPage === numberOfPages}
              className="ml-1.5"
            />
            <button
              onClick={() => setPage(currentPage - 2)}
              className="py-1 px-2 rounded text-lg tracking-widest text-minorTheme ml-1.5"
            >
              ...
            </button>
            {array.slice(currentPage - 3, currentPage + 2).map((page, i) => (
              <li key={i}>
                <PaginateButton
                  page={page}
                  currentPage={currentPage}
                  onClick={() => setPage(page)}
                  className="ml-1.5"
                />
              </li>
            ))}
            <button
              onClick={() => setPage(currentPage + 2)}
              className="py-1 px-2 rounded text-lg tracking-widest text-minorTheme ml-1.5"
            >
              ...
            </button>

            <PaginateButton
              page={numberOfPages}
              active={currentPage === numberOfPages}
              currentPage={currentPage}
              onClick={() => setPage(numberOfPages)}
              className="ml-1.5"
            />
          </>
        )}
        <li>
          <button
            onClick={handleNext}
            className={cn(
              "h-8 w-8 aspect-square flex items-center justify-center rounded-full ml-1.5",
              "border-minorTheme border",
              "disabled:cursor-not-allowed disabled:opacity-60"
            )}
            disabled={currentPage === numberOfPages}
          >
            <ArrowLeftIcon
              className={cn(
                "fill-minorTheme",
                currentPage === numberOfPages && "fill-gray-400"
              )}
              size={15}
            />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
