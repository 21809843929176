"use client";
import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { MediaSlider } from "@/widgets/MediaSlider/Index";

const DetailsMaincolumn = ({ data }: any) => {
  const [cleanContent, setCleanContent] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data && data?.content) {
      const fillData = () => {
        const purify = (html: string) => {
          if (html) {
            let cleanedHtml = html.replace(/<style>[\s\S]*<\/style>/, "");

            cleanedHtml = cleanedHtml.replace(
              /<a(?![^>]*target=["'][^"']*["'])([^>]*)>/gi,
              '<a$1 target="_blank">'
            );

            // cleanedHtml = cleanedHtml.replace(
            //   /(https?:\/\/[^\s"']+\.pdf)/gi,
            //   `<iframe  src="http://docs.google.com/gview?embedded=true&url=$1&amp;embedded=true" frameborder="0" class="w-[350px] h-[600px]"/>`
            // );

            return cleanedHtml;
          }
          return "";
        };
        if (data?.content.length > 0) {
          setCleanContent(purify(data?.content) ?? "");
        }
      };

      fillData();
    }
  }, [data?.content]);

  useEffect(() => {
    if (cleanContent && contentRef.current) {
      contentRef.current.innerHTML = cleanContent;

      if ((window as any)?.twttr?.widgets) {
        (window as any)?.twttr.widgets.load();
      }
      if ((window as any)?.instgrm?.Embeds) {
        (window as any)?.instgrm.Embeds.process();
      }
    }
  }, [cleanContent]);

  const sourceMeta = data?.metas?.find(
    (meta: { type: string; url: string; key?: string; value?: string }) => {
      const isSource = meta.key === "source";
      return isSource && meta.value;
    }
  );
  if (!data) return;
  
  return (
    <>
      <>
        {cleanContent && (
          <div
            className="post-details-content prose text-lg w-full leading-7 min-w-full lg:pl-4 md:pl-2"
            style={{ fontFamily: "Droid Arabic Naskh,sans-serif" }}
          >
            <div
              className="w-full"
              style={{ fontFeatureSettings: "'liga' 0" }}
              ref={contentRef}
            ></div>
          </div>
        )}

        <MediaSlider data={data} crop={false} />

        {data?.content ? (
          <div className="flex space-x-2 flex-wrap">
            {data?.keywords
              ? data?.keywords.map((item: any, index: any) => (
                  <Link
                    href={item?.url}
                    key={index}
                    className="text-2xl font-amine-light cursor-pointer left-border px-2 text-[#736357]"
                  >
                    {item?.title}
                  </Link>
                ))
              : null}
          </div>
        ) : (
          ""
        )}
        {sourceMeta?.value ? (
          <div className="flex space-x-2  rtl:space-x-reverse  text-2xl">
            <label className="block  text-[#080808c7]">
              مصدر هذا المحتوى هو
            </label>
            <label className="block text-masterRed tracking-wide">
              {sourceMeta?.value}
            </label>
          </div>
        ) : (
          ""
        )}
        {data ? <div className="w-full h-2" ref={ref}></div> : ""}
      </>
    </>
  );
};

export default DetailsMaincolumn;
