"use client";

import { TitleSection } from "@/ui/Title/Index";
import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { InsideBanner } from "@/components/InsideBanner/Index";
import { extractSection } from "@/utils/extractSection";
import { usePathname } from "next/navigation";
import pulseConfig from "@/pulsex.config";

export const TwoCardsGridTwoTextAsideWidget = ({
  data,
  isMobile,
  hideCategory,
}: WidgetProps) => {
  const pathname = usePathname();
  const colorTheme = extractSection(pathname);
  if (!data?.posts?.length) return;
  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} colorTheme={colorTheme || ""} />

      <div className="max-md:flex-col max-md:space-y-2 w-full flex md:space-x-3 lg:space-x-4   rtl:md:!space-x-reverse ltr:md:flex-row-reverse   ">
        <div className="flex-col space-y-4 max-md:space-y-2 flex md:w-1/2">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={""}
            data={data.posts[0]}
            cropSize="wide"
            cardOrientation="v"
            isMobile={isMobile}
            showSummary={false}
            customTitle="line-clamp-1"
            customAuthor="md:pt-[2px]"
            showCategory={hideCategory}
          />
          <InsideBanner isMobile={isMobile} type="wide" className="" />
        </div>
        <div className="flex-col space-y-4 max-md:space-y-2 flex md:w-1/2">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={"max-md:flex-row"}
            data={data.posts[1]}
            resolution={3}
            cropSize="square"
            cardOrientation="h"
            isMobile={isMobile}
            customSummary="line-clamp-5"
            customImage="md:min-w-[53.33%] md:max-w-[53.33%]"
            showCategory={hideCategory}
          />
          {isMobile ? (
            <div
              className="grid grid-cols-2 gap-2 w-full"
              dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
            >
              {data.posts.slice(2, 4).map((post: any, index: number) => (
                <Card
                  widgetTitle={data?.title}
                  widgetColor={data?.widgetColor || colorTheme}
                  key={index}
                  className={""}
                  data={post}
                  resolution={3}
                  cropSize="1"
                  cardOrientation="h"
                  isMobile={isMobile}
                  customSummary="line-clamp-1"
                  showImage={false}
                  showCategory={hideCategory}
                />
              ))}
            </div>
          ) : (
            <>
              {data.posts.slice(2, 4).map((post: any, index: number) => (
                <Card
                  widgetTitle={data?.title}
                  widgetColor={data?.widgetColor || colorTheme}
                  key={index}
                  className={""}
                  data={post}
                  resolution={3}
                  cropSize="1"
                  cardOrientation="h"
                  isMobile={isMobile}
                  customSummary="line-clamp-1"
                  customTitle="line-clamp-1"
                  showCategory={hideCategory}
                  showImage={false}
                />
              ))}
            </>
          )}
        </div>
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
