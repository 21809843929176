"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = postSubscribe;
async function postSubscribe(domain, email, userId) {
    const url = `https://api-appx.cognativex.com/subscription-service/subscribe`;
    const requestBody = {
        appDomain: domain,
        email: email,
        userId: userId,
    };
    try {
        // Perform the fetch request
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        if (!response.ok) {
            const errorMessage = data.errorMessage || response.statusText;
            return {
                data: null,
                error: errorMessage,
                code: response.status,
            };
        }
        return { data, error: undefined, code: response.status };
    }
    catch (error) {
        return {
            data: null,
            error: error instanceof Error
                ? error.message
                : "An unknown error occurred",
            code: undefined,
        };
    }
}
