import { postModel } from "@pulsex/models";
import { getRelatedPosts } from "@pulsex/services";
import { useQuery } from "@tanstack/react-query";
export default function useRelatedPosts({ postId, config, }) {
    var _a, _b, _c, _d;
    const query = useQuery({
        queryKey: ["GET_RELATED_POSTS", postId],
        queryFn: () => getRelatedPosts(config, postId),
        refetchOnWindowFocus: false,
        enabled: !!postId,
    });
    let mappedPosts;
    if (query.isSuccess && ((_b = (_a = query.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.length)) {
        mappedPosts = (_d = (_c = query.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.map((post) => postModel(config, post));
    }
    return {
        isLoading: query.isLoading,
        posts: mappedPosts,
    };
}
