import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { InsideBanner } from "@/components/InsideBanner/Index";
import {
  MEDIUM_CARD_RESOLUTION_FACTOR,
  SMALL_CARD_RESOLUTION_FACTOR,
} from "@/constants";
import { getViewport } from "@/utils/getViewPort";
import { useState, useEffect } from "react";
import cn from "@pulsex/utils/dist/lib/cn";

export const PeopleHomeWidget = ({
  data,
  isMobile,
  insideBanners,
}: WidgetProps) => {
  const { viewWidth } = getViewport();
  const [deskWidth, setDeskWidth] = useState(0);

  useEffect(() => {
    if (viewWidth && viewWidth > 1520) {
      setDeskWidth(650);
    }
  }, [viewWidth]);

  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} />
      <div className="flex flex-col w-full">
        <div className="max-md:flex-col flex">
          <Card
            widgetColor={data?.widgetColor}
            className={"md:w-1/2"}
            data={data.posts[0]}
            resolution={deskWidth > 0 ? 2.2 : MEDIUM_CARD_RESOLUTION_FACTOR}
            cropSize="wide"
            cardOrientation="v"
            isMobile={isMobile}
            customSummary="line-clamp-1"
            showSummary={false}
          />

          <div className="max-md:flex-col flex md:w-1/2 md:mr-4 max-md:mt-2">
            <Card
              widgetColor={data?.widgetColor}
              className={"md:w-1/2"}
              data={data.posts[1]}
              resolution={isMobile ? 1.3 : SMALL_CARD_RESOLUTION_FACTOR}
              cardOrientation="v"
              cropSize="wide"
              isMobile={isMobile}
              customSummary="line-clamp-5"
            />
            <div className="flex flex-col md:w-1/2 md:mr-4 max-md:mt-2">
              {data.posts.slice(2, 5).map((post: any, index: number) => (
                <Card
                  widgetColor={data?.widgetColor}
                  key={index}
                  className={cn(index > 0 && "mt-2 md:mt-4 md:pt-2")}
                  data={post}
                  cardOrientation="h"
                  isMobile={isMobile}
                  showSummary={false}
                  customSummary="line-clamp-1"
                  showImage={false}
                  customMetaFlex={"2xl:pt-6"}
                  customCategory="2xl:pb-3"
                />
              ))}
            </div>
          </div>
        </div>
        <div className="max-md:flex-col flex md:mt-4 max-md:mt-2">
          <Card
            widgetColor={data?.widgetColor}
            className={"md:w-1/2 max-md:flex-row"}
            data={data.posts[5]}
            resolution={1}
            cardOrientation="h"
            isMobile={isMobile}
            cropSize="square"
            customTitle="md:line-clamp-2"
            customSummary="md:line-clamp-3 lg:line-clamp-5"
            customImage="md:min-w-[55%] md:max-w-[55%]"
          />
          <div className="max-md:flex-col flex md:w-1/2 md:mr-4 max-md:mt-2">
            <Card
              widgetColor={data?.widgetColor}
              className={"md:w-1/2"}
              data={data.posts[6]}
              resolution={isMobile ? 1.3 : SMALL_CARD_RESOLUTION_FACTOR}
              cardOrientation="v"
              cropSize="wide"
              customTitle="line-clamp-1 md:line-clamp-1 lg:line-clamp-2"
              isMobile={isMobile}
              showSummary={false}
            />

            {data.posts.length > 6 ? (
              <div className="flex-col flex md:w-1/2 md:mr-4 max-md:mt-2">
                {insideBanners &&
                  insideBanners?.peopleWidgetInsideBanners &&
                  insideBanners?.peopleWidgetInsideBanners.map(
                    (banner: any, index: number) => (
                      <InsideBanner
                        isMobile={isMobile}
                        data={banner}
                        type="medium"
                        key={index}
                        className={cn(index > 0 && "mt-2 md:mt-4")}
                      />
                    )
                  )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
