"use client";
import { useEffect, useState } from "react";
import SearchSuspense from "./Suspense/Index";
import pulseConfig from "@/pulsex.config";

const SearchPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [filterTypes, setFilterTypes] = useState<any>();

  const API_URL = pulseConfig.API_URL;

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await fetch(API_URL + "/assets/searchSections");
        if (!response.ok) {
          throw new Error(`Error: ${response?.status}`);
        }
        const data = await response?.json();
        if (data) {
          const values = data["الكل"];
          setFilterTypes(values);
        }
      } catch (err: unknown) {
        console.log("error occured");
      }
    };

    fetchSections();
  }, []);

  const isMobileDevice = () => {
    const userAgent = typeof window !== "undefined" ? navigator.userAgent : "";
    const mobileRegex = /Mobi|Android|iPhone|iPod/i;
    const iPadRegex = /iPad/i;

    return (
      mobileRegex.test(userAgent) ||
      iPadRegex.test(userAgent) ||
      navigator.maxTouchPoints > 1
    );
  };

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  return (
    <>
      <SearchSuspense isMobile={isMobile} types={filterTypes} />
    </>
  );
};

export default SearchPage;
