import { locale } from "@/locale";
import { cn } from "@pulsex/utils";
import React from "react";
type Props = {
  moreUrl?: string;
  className?: string;
  customText?: string;
};
export const MoreWrap = ({ moreUrl, className, customText }: Props) => {
  return moreUrl ? (
    <div className={cn("flex flex-col justify-end max-lg:hidden", className)}>
      <div className="flex h-full w-[50px] min-w-[50px] items-start md:self-end ">
        <a
          className={cn(
            "text-lg font-black text-gray-text self-end leading-5",
            customText
          )}
          // href={moreUrl ? "/more"+moreUrl : ""}
          href={moreUrl ? moreUrl : ""}
        >
          {locale.more}
        </a>
      </div>
    </div>
  ) : (
    <div className={cn("flex flex-col justify-end max-md:hidden", className)}>
      <div className="flex h-full w-[50px] min-w-[50px] items-start self-end " />
    </div>
  );
};
