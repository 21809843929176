import { IconProps } from "../types/types";


export const PlayIcon = ({ className="fill-white", size }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 0.375 0.375"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M.12.052A.013.013 0 0 0 .1.063v.25a.013.013 0 0 0 .02.01L.295.198a.013.013 0 0 0 0-.02z" />
    </svg>
  );
};
