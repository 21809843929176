"use client";
import { ArrowDownIcon } from "@/icons/ArrowDownIcon";
import { SearchIcon } from "@/icons/SearchIcon";
import { cn } from "@pulsex/utils";
import Link from "next/link";
import { useRef, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import BurgerIcon from "@/icons/BurgerIcon";
import { CloseIcon } from "@/icons/CloseIcon";
import { Menu } from "@pulsex/models";
import { Logo } from "@/icons/Logo";
import pulseConfig from "@/pulsex.config";
import useDailyPdf from "@/hooks/useDailyPdf";
import { locale } from "@/locale";
type MenuProps = {
  menus: Menu;
  status: string;
  scrolled?: boolean;
  scrollDirection?: string;
};

const MobileMenu = ({
  menus,
  status,
  scrolled = false,
  scrollDirection = "",
}: MenuProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showSubMenu, setShowSubMenu] = useState<any>();
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const pathname = usePathname();

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();

    const searchQuery = searchInputRef.current?.value;
    if (searchQuery) {
      searchQuery.trim().length > 2 &&
        router?.push(`/search?q=${searchQuery.trim()}`);
    }
  };

  const { isLoading, data } = useDailyPdf(pulseConfig);
  const dailyPdfURL = data?.mainPost?.url;

  return (
    <header className="bg-white sticky top-0 left-0 z-[11]">
      <nav className={cn("flex flex-col py-3.5 container")}>
        <div className="flex space-x-2  rtl:space-x-reverse  justify-between items-end border-b-2 border-greyBackgroundDim pb-4">
          <Link href="/" aria-label="Home">
            <Logo size={40} className="fill-black" />
          </Link>
          {/* <div className="flex flex-col space-y-2">
            <div className="opacity-0 bg-[#4B5F87] flex justify-between items-center space-x-2  rtl:space-x-reverse  p-1.5">
              <div className="text-white">locale.logIn </div>
              <UserIcon size={22} />
            </div>
            <div className="opacity-0 bg-[#EBC855] flex justify-between items-center space-x-2  rtl:space-x-reverse  p-1.5">
              <div className="text-white">locale.subscribe </div>
              <LoginIcon size={22} />
            </div>
             <label className="font-amine-light text-sm hidden">دعم المنصة بالاشتراك، واستفد من تجربة متطورة</label>
            <label className="text-[rgb(235,200,85)] font-amine-light hidden">إضغط لمعرفة المزيد</label> 
          </div> */}
          <img
            src="/images/samaha.png"
            alt="samaha"
            className="brightness-100 h-[48px] block select-none"
            style={{
              color: "transparent",
              filter: "contrast(1.2)",
              transform: "scaleX(-1)",
            }}
            width={40.23}
            height={48}
          />
        </div>
        <div className="flex mt-3 justify-between">
          <div
            className="flex items-center justify-center h-fit px-1 py-1 bg-black"
            onClick={() => setShowMenu(!showMenu)}
          >
            <BurgerIcon size={27} className="fill-white" />
          </div>
          <div
            className={cn(
              "bg-greyBackgroundDim max-w-[180px] w-full h-[38px] transition-all duration-[400ms]"
            )}
          >
            <form
              className="flex items-center h-full w-full p-2"
              onSubmit={handleSubmit}
            >
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-white sr-only hidden"
              >
                {locale.search}
              </label>
              <div className="relative flex items-center space-x-2  space-x-reverse ">
                <div
                  onClick={() => handleSubmit()}
                  className="flex items-center cursor-pointer z-10"
                >
                  <SearchIcon size={22} className="fill-black" />
                </div>
                <input
                  type="search"
                  id="default-search"
                  ref={searchInputRef}
                  className="m-auto block w-full bg-white text-center text-greyText placeholder-greyText bg-transparent focus:ring-white/0 focus:border-none outline-0 !rounded-none"
                  placeholder={locale.searchPlaceHolder}
                  required
                />
              </div>
            </form>
          </div>
        </div>

        {showMenu && (
          <>
            <div
              className={cn(
                "flex flex-col bg-greyBackground h-screen w-full fixed",
                "left-0 top-0 z-50 m-0 pb-10 pt-40 w-full overflow-y-scroll"
              )}
            >
              <div
                className="bg-black absolute right-3 top-8 z-50 px-1 py-1"
                onClick={() => setShowMenu(false)}
              >
                <CloseIcon size={30} className="fill-white" />
              </div>

              <div className="w-full absolute right-0 top-[18%] pb-8">
                {menus
                  ?.getMenuByKey("h-main")
                  ?.map((menuItem: any, index: number) => (
                    <div
                      key={index}
                      className={cn(
                        "block border-b border-greyBackgroundDim justify-center text-2xl text-white font-medium",
                        showSubMenu === index && `show-sub-menu-${index}`
                      )}
                    >
                      <div
                        aria-label={menuItem?.title}
                        className={cn(
                          "rtl:pl-6 ltr:pr-6 py-3 flex items-end justify-between text-black ",
                          "rtl:border-r-[14px] ltr:border-l-[14px] ltr:flex-row-reverse",
                          pathname.length > 2 &&
                            menuItem?.url?.includes(pathname?.split("/")[1]) &&
                            "relative"
                        )}
                        style={{ borderRight:`14px solid ${menuItem?.color}` }}
                        onClick={() => {
                          if (menuItem?.subMenus?.length) {
                            setShowSubMenu(
                              showSubMenu === index ? undefined : index
                            );
                          } else {
                            setShowMenu(false);
                            setShowSearch(false);
                          }
                        }}
                      >
                        {menuItem?.subMenus?.length > 0 ? (
                          <div className=""
                          style={{marginRight:"14px"}}>
                            {menuItem?.title}
                          </div>
                        ) : (
                          <Link
                            href={menuItem?.url}
                            style={{marginRight:"14px"}}
                            className=""
                          >
                            {menuItem?.title}
                          </Link>
                        )}
                        {menuItem?.subMenus?.length > 0 && (
                          <ArrowDownIcon
                            size={16}
                            className={cn(
                              "ml-1.5 -translate-y-[3px] transition-transform duration-100 fill-black hover:fill-primary",
                              showSubMenu === index && "rotate-180"
                            )}
                          />
                        )}
                      </div>

                      {menuItem?.subMenus?.length > 0 && (
                        <div
                          className={cn(
                            " bg-gray-200 text-xl  space-y-0 items-start ltr:text-left transition-all",
                            showSubMenu === index ? "block" : "hidden",
                            "text-black rtl:border-r-[14px] ltr:border-l-[14px]"
                          )}
                          style={{ borderRight:`14px solid ${menuItem?.color}` }}
                        >
                          {menuItem?.title !== locale.more ? (
                            <Link
                              href={menuItem?.url || ""}
                              aria-label={menuItem?.title}
                              className="border-greyBackgroundDim border-b border-t p-3 w-full block"
                            >
                              {menuItem?.title}
                            </Link>
                          ) : (
                            ""
                          )}
                          {menuItem?.subMenus?.map(
                            (subItem: any, subIndex: number) => (
                              <Link
                                key={subIndex}
                                className={cn(
                                  "border-greyBackgroundDim px-3 w-full py-3 block",
                                  subIndex !== menuItem?.subMenus?.length - 1 &&
                                    "border-b",
                                  `group/${menuItem?.title}`
                                )}
                                href={
                                  decodeURIComponent(subItem?.title) ===
                                    locale.todaysRelease && dailyPdfURL
                                    ? dailyPdfURL
                                    : subItem?.url || ""
                                }
                                aria-label={subItem?.title}
                                onClick={() => {
                                  setShowMenu(false);
                                  setShowSubMenu(false);
                                }}
                              >
                                {subItem?.title}
                              </Link>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>

            <div
              onClick={() => setShowMenu(false)}
              className={cn(
                "fixed w-screen h-screen top-0 left-0",
                "transition-all duration-100 opacity-0 overflow-y-scroll",
                showMenu
                  ? "z-20 backdrop-blur-sm bg-greyBackground opacity-100"
                  : "z-[-1]"
              )}
            ></div>
          </>
        )}
      </nav>
    </header>
  );
};

export default MobileMenu;
