import { IPulseXConfig } from "@pulsex/types";

export const IS_LIVE = process.env.NEXT_PUBLIC_STAGING == "true";

const staging = IS_LIVE || true;


const pulseConfig: IPulseXConfig = {
  CLIENT_ID: "akhbar",
  LANGUAGE: "ar",
  CLIENT_DOMAIN: "al-akhbar.com",
  API_URL: staging
    ? "https://api.al-akhbar.com"
    : "https://beta-api.al-akhbar.com",
  MEDIA_URL: staging
    ? "https://media.al-akhbar.com"
    : "https://media-alakhbar.mangopulse.net",
  CLIENT_URL: staging
    ? "https://www.al-akhbar.com"
    : "https://alakhbar.mangopulse.net",
  weatherApiKey: "cf681607a7c35bd1bac01b122bf3308c",
  TRACK_BASE_URL: "https://mdn-search.mangopulse.net",
  cropRatios: {
    square: 1,
    wide: 2,
    medium: 3,
    cinema: 4,
    rectangle: 5,
  },
  cropSizes: {
    "1": 1,
    "2": 1.7,
    "3": 1.5,
    "4": 0.657,
    "5": 1.777,
  },
  // TODO: edit this to the real date
  sitemapStartingDate: "2024-10-01",
  QUERY_KEYS: {
    GET_PAGE: "GET_PAGE",
    GET_ASSETS: "GET_ASSETS",
    GET_DAILY_PDF: "GET_DAILY_PDF",
  },
};

export default pulseConfig;

export const IS_LOCAL =
  typeof window !== "undefined" &&
  window.location &&
  window.location.hostname.startsWith("localhost");