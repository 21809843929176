"use client";
import { NewsPaperIcon } from "@/icons/NewsPaperIcon";
import { PlayIcon } from "@/icons/PlayIcon";
import { QuoteIcon } from "@/icons/QuoteIcon";
import { locale } from "@/locale";
import pulseConfig, { IS_LIVE, IS_LOCAL } from "@/pulsex.config";
import { widgetPageModel } from "@pulsex/models";
import { cn } from "@pulsex/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

type Props = {
  className?: string;
  template?: string;
  customTitle?: string;
  customSize?: number;
  setTodaysNumber?: any;
  pdfData?: any;
};

const DailyPdf = ({
  className,
  template,
  customTitle,
  customSize,
  setTodaysNumber,
  pdfData,
}: Props) => {
  const prefix = "/newspaper/";
  const pathname = usePathname();
  const pdfModel = widgetPageModel(pulseConfig, pdfData);

  const data = pdfModel?.widgets?.length ? pdfModel?.widgets?.[0]?.mainPost  : pdfData?.mainPost;

  const pdfUrl = data?.medias?.filter((item: any) => {
    const isFileMain = item.type === "file" && item.placement === "main";
    const hasValidUrl = item.url && item.url.endsWith(".pdf");

    return isFileMain && hasValidUrl;
  });

  const newsNumbering = pathname.startsWith(prefix)
    ? pathname.slice(prefix.length)
    : null;

  const pdfDetails =
    (newsNumbering && newsNumbering === data?.title) ||
    pathname.includes("newspaper/");

  const pdfFileUrl = pdfDetails ? pdfUrl?.[0]?.url : data?.url;
  const pdfTarget = pdfDetails ? "_blank" : "_self";
  const pdfText = !pdfDetails ? pdfModel?.title : locale.pdfPages;

  useEffect(() => {
    data?.title && setTodaysNumber && setTodaysNumber(data?.title);
  }, [data]);

  if (!data) {
    return (
      <div className="h-[417px] max-w-[237px] w-full bg-greyBackground/15" />
    );
  }

  return (
    <>
      <div
        className={cn(
          "flex flex-col max-w-full",
          template === "details" ? "w-[86%]" : "w-full border-2 border-black",
          className
        )}
      >
        {template === "details" ? (
          <Link
            href={pdfFileUrl || "#"}
            target={pdfTarget}
            className="p-2 rtl:pr-0 ltr:pl-0 flex items-center space-x-1  rtl:space-x-reverse  text-black font-amine-light max-md:text-xl max-lg:text-2xl text-3xl"
          >
            <PlayIcon size={30} className="fill-masterRed rotate-180" />
            <p>{pdfText}</p>
          </Link>
        ) : (
          ""
        )}
        {data ? (
          <>
            <Link
              href={pdfFileUrl || "#"}
              target={pdfTarget}
              aria-label="daily pdf"
              className="relative overflow-hidden"
            >
              {template === "today" ? (
                <p className="bg-masterRed py-[2px] font-amine-light text-lg w-full text-center text-white absolute bottom-0 left-0">
                  {locale.todaysPdf}
                </p>
              ) : (
                ""
              )}
              <img
                loading="lazy"
                src={
                  data?.image
                    ? data?.image + "?width=298"
                    : "/svgs/fallback/default-paper.svg"
                }
                alt="daily pdf"
                className={cn(
                  "max-w-full w-full",
                  template === "details" ? "border-2 border-black" : ""
                )}
                width={250}
                height={450}
              />
            </Link>
          </>
        ) : (
          ""
        )}
        {template !== "details" ? (
          <Link
            href={pdfFileUrl || "#"}
            target={pdfTarget}
            className={cn(
              "bg-black p-2 flex items-center space-x-1 ltr:flex-row-reverse rtl:space-x-reverse  text-white font-amine-light max-md:text-xl max-lg:text-2xl text-3xl",
              customTitle
            )}
          >
            <PlayIcon
              size={customSize ? customSize : 28}
              className="fill-masterRed rtl:rotate-180"
            />
            <p>{pdfText}</p>
          </Link>
        ) : (
          ""
        )}
      </div>

      {/* mobile newspaper floating button */}
      {/* {!IS_LIVE ? (
        <Link
          href={pdfFileUrl || ""}
          className={cn(
            "h-20 w-20 rounded-full ",
            "max-md:block hidden fixed left-1.5 bottom-1.5",
            "z-10 p-1 bg-masterRed"
          )}
        >
          <div className="w-full h-full">
            <NewsPaperIcon
              size={82}
              className="absolute -top-7 -right-4 z-[-1]"
            />
            <QuoteIcon
              size={20}
              className="absolute top-4 -right-1 fill-masterRed stroke-white stroke-2"
            />

            <div
              className={cn(
                "bg-black rounded-full h-full w-full",
                "flex flex-col items-center justify-center",
                "text-2xl text-white"
              )}
            >
              <label className="leading-6 z-10">{locale.pdf}</label>
              <label className="leading-5 z-10">{locale.today}</label>
            </div>
          </div>
        </Link>
      ) : (
        ""
      )} */}
    </>
  );
};

export default DailyPdf;
