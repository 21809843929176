"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = getTrending;
async function getTrending(config, size, type) {
    const clientId = config["CLIENT_ID"];
    const baseUrl = config["TRACK_BASE_URL"];
    const params = new URLSearchParams({
        clientId,
        size,
        type,
    });
    const url = `${baseUrl}/mangopulse/event/trending?${params.toString()}`;
    const response = await fetch(`${url}`);
    if (response) {
        const data = await (response === null || response === void 0 ? void 0 : response.json());
        return data;
    }
    return;
}
