"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = MangoTracker;
async function MangoTracker(config, postId) {
    const CLIENT_DOMAIN = config["CLIENT_DOMAIN"];
    if (!CLIENT_DOMAIN) {
        console.error("TRACK_BASE_URL not found in config");
        return null;
    }
    const url = `https://track.${CLIENT_DOMAIN}/TrackView/${postId}`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (!(response === null || response === void 0 ? void 0 : response.ok)) {
        console.error(`Failed to send track view: ${response === null || response === void 0 ? void 0 : response.statusText}`);
    }
    if (response) {
        try {
            return "ok";
        }
        catch (error) {
            console.error("Error parsing JSON:", error);
            return;
        }
    }
}
