"use client";
import { getViewport } from "@/utils/getViewPort";
import { cn } from "@pulsex/utils";
import Link from "next/link";
import React, { useEffect, useState } from "react";

interface BannerProps {
  type: "wide" | "medium" | "tall";
  className?: string;
  data?: any;
  magnifyBanner?: boolean;
  isMobile?: boolean;
}

export const InsideBanner = ({
  data,
  type,
  className,
  magnifyBanner = false,
  isMobile,
}: BannerProps) => {
  let paddingTop: string;
  const [screenFactor, setScreenFactor] = useState(1);
  const { viewWidth, viewHeight } = getViewport();

  useEffect(() => {
    if (viewWidth && viewWidth > 1520 && magnifyBanner) {
      setScreenFactor(1.6);
    }
  }, [viewWidth]);

  let width = 450;
  switch (type) {
    case "wide":
      paddingTop = `${(132.5 / 510) * 100}%`; // 3.76 aspect ratio
      width = 481 * screenFactor;
      break;
    case "medium":
      paddingTop = `${(136.5 / 255.94) * 100}%`; // 1.93 aspect ratio
      width = 450 * screenFactor;
      break;
    case "tall":
      paddingTop = `${(276.58 / 230.5) * 100}%`; // 0.83 aspect ratio
      width = 450 * screenFactor;

      break;
    default:
      paddingTop = `${(132.5 / 498) * 100}%`;
  }
  const desktopImage = data?.mainPost?.medias.filter(
    (media: any) => media.placement === "desktop-cover"
  )[0];
  const mobileImage = data?.mainPost?.medias.filter(
    (media: any) => media.placement === "mobile-cover"
  )[0];

  let deskBannerSrc =
    desktopImage?.url + `?width=${isMobile ? 1 : width}`;
  let mobileBannerSrc =
    mobileImage?.url + `?width=${isMobile ? 500 : 1}`;

  if (!desktopImage?.url || !mobileImage?.url) return;

  return (
    <div className={cn("w-full", className)}>
      {data?.mainPost?.url ? (
        <Link
          href={data?.mainPost?.url || ""}
          className={cn("w-full block relative overflow-hidden")}
          style={{ paddingTop }}
        >
          <img
            loading="lazy"
            alt={"banner " + data?.mainPost?.title}
            // src={"/svgs/banners/banner-" + type + ".svg"}
            src={deskBannerSrc}
            className={cn(
              "absolute inset-0 w-full h-full items-center justify-center ",
              !isMobile ? "flex" : "hidden"
            )}
          />
          {mobileBannerSrc ? (
            <img
              alt={"banner " + data?.mainPost?.title}
              loading="lazy"
              // src={"/svgs/banners/banner-" + type + ".svg"}
              src={mobileBannerSrc}
              className={cn(
                "absolute inset-0 w-full h-full items-center justify-center",
                isMobile ? "flex" : "hidden"
              )}
            />
          ) : (
            ""
          )}
        </Link>
      ) : (
        <div
          className={cn("w-full relative overflow-hidden")}
          style={{ paddingTop }}
        >
          <img
            loading="lazy"
            alt={"banner " + data?.mainPost?.title}
            // src={"/svgs/banners/banner-" + type + ".svg"}
            src={deskBannerSrc}
            className={cn(
              "absolute inset-0 w-full h-full items-center justify-center ",
              !isMobile ? "flex" : "hidden"
            )}
          />
          {mobileBannerSrc ? (
            <img
              alt={"banner " + data?.mainPost?.title}
              loading="lazy"
              // src={"/svgs/banners/banner-" + type + ".svg"}
              src={mobileBannerSrc}
              className={cn(
                "absolute inset-0 w-full h-full items-center justify-center",
                isMobile ? "flex" : "hidden"
              )}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
