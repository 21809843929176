import { IconProps } from "../types/types";

const InstagramIcon = ({ className, size }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 0.563 0.563"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M.413.131H.45M.169.019h.225a.15.15 0 0 1 .15.15v.225a.15.15 0 0 1-.15.15H.169a.15.15 0 0 1-.15-.15V.169a.15.15 0 0 1 .15-.15Zm.112.375a.112.112 0 1 1 0-.225.112.112 0 0 1 0 .225Z"
        // stroke="transparent"
        strokeWidth=".038"
      />
    </svg>
  );
};

export default InstagramIcon;
