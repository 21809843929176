import { useQuery } from "@tanstack/react-query";
import { postSubscribe } from "@pulsex/services";
export default function useSubscribe(domain, email) {
    var _a, _b;
    function generateUserId() {
        return `user-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    }
    const userId = email ? generateUserId() : undefined;
    const query = useQuery(["SUBSCRIBE", email], () => postSubscribe(domain, email, userId), {
        retry: 0,
        cacheTime: 1000 * 60 * 60,
        refetchOnWindowFocus: false,
        enabled: !!email,
    });
    let status;
    if (!query.isLoading && query.data) {
        switch (query.data.code) {
            case 200:
                status = "success";
                break;
            case 400:
                if (((_a = query === null || query === void 0 ? void 0 : query.data) === null || _a === void 0 ? void 0 : _a.error) &&
                    ((_b = query === null || query === void 0 ? void 0 : query.data) === null || _b === void 0 ? void 0 : _b.error.includes("already subscribed"))) {
                    status = "alreadySubscribed";
                }
                else {
                    status = "error";
                }
                break;
            default:
                status = "error";
        }
    }
    return {
        isLoading: query.isLoading,
        status,
    };
}
