import widgetsModel from "./widgetsModel";
export default function widgetPageModel(config, rawPage) {
    return {
        title: rawPage === null || rawPage === void 0 ? void 0 : rawPage.title,
        id: rawPage === null || rawPage === void 0 ? void 0 : rawPage.id,
        postId: rawPage === null || rawPage === void 0 ? void 0 : rawPage.postId,
        widgets: widgetsModel(config, rawPage === null || rawPage === void 0 ? void 0 : rawPage.widgets),
        seo: rawPage === null || rawPage === void 0 ? void 0 : rawPage.seo,
        subMenus: rawPage === null || rawPage === void 0 ? void 0 : rawPage.subMenus,
        regionsMenu: rawPage === null || rawPage === void 0 ? void 0 : rawPage.regionsMenu,
        breadcrumbs: rawPage === null || rawPage === void 0 ? void 0 : rawPage.breadcrumbs
    };
}
