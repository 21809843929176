"use client";
import React from "react";
import { TitleSection } from "@/ui/Title/Index";
import { WidgetProps } from "@/types/types";
import SectionWrap from "@/components/SectionWrap/Index";
import { WriterCard } from "@/components/WriterCard/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import pulseConfig from "@/pulsex.config";

export const WritersGridWidget = ({ data }: WidgetProps) => {
  if (!data?.posts?.length) return;

  const pdfShow = data?.code.includes("pdfShow") ? true : false;

  return (
    <SectionWrap className="pt-4 pb-14">
      <AsideTitleColumn data={data} pdf={pdfShow} />

      <div
        className="grid grid-cols-2 md:grid-cols-4 gap-4 w-full"
        dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
      >
        {data?.posts?.map((item: any, index: any) => (
          <WriterCard
            className={""}
            data={item}
            cropSize="square"
            customSummary="line-clamp-4"
            key={index}
          />
        ))}
      </div>
    </SectionWrap>
  );
};
