import { Card } from "@/components/Card/Index";
import Pagination from "@/components/Pagination/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import SpinnerIcon from "@/icons/SpinnerIcon";
import pulseConfig from "@/pulsex.config";
import { AsideSearchColumn } from "@/ui/AsideSearchColumn/Index";
import { useSearch } from "@pulsex/hooks";
import { cn } from "@pulsex/utils";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

type Props = { isMobile?: any; types?: any };

const SearchSuspense = ({ isMobile, types }: Props) => {
  const [orderBy, setOrderBy] = useState<0 | 1>(0);
  const [page, setPage] = useState(1);
  const [activeTypes, setActiveTypes] = useState<string[]>([]);

  const searchParams = useSearchParams();
  const searchValue = searchParams.get("q");
  const searchDateFrom = searchParams.get("From");
  const searchDateTo = searchParams.get("To");
  const searchAuthor = searchParams.get("Author");
  const searchCategory = searchParams.get("Category");

  useEffect(() => {
    if (types && typeof types === "string") {
      const separatedTypes = types?.includes(",") ? types.split(",") : [types];
      setActiveTypes(separatedTypes);
    }
  }, [types]);


  const { data, isLoading } = useSearch(pulseConfig, {
    q: searchValue || "",
    orderBy,
    types: activeTypes,
    page,
    from: searchDateFrom ? searchDateFrom + "T00:00:00" : undefined,
    to: searchDateTo ? searchDateTo + "T00:00:00" : undefined,
    authors: searchAuthor ? searchAuthor : undefined,
    categories: searchCategory ? searchCategory : undefined,
  });

  const numberOfPages = Math.floor(data?.count / 10);
  const handleNext = () => {
    if (page === numberOfPages) return;
    setPage(page + 1);
  };
  const handlePrev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page, orderBy, activeTypes]);

  useEffect(() => {
    setPage(1);
  }, [searchDateFrom, searchDateTo]);

  return (
    <>
      <SectionWrap className="relative flex md:flex-row max-md:flex-col max-md:space-y-3 border-t-0">
        <AsideSearchColumn
          data={undefined}
          template="search"
          className="max-md:w-full"
        />
        <div className="flex-col space-y-4 flex w-full">
          <div
            className={cn(
              " w-fit text-3xl max-md:text-4xl lg:text-5xl text-[#242424] font-semibold pb-2 border-b-2 border-masterRed"
            )}
          >
            نتائج البحث
          </div>
          {isLoading ? (
            <div
              role="status"
              className=" w-full h-[50vh] flex items-center justify-center"
            >
              <SpinnerIcon className={"w-14 h-14 m-auto fill-masterRed"} />
            </div>
          ) : (
            ""
          )}
          {!isLoading && data && !data?.posts?.length ? (
            <p className="text-[#242424] mx-auto text2xl md:text-3xl lg:text-4xl">
              لم يتم العثور على نتائج
            </p>
          ) : (
            ""
          )}

          <div
            className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-md:pt-4 pt-6"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            {data &&
              data?.posts &&
              data?.posts?.map((item: any, index: any) => (
                <Card
                  key={index}
                  data={item}
                  className={cn(
                    "flex flex-col space-y-1 mr-3 mb-4 max-md:mr-2"
                    // index % 4 === 0 && "md:mr-4",
                    // index >= 4 && "md:mt-4",
                    // index % 2 === 0 && "max-md:mr-2",
                    // index >= 2 && "max-md:mt-4"
                  )}
                  cardOrientation="v"
                  customTitle="line-clamp-3"
                  customSummary="line-clamp-4"
                  isMobile={isMobile}
                  isSearch={true}
                />
              ))}
          </div>
          {numberOfPages > 1 && (
            <Pagination
              currentPage={page}
              setPage={setPage}
              numberOfPages={numberOfPages}
              handlePrev={handlePrev}
              handleNext={handleNext}
            />
          )}
        </div>
      </SectionWrap>
    </>
  );
};

export default SearchSuspense;
