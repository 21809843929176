"use client";
import { TitleSection } from "@/ui/Title/Index";
import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { InsideBanner } from "@/components/InsideBanner/Index";
import { extractSection } from "@/utils/extractSection";
import { usePathname } from "next/navigation";

export const TwoCardsGridOneTextAsideWidget = ({
  data,
  isMobile,
  hideCategory,
}: WidgetProps) => {
  const pathname = usePathname();
  const colorTheme = extractSection(pathname);
  if (!data?.posts?.length) return;
  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} colorTheme={colorTheme || ""} />

      <div className="max-md:flex-col max-md:space-y-2 w-full flex md:space-x-3 lg:space-x-4   rtl:md:!space-x-reverse ltr:md:flex-row-reverse   ">
        <Card
          widgetTitle={data?.title}
          widgetColor={data?.widgetColor || colorTheme}
          className={"md:w-1/2"}
          data={data.posts[0]}
          cropSize="wide"
          cardOrientation="v"
          isMobile={isMobile}
          showSummary={true}
          customTitle="line-clamp-1"
          customSummary="line-clamp-1"
          showCategory={hideCategory}
        />

        <div className="flex-col space-y-4 max-md:space-y-2 flex md:w-1/2">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={"max-md:flex-row"}
            data={data.posts[1]}
            resolution={3}
            cropSize="square"
            cardOrientation="h"
            isMobile={isMobile}
            customSummary="line-clamp-5 md:line-clamp-3 lg:line-clamp-5"
            customImage="md:min-w-[55%] md:max-w-[55%]"
            showCategory={hideCategory}
          />
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={""}
            data={data.posts[2]}
            cardOrientation="h"
            isMobile={isMobile}
            customSummary="line-clamp-1"
            customTitle="line-clamp-1"
            showImage={false}
            showCategory={hideCategory}
            customMetaFlex={"pt-[25px]"}
          />
        </div>
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
