"use client";
import { useQuery } from "@tanstack/react-query";
import { getPage } from "@pulsex/services";
import { widgetPageModel } from "@pulsex/models";
export default function useWidgetPage(config, pathname, initialData) {
    // extract the page form url
    const query = useQuery({
        queryKey: ["GET_PAGE", pathname],
        queryFn: () => getPage(config, pathname),
        initialData,
        refetchOnWindowFocus: false,
    });
    if (query.isError) {
        console.error("Query Error:", query.error);
    }
    let mappedData;
    if (query.isSuccess && query.data) {
        mappedData = widgetPageModel(config, query.data);
    }
    return {
        isLoading: query.isLoading,
        data: mappedData,
    };
}
