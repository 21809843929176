import menuItemModel from "../mappers/menuItemModel";
export default class Menu {
    constructor(menus) {
        var _a;
        this.menus = {};
        for (let menu in menus) {
            this.menus[menu] = (_a = menus[menu]) === null || _a === void 0 ? void 0 : _a.map((menuItem) => menuItemModel(menuItem));
        }
    }
    getMenuByKey(key) {
        return this.menus[key];
    }
}
