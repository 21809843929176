import { IconProps } from "../types/types";

export const ArrowLeftIcon = ({ className, size = 20, onClick }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path d="M3 7.5 11 0v15z" fill="" />
    </svg>
  );
};
