import React from "react";
import { cn } from "@pulsex/utils";
import Link from "next/link";
import { RightSign } from "@/icons/RightSign";
import { locale } from "@/locale";

type TabsProps = {
  showMore?: boolean;
  showTabs?: boolean;
  type?: "dark";
  className?: string;
  title?: string;
  customTitle?: string;
  moreUrl?: string | undefined;
  tabsArray?: { title: string; url: string }[];
  colorTheme?: string;
  showMobileMoreBtn?: any;
};

const moreTab = {
  url: "",
  title: "",
};

export const TitleSection = ({
  showMore = true,
  showTabs = true,
  className,
  title,
  type,
  customTitle,
  colorTheme,
  moreUrl,
  tabsArray = [],
  showMobileMoreBtn,
}: TabsProps) => {
  const getTabs = (): { title: string; url: string }[] => {
    if (showTabs && tabsArray.length) {
      return [...tabsArray];
    } else if (showMore && moreUrl && !showTabs) {
      return [moreTab];
    } else if (showMore && showTabs && tabsArray.length) {
      return moreUrl ? [...tabsArray, moreTab] : [...tabsArray];
    }
    return [];
  };

  return (
    <>
      {moreUrl ? (
        <Link
          href={moreUrl}
          className={cn(
            "flex items-center group lg:max-w-[183px]",
            showMobileMoreBtn &&
              "justify-between max-lg:items-center max-lg:w-full"
          )}
        >
          <h3
            className={cn(
              "text-3xl md:text-4xl cursor-pointer text-[#242424] font-semibold",
              type === "dark" && "text-white",
              customTitle
            )}
            style={{ color: colorTheme }}
          >
            {title || (tabsArray?.length ? tabsArray[0]?.title : "")}
          </h3>

          {showMobileMoreBtn ? (
            <p
              className={cn(
                "text-lg font-black text-gray-text self-end leading-5 max-lg:block hidden"
              )}
            >
              {locale.more}
            </p>
          ) : (
            ""
          )}
        </Link>
      ) : (
        <h3
          className={cn(
            "text-3xl lg:text-4xl cursor-pointer text-[#242424] font-semibold",
            type === "dark" && "text-white",
            customTitle
          )}
          style={{ color: colorTheme }}
        >
          {title || (tabsArray?.length ? tabsArray[0]?.title : "")}
        </h3>
      )}

      {getTabs() && getTabs()?.length > 0 ? (
        <ul className="mt-2 max-md:mb-2 flex items-start justify-start gap-1 text-base font-black text-greyText rtl:lg:mr-0 ltr:lg:ml-0 lg:mt-6 lg:flex-col lg:text-[16px] overflow-x-scroll max-w-full">
          {getTabs()?.map((tab, index) => (
            <li
              key={index}
              className={cn(
                "border-l-[1px] border-l-gray-d last:border-none lg:border-none  whitespace-nowrap",
                "rtl:max-md:pl-1.5 rtl:pl-1 rtl:lg:pl-0  ltr:max-md:pr-1.5 ltr:pr-1 ltr:lg:pr-0",
                `hover:text-minorTheme`
              )}
            >
              {tab.url ? (
                <Link href={tab.url}>{tab.title}</Link>
              ) : (
                <span>{tab.title}</span>
              )}
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </>
  );
};
