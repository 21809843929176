"use client";
import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { extractSection } from "@/utils/extractSection";
import { usePathname } from "next/navigation";

export const FourCardsGridHorizontalSectionsWidget = ({
  data,
  isMobile,
  hideCategory,
}: WidgetProps) => {
  const pathname = usePathname();
  const colorTheme = extractSection(pathname);
  if (!data?.posts?.length) return;
  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} colorTheme={colorTheme || ""} />
      <div className="flex flex-col space-y-4 max-md:space-y-2 w-full">
        <div className="max-md:flex-col max-md:space-y-2 max-md:space-x-0 flex space-x-4  rtl:space-x-reverse ltr:md:flex-row-reverse">
          {data.posts.slice(0, 2).map((post: any, index: number) => (
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor || colorTheme}
              key={index}
              className={"md:w-1/2"}
              data={post}
              cardOrientation="h"
              isMobile={isMobile}
              customSummary="md:line-clamp-3 lg:line-clamp-4"
              cropSize="square"
              showCategory={hideCategory}
            />
          ))}
        </div>
        <div className="max-md:flex-col max-md:space-y-2 max-md:space-x-0 flex space-x-4  rtl:space-x-reverse ltr:md:flex-row-reverse">
          {data.posts.slice(2, 4).map((post: any, index: number) => (
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor || colorTheme}
              key={index}
              className={"md:w-1/2"}
              data={post}
              cardOrientation="h"
              isMobile={isMobile}
              customSummary="line-clamp-4"
              cropSize="square"
              showCategory={hideCategory}
            />
          ))}
        </div>
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
