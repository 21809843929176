"use client";
import { useEffect, useState } from "react";
import { WidgetProps } from "@/types/types";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import pulseConfig from "@/pulsex.config";
import { cn } from "@pulsex/utils";
import Link from "next/link";

export const ElasticRelatedGrid = ({ data, postId }: WidgetProps) => {
  const [relatedData, setRelatedData] = useState<any>();
  const now = new Date();
  const utcPlus2Now = new Date(now.getTime() + 2 * 60 * 60 * 1000);
  const utcPlus2From = new Date(utcPlus2Now.getTime() - 30 * 24 * 60 * 60 * 1000);
  
  const formatDate = (date: Date) => {
    function pad(n: number): string {
      return n < 10 ? '0' + n : '' + n;
    }
  
    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1);
    const day = pad(date.getUTCDate());
    const hours = pad(date.getUTCHours());
    const minutes = pad(date.getUTCMinutes());
    const seconds = pad(date.getUTCSeconds());
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
  const fromDate = formatDate(utcPlus2From);
  const toDate = formatDate(utcPlus2Now);

  useEffect(() => {
    if (!postId) return;

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://mdn-search.mangopulse.net/mangopulse/related?&id=${postId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: "akhbar",
              fromDate: fromDate,
              toDate: toDate,
              size: 4,
            }),
          });

        const result = await response.json();
        if (result?.data) setRelatedData(result?.data);

      } catch (error) {
        console.log("elastic related error", error);
      }
    };

    fetchData();
  }, [postId]);

  if (!relatedData || !relatedData?.length) return;

  const colorTheme = relatedData
    ? `var(--theme-${(
        relatedData[0]?.category || relatedData[2]?.category
      )?.replace(/ /g, "-")})`
    : "";

  return (
    <SectionWrap className="pt-4 pb-14">
      <AsideTitleColumn data={data} colorTheme={colorTheme} />

      <div
        className="grid grid-cols-1 md:grid-cols-2 w-full"
        dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
      >
        {relatedData?.map((item: any, index: any) => (
          <div
            key={index}
            className="flex relative group overflow-hidden flex-col md:flex-row bg-greyBackground max-md:mt-2 mt-4 mr-4 max-md:mr-0 "
          >
            <div className="flex flex-col items-start max-sm:ml-0 rtl:lg:mr-1.5 ltr:lg:ml-1.5 flex-1 rtl:md:mr-0 ltr:md:ml-0 w-full">
              <div className="h-full w-full flex flex-col ltr:items-end p-2 max-md:p-1.5 mt-0">
                <label
                  className={cn(
                    "h-fit w-fit cursor-pointer text-xl font-amine-light leading-[1] pb-[2px] max-md:pb-0 lg:mt-0",
                    !item?.category && "h-[22px]"
                  )}
                  style={{ color: data?.widgetColor || colorTheme }}
                >
                  {item?.category}
                </label>
                <Link
                  href={item?.publicUrl || ""}
                  className="cursor-pointer relative max-sm:my-1 text-2xl max-lg:text-xl max-sm:text-xl leading-6 max-lg:leading-6 text-black rtl:lg:pl-4 ltr:lg:pr-4 line-clamp-2"
                >
                  {item?.title}
                </Link>
                <div className="flex space-x-1 rtl:space-x-reverse ltr:flex-row-reverse items-center justify-between w-full h-fit mt-auto pt-4">
                  <div className="text-sm w-fit cursor-pointer text-minorTheme">
                    {item?.author || "الأخبار"}
                  </div>
                  <p
                    className="text-[#848484] text-[10px] font-semibold"
                    style={{ fontFamily: '"Helvetica Bold", sans-serif' }}
                  >
                    {item?.date}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </SectionWrap>
  );
};
