import { IconProps } from "../types/types";

const FacebookIcon = ({ className, size }: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="-0.219 -0.063 0.75 0.75"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin"
    >
      <path d="M.064.121v.086H.001v.105h.063v.312h.129V.312H.28S.288.262.292.207H.194V.134c0-.011.014-.025.028-.025h.07V0H.196C.06 0 .064.105.064.121" />
    </svg>
  );
};

export default FacebookIcon;
