import { useState, useEffect } from "react";

export function useScroll() {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [bodyOffset, setBodyOffset] = useState<DOMRect>();
  const [scrollY, setScrollY] = useState(bodyOffset && bodyOffset.top);
  const [scrollDirection, setScrollDirection] = useState<any>();

  const listener = (e: any) => {
    setBodyOffset(document.body.getBoundingClientRect());
    bodyOffset && setScrollY(-bodyOffset.top);
    bodyOffset &&
      setScrollDirection(lastScrollTop > -bodyOffset?.top ? "down" : "up");
    bodyOffset && setLastScrollTop(-bodyOffset.top);
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  return {
    scrollY,
    scrollDirection,
  };
}
