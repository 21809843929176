import React from "react";
import { cn } from "@pulsex/utils";
import { formatArabicDate } from "@/utils/formatDate";

export const UrgentWidget = ({
  urgentNewsAside,
  isDailyEdition,
  template,
}: any) => {
  if (!urgentNewsAside?.posts) return;

  return (
    <div className="bg-greyBackground/65 flex flex-col space-y-2 h-full shadow-sm urgent-widget max-sm:min-h-80">
      <div className="flex items-center bg-masterRed p-2">
        <p className="circle relative h-3 w-3 bg-white rounded-full" />

        <p className="text-customPink text-2xl text-white font-amine-light mr-3">
          {urgentNewsAside?.title}
        </p>
      </div>
      <div
        className={cn(
          "block overflow-scroll px-2 max-lg:h-[300px]",
          isDailyEdition
            ? "h-[500px]"
            : "h-[450px] miniDeskMin:h-[415px] max-miniDeskMax:h-[415px] xl:h-[450px] 2xl:h-[526px]",
          template === "sections" && "h-[458px] 2xl:h-[528px]"
        )}
      >
        {urgentNewsAside?.posts &&
          urgentNewsAside?.posts?.map((item: any, index: any) => (
            <div
              className="py-2.5 border-b border-gray-300 rtl:md:mr-1 rtl:lg:mr-1.5 ltr:md:ml-1 ltr:lg:ml-1.5 flex-col lg:flex-row ltr:flex-row-reverse flex max-lg:block lg:space-x-1 rtl:space-x-reverse"
              key={index}
            >
              <div className="flex lg:space-x-2 text-base md:text-sm lg:text-[15px] min-w-20 ltr:justify-end">
                {item?.displayDate ? (
                  <>
                    <p className="text-masterRed font-amine-light block">
                      {item?.displayDate}
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div>
                {item?.title ? (
                  <p
                    aria-label={item?.title}
                    className="font-greta-light text-black block"
                  >
                    {item?.title}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
