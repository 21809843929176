import { WidgetProps } from "@/types/types";
import { TitleSection } from "@/ui/Title/Index";
import React, { useEffect, useState } from "react";
import SectionWrap from "./SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";

const StaticPage = ({ data }: WidgetProps) => {
  const [cleanContent, setCleanContent] = useState({});

  useEffect(() => {
    if (data?.mainPost?.content) {
      const fetchAuthor = () => {
        const purify = (html: string) => {
          if (html) return html.replace(/<style>[\s\S]*<\/style>/, "");
        };
        setCleanContent(purify(data?.mainPost?.content)!);
      };

      fetchAuthor();
    }
  }, [data?.mainPost?.content]);

  if (!data?.mainPost) return;

  return (
    <>
      <SectionWrap className="flex flex-col space-y-3 lg:pt-8">
        <AsideTitleColumn data={data} customTitle="xl:text-[42px] lg:pt-2"/>

        <div className="prose !m-0 max-w-full">
          {data?.mainPost?.content && (
            <div className="static-content text-xl text-[#242424] md:w-3/4 font-greta-light leading-8">
              {cleanContent ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: cleanContent,
                  }}
                ></div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </SectionWrap>
    </>
  );
};

export default StaticPage;
