import { cn } from "@pulsex/utils";
import React from "react";

interface PageButtonProps {
  page: number;
  currentPage: number;
  active?: boolean;
  onClick: any;
  className?: string;
}

const PaginateButton = ({
  page,
  currentPage,
  active,
  onClick,
  className,
}: PageButtonProps) => {
  const activeBtn = page === currentPage ? true : false;
  return (
    <button
      onClick={onClick}
      className={cn(
        "flex select-none items-center justify-center rounded-full text-minorTheme border text-lg border-minorTheme h-7 min-w-7 aspect-square",
        active || activeBtn
          ? "bg-minorTheme text-white"
          : "hover:bg-minorTheme/20",
        className
      )}
    >
      {page}
    </button>
  );
};

export default PaginateButton;
