import SectionWrap from "../../components/SectionWrap/Index";
import { Card } from "../../components/Card/Index";
import { cn } from "@pulsex/utils";
import { WidgetProps } from "@/types/types";
import { useLoadMore } from "@pulsex/hooks";
import pulseConfig from "@/pulsex.config";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { AsideSearchColumn } from "@/ui/AsideSearchColumn/Index";
import { locale } from "@/locale";

export const MorePageTemplate = ({ data, isMobile }: WidgetProps) => {
  const LoadQ = useLoadMore(pulseConfig, data?.loadMoreLink, data?.posts);
  const fetchNext = () => {
    if (LoadQ.hasNextPage && !LoadQ.isFetchingNextPage) LoadQ.fetchNextData();
    else console.log("no more data");
  };

  return (
    <>
      {data?.posts?.length > 0 && (
        <>
          <SectionWrap className="relative">
            <AsideSearchColumn data={data} />
            <div className="flex-col space-y-4 max-md:space-y-2 flex">
              <div className="max-md:flex-col max-md:space-y-2 flex md:md:space-x-3 lg:space-x-4   rtl:md:!space-x-reverse ltr:md:flex-row-reverse   ">
                {LoadQ?.data.slice(0, 2).map((post: any, index: number) => (
                  <Card
                    widgetColor={data?.widgetColor}
                    key={index}
                    className={"md:w-1/2"}
                    data={post}
                    cardOrientation="h"
                    isMobile={isMobile}
                    customSummary="line-clamp-4"
                    cropSize="square"
                  />
                ))}
              </div>
              <div
                className="grid grid-cols-1 md:grid-cols-2 gap-4 max-md:gap-2"
                dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
              >
                {LoadQ?.data?.slice(2).map((item: any, index: any) => (
                  <Card
                    widgetColor={data?.widgetColor}
                    key={index}
                    data={item}
                    className=""
                    cardOrientation="h"
                    customTitle="line-clamp-2"
                    isMobile={isMobile}
                    showImage={false}
                    showSummary={false}
                  />
                ))}
              </div>
              <div className="flex flex-col items-center justify-center mt-10 mb-0 container">
                {!LoadQ.isLoading && !LoadQ.hasNextPage && (
                  <div className="text-center pt-5 w-full text-2xl text-masterRed">
                    {locale.noMore}
                  </div>
                )}
                {LoadQ.hasNextPage && (
                  <div
                    className={cn(
                      "flex space-x-2.5 w-fit cursor-pointer",
                      LoadQ?.isFetching && ""
                    )}
                    onClick={() => fetchNext()}
                  >
                    <div
                      className={cn(
                        "w-3 h-3 bg-masterRed rounded-full",
                        LoadQ?.isFetching &&
                          "animate-bounce [animation-delay:-0.4s]"
                      )}
                    />
                    <div
                      className={cn(
                        "w-3 h-3 bg-masterRed rounded-full",
                        LoadQ?.isFetching &&
                          "animate-bounce [animation-delay:-0.16s]"
                      )}
                    />
                    <div
                      className={cn(
                        "w-3 h-3 bg-masterRed rounded-full",
                        LoadQ?.isFetching && "animate-bounce"
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          </SectionWrap>
        </>
      )}
    </>
  );
};
