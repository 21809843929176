import { postModel } from "@pulsex/models";
import { getLoadMore } from "@pulsex/services";
import { useInfiniteQuery } from "@tanstack/react-query";
import { removePageValue } from "@pulsex/utils";
export default function useLoadMore(config, url, initialData, queryKeys = []) {
    var _a;
    const query = useInfiniteQuery(["LOADMORE", url, ...queryKeys], ({ pageParam = 1 }) => getLoadMore(config, `${removePageValue(url)}&page=${pageParam}`), {
        enabled: !!url,
        initialData: { pageParams: [undefined], pages: [initialData] },
        getNextPageParam: (lastPage, pages) => {
            if ((lastPage === null || lastPage === void 0 ? void 0 : lastPage.widgets) &&
                (lastPage === null || lastPage === void 0 ? void 0 : lastPage.widgets[0]) &&
                !(lastPage === null || lastPage === void 0 ? void 0 : lastPage.widgets[0].posts)) {
                return undefined;
            }
            return pages.length + 1;
        },
        refetchOnWindowFocus: false,
    });
    const mappedPosts = [];
    if ((query === null || query === void 0 ? void 0 : query.isSuccess) && (query === null || query === void 0 ? void 0 : query.data)) {
        (_a = query === null || query === void 0 ? void 0 : query.data) === null || _a === void 0 ? void 0 : _a.pages.map((responseData) => {
            var _a;
            if (responseData === null || responseData === void 0 ? void 0 : responseData.widgets) {
                responseData === null || responseData === void 0 ? void 0 : responseData.widgets.map((widgetData) => {
                    var _a, _b;
                    if ((_a = widgetData === null || widgetData === void 0 ? void 0 : widgetData.posts) === null || _a === void 0 ? void 0 : _a.length) {
                        const posts = (_b = widgetData === null || widgetData === void 0 ? void 0 : widgetData.posts) === null || _b === void 0 ? void 0 : _b.map((post) => postModel(config, post));
                        if (posts === null || posts === void 0 ? void 0 : posts.length) {
                            posts === null || posts === void 0 ? void 0 : posts.map((item) => {
                                item && (mappedPosts === null || mappedPosts === void 0 ? void 0 : mappedPosts.push(item));
                            });
                        }
                    }
                });
            }
            else {
                const posts = (_a = responseData === null || responseData === void 0 ? void 0 : responseData.posts) === null || _a === void 0 ? void 0 : _a.map((post) => postModel(config, post));
                if (posts === null || posts === void 0 ? void 0 : posts.length) {
                    posts === null || posts === void 0 ? void 0 : posts.forEach((item) => {
                        mappedPosts === null || mappedPosts === void 0 ? void 0 : mappedPosts.push(item);
                    });
                }
                else {
                    responseData === null || responseData === void 0 ? void 0 : responseData.forEach((post) => mappedPosts === null || mappedPosts === void 0 ? void 0 : mappedPosts.push(post));
                }
            }
        });
    }
    return {
        isLoading: query.isLoading,
        data: mappedPosts,
        fetchNextData: query.fetchNextPage,
        isFetching: query.isFetching,
        isFetchingNextPage: query.isFetchingNextPage,
        hasNextPage: query.hasNextPage,
    };
}
