import { IconProps } from "../types/types";

const YouTubeIcon = ({ className, size }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 1.5 1.5"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g data-name="Layer 2">
        <g data-name="invisible box" fill="none">
          <path d="M0 0h1.5v1.5H0z" />
          <path d="M0 0h1.5v1.5H0z" />
        </g>
        <path
          d="M1.409 0.4a0.172 0.172 0 0 0 -0.122 -0.122C1.181 0.25 0.75 0.25 0.75 0.25s-0.431 0 -0.537 0.028a0.172 0.172 0 0 0 -0.122 0.122C0.063 0.506 0.063 0.731 0.063 0.731s0 0.225 0.028 0.331a0.172 0.172 0 0 0 0.122 0.122c0.106 0.028 0.537 0.028 0.537 0.028s0.431 0 0.537 -0.028a0.172 0.172 0 0 0 0.122 -0.122c0.028 -0.106 0.028 -0.331 0.028 -0.331s0 -0.225 -0.028 -0.331M0.613 0.938V0.525L0.969 0.731Z"
          data-name="icons Q2"
        />
      </g>
    </svg>
  );
};

export default YouTubeIcon;
