import { StringsObject } from "@pulsex/types";

class MangopulseClient {
  async post(
    API_URL: string,
    endpoint: string,
    bodyParams: StringsObject,
    queryParams?: StringsObject,
    headers?: StringsObject
  ): Promise<unknown> {
    if (!endpoint) return;

    return this.fetch(API_URL, appendQueryParameters(endpoint, queryParams), {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        ...(headers || {}),
      },
      // body: Object.entries(bodyParams)
      //     .map(
      //         ([key, value]) =>
      //             `${encodeURIComponent(key)}=${encodeURIComponent(
      //                 value as any
      //             )}`
      //     )
      //     .join("&"),
      body: JSON.stringify(bodyParams),
    });
  }

  async get(
    API_URL: string,
    endpoint: string,
    queryParams?: StringsObject
  ): Promise<unknown> {
    if (!endpoint) return;
    return this.fetch(API_URL, appendQueryParameters(endpoint, queryParams));
  }

  private async fetch(
    API_URL: string,
    endpoint: string,
    options?: RequestInit
  ): Promise<unknown> {
    if (!endpoint) return;
    if (endpoint.charAt(0) !== "/") endpoint = "/" + endpoint;

    return fetch(API_URL + endpoint, {
      cache: "no-store",
      headers: { "content-type": "application/json;charset=UTF-8" },
      ...options,
    }).then(async (res) => {
      if (!res.ok) throw new Error(`HTTP error! Status: ${res.status}`);
      const json = await res.json();
      return json;
    });
  }
}

const client = new MangopulseClient();
export default client;

export function appendQueryParameters(
  url: string,
  params?: StringsObject
): string {
  if (!url) return "";
  const urlHasParams = url.includes("?");
  const separator = urlHasParams ? "&" : "?";
  params = params ?? {};
  const filteredParams = Object.entries(params as never)
    .filter(
      ([_, value]) => typeof value !== "function" && typeof value !== "object"
    )
    .map(([key, value]) => `${key}=${value as string}`)
    .join("&");

  return `${url}${separator}${filteredParams}&platform=headless`;
}