"use client";
import React, { useState } from "react";
import Image from "next/image";
import { cn, getCropImage } from "@pulsex/utils";
const constructUrl = (url, params) => {
    try {
        const parsedUrl = new URL(url);
        const urlParams = new URLSearchParams(parsedUrl.search);
        Object.entries(params)
            .filter(([_, value]) => typeof value !== "function" && typeof value !== "object")
            .map(([key, value]) => {
            if (value) {
                urlParams.set(key, String(value)); // Convert to string if it's not undefined or null
            }
        });
        // Append new parameters
        Object.entries(params).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                urlParams.set(key, String(value)); // Convert to string if it's not undefined or null
            }
        });
        // Construct the final URL with updated query parameters
        parsedUrl.search = urlParams.toString();
        return parsedUrl.toString();
    }
    catch (e) {
        console.error(e);
        return "";
    }
};
const MangoXImage = ({ config, data, className, ImageElementclassName, cropSize, needsMediaURL = false, resolution, style = {}, priority, children, }) => {
    const [primaryError, setPrimaryError] = useState(false);
    const [secondaryError, setSecondaryError] = useState(false);
    const [thirdError, setThirdError] = useState(false);
    const upscaleImage = (cropSize, resolution) => {
        const size = {
            width: 300 * (resolution ? resolution : 2),
            height: 300,
            paddingTop: 0,
        };
        const setHeight = (crop) => Math.trunc(size.width / parseFloat(crop));
        const setPaddingTop = (height) => ((height / size.width) * 100).toFixed(2);
        size.height = setHeight(config["cropSizes"][cropSize]);
        size.paddingTop = setPaddingTop(size.height);
        return size;
    };
    let imageSize = upscaleImage(config["cropRatios"][cropSize], resolution ? resolution : 2);
    const getSrc = () => {
        if (!(data === null || data === void 0 ? void 0 : data.image) || thirdError) {
            return `/svgs/fallback/default${config["cropRatios"][cropSize]}.svg`;
        }
        if (primaryError) {
            return constructUrl(data === null || data === void 0 ? void 0 : data.image, {
                width: imageSize.width,
                height: imageSize.height,
                mode: "crop",
            });
        }
        if (secondaryError) {
            return constructUrl(data === null || data === void 0 ? void 0 : data.image, {
                width: imageSize.width,
                height: imageSize.height,
                mode: "crop",
                format: "webp",
            });
        }
        return needsMediaURL
            ? config["MEDIA_URL"] +
                getCropImage(config, (data === null || data === void 0 ? void 0 : data.image) || (data === null || data === void 0 ? void 0 : data.url), cropSize)
            : constructUrl(getCropImage(config, (data === null || data === void 0 ? void 0 : data.image) || (data === null || data === void 0 ? void 0 : data.url), cropSize), {
                width: imageSize.width,
                format: "webp",
            });
    };
    const handleError = () => {
        if (!primaryError) {
            setPrimaryError(true);
        }
        else if (!secondaryError) {
            setSecondaryError(true);
        }
        else if (!thirdError) {
            setThirdError(true);
        }
    };
    const ImageComponent = priority ? Image : "img";
    return (React.createElement("div", { style: Object.assign({ paddingTop: `${imageSize.paddingTop}%` }, style), className: cn("w-full block rounded-lg bg-neutral-300 overflow-hidden relative bg-gray-200 dark:bg-gray-200", className) },
        React.createElement(ImageComponent, { loading: priority ? "eager" : "lazy", alt: (data === null || data === void 0 ? void 0 : data.imageCaption) || (data === null || data === void 0 ? void 0 : data.title) || "Image", src: getSrc(), onError: handleError, className: cn("absolute left-0 top-0 w-full h-full", ImageElementclassName), width: imageSize.width, height: imageSize.height, priority: priority }),
        children));
};
export default MangoXImage;
