"use client";
import { useEffect, useState } from "react";
import Link from "next/link";
import { WidgetProps } from "@/types/types";
import { isEmpty } from "@pulsex/utils";
import { cn } from "@pulsex/utils";
import SectionWrap from "../SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import { getViewport } from "@/utils/getViewPort";

export const HorizontalBanner = ({ data, isMobile, isApple }: WidgetProps) => {
  const { viewWidth, viewHeight } = getViewport();
  const [deskWidth, setDeskWidth] = useState(1650);
  const [mobileWidth, setMobileWidth] = useState(0);
  const [isMac, setIsMac] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isApple) {
      setIsMac(false);
      setIsLoading(false);
      return;
    }

    const checkMac = () => {
      setIsLoading(true);
      try {
        const width = window.innerWidth;
        setIsMac(width >= 1280);
        setIsLoading(false);
      } catch (error) {
        console.error("Error detecting Mac device:", error);
        setIsLoading(false);
      }
    };

    checkMac();
  }, [isApple]);

  useEffect(() => {
    if (viewWidth && viewWidth > 1520) {
      setDeskWidth(viewWidth);
    }
    if (viewWidth && viewWidth > 420 && viewWidth < 1180) {
      setMobileWidth(1000);
    }
  }, [viewWidth]);

  if (!data?.posts?.length) return;

  const desktopImage = data?.mainPost?.medias.filter(
    (media: any) => media.placement === "desktop-cover"
  )[0];
  const mobileImage = data?.mainPost?.medias.filter(
    (media: any) => media.placement === "mobile-cover"
  )[0];

  let bannerSrc =
    isMobile && !isMac
      ? mobileImage?.url +
        `?width=${mobileWidth > 0 ? (!isApple ? mobileWidth : 800) : 800}`
      : desktopImage?.url + `?width=${deskWidth}`;

  return (
    <>
      {!isEmpty(bannerSrc) ? (
        <SectionWrap className="!py-5">
          <AsideTitleColumn data={data} className="max-lg:hidden" />
          <div className={cn("overflow-hidden w-full")}>
            <Link
              href={data?.mainPost?.url ? `${data?.mainPost?.url}` : ""}
              className="w-full bg-neutral-400/80  "
            >
              <img
                loading="lazy"
                src={bannerSrc}
                alt={data?.title || "banner"}
                width={isMobile ? (mobileWidth > 0 ? mobileWidth : 700) : 1000}
                height={116}
                className="w-full"
              />
            </Link>
          </div>
          <MoreWrap />
        </SectionWrap>
      ) : (
        ""
      )}
    </>
  );
};
