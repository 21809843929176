"use client";
import { cn } from "@pulsex/utils";
import React, { Suspense } from "react";
import SearchInput from "@/components/SearchInput/Index";
import DailyPdf from "@/components/DailyPdf/Index";
import { locale } from "@/locale";

type AsideProps = {
  data: any;
  className?: string;
  template?: string;
  setIsLoading?: any;
  setResponse?: any;
  pageTitle?: any;
  dailyPdf?: any;
};

export const AsideMoreColumn = ({
  data,
  className,
  template,
  setIsLoading,
  setResponse,
  pageTitle,
  dailyPdf,
}: AsideProps) => {
  let subMenus: Array<any> | undefined;

  try {
    subMenus = data?.subMenus ? JSON.parse(data?.subMenus) : undefined;
  } catch (error) {
    console.error("Failed to parse subMenus:", error);
    subMenus = undefined;
  }

  if (!data && !template) return;

  const picData = data?.mainPost?.postType === "picture";
  const videoData = data?.mainPost?.postType === locale.video;

  const title = pageTitle.includes("-") ? pageTitle.split("-")[0] : "";
  const subTitle = pageTitle.includes("-") ? pageTitle.split("-")[1] : "";

  const colorTheme = title ? `var(--theme-${title.replace(/ /g, "-")})` : "";

  if (!data?.posts?.length) return;

  return (
    <div
      className={cn(
        "max-md:mt-4 max-md:mb-2 flex w-[200px] min-w-[200px] flex-col rtl:items-start ltr:items-end justify-start max-md:w-full",
        className
      )}
    >
      <div className="flex flex-col max-md:flex-row max-md:items-end border-none py-3 sm:block  max-md:p-0 max-md:py-2 md:block md:border-b-2 md:border-b-black ">
        {data?.mainPost?.tag?.title || pageTitle.includes("-") ? (
          <div
            className={cn(
              "text-4xl max-md:text-4xl font-amine-bold border-none",
              "rtl:pl-2 ltr:pr-2",
              "h-fit w-fit"
            )}
            style={{
              color:
                (pageTitle === locale.video && "var(--theme-المزيد)") ||
                (pageTitle === "كومكس" && "var(--theme-comics)") ||
                (title === "قضايا وآراء" && "var(--theme-opinion)") ||
                (pageTitle === "كاريكاتور" && "var(--theme-opinion)") ||
                data?.mainPost?.tag?.color ||
                colorTheme,
            }}
          >
            {pageTitle === locale.video
              ? locale.video
              : title !== locale.more
              ? title || data?.mainPost?.tag?.title
              : subTitle}
          </div>
        ) : (
          ""
        )}

        {title !== "المزيد" ? (
          (data?.mainPost?.terms && data?.mainPost?.terms[0]?.title) ||
          pageTitle.includes("-") ? (
            <div
              className={cn(
                "h-fit w-fit font-amine-light mt-2 max-md:mr-3",
                "text-2xl h-fit max-md:pb-1 !text-black border-none",
                className
              )}
            >
              {subTitle}
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>

      <>
        <div className="mt-10 max-md:mt-4 text-2xl font-amine-light">
          <div>{locale.searchIndicatorMessage}</div>
          <div>{locale.searchSubIndicatorMessage} </div>
        </div>

        <Suspense>
          <SearchInput
            setData={setResponse}
            setIsLoading={setIsLoading}
            className={"my-0 rounded-none"}
          />
        </Suspense>
        <DailyPdf
          className="max-md:hidden mt-4 lg:mt-8 md:sticky md:top-48 lg:top-20 max-md:w-1/2"
          pdfData={dailyPdf}
        />
      </>
    </div>
  );
};
