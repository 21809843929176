import { cn } from "@pulsex/utils";
import React from "react";

type SummaryProps = {
  children: React.ReactNode;
  className?: string;
  url?: string;
};

export const Summary = ({ children, className, url }: SummaryProps) => {
  return (
    <>
      <div
        className={cn(
          "w-fit mt-1 cursor-pointer text-[15px] max-sm:py-0 font-greta-light line-clamp-5",
          className
        )}
        // style={{
        //   fontFamily: "Droid Arabic Naskh,sans-serif",
        //   fontWeight: "500",
        // }}
      >
        {children}
      </div>
    </>
  );
};
