import { cn } from "@pulsex/utils";
import Link from "next/link";
import React from "react";

type CategoryProps = {
  item?: any;
  className?: string;
  color?: string;
};

export const Category = ({ item, className, color }: CategoryProps) => {
  return item?.title ? (
    <Link
      href={item?.url ? item?.url : ""}
      className={cn(
        "h-fit w-fit cursor-pointer text-xl font-amine-light leading-[1]",
        className
      )}
      style={{ color: color || item?.color }}
    >
      {item?.title}
    </Link>
  ) : (
    ""
  );
};
