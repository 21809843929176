"use client";
import { ArrowDownIcon } from "@/icons/ArrowDownIcon";
import { SearchIcon } from "@/icons/SearchIcon";
import { cn } from "@pulsex/utils";
import Link from "next/link";
import React, { useRef } from "react";
import { usePathname, useRouter } from "next/navigation";
import { Menu } from "@pulsex/models";
import { Logo } from "@/icons/Logo";
import useDailyPdf from "@/hooks/useDailyPdf";
import { locale } from "@/locale";
import pulseConfig from "@/pulsex.config";
type MenuProps = {
  menus: Menu;
  status: string;
  scrolled?: boolean;
  scrollDirection?: string;
};

const DeskMenu = ({
  menus,
  status,
  scrolled = false,
  scrollDirection,
}: MenuProps) => {
  const router = useRouter();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const pathname = usePathname();

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();

    const searchQuery = searchInputRef.current?.value;
    if (searchQuery) {
      searchQuery.trim().length > 2 &&
        router?.push(`/search?q=${searchQuery.trim()}`);
    }
  };

  const date = new Date();

  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    numberingSystem: pulseConfig.LANGUAGE === "ar" ? "arab" : "latn",
  };
  const formattedDate = date
    .toLocaleDateString(
      pulseConfig.LANGUAGE === "ar" ? "ar-LB" : "en-US",
      options
    )
    .replace("،", "");
  const isHomePage = pathname === "/";
  const isNewsPage = pathname === "/Newspaper%20Articles";
  const isNewsPaperDetails =
    pathname.includes("/newspaper/") ||
    decodeURIComponent(pathname).includes(locale.todaysRelease);
  const { isLoading, data } = useDailyPdf(pulseConfig);
  const dailyPdfURL = data?.mainPost?.url;

  return (
    <>
      <header
        className={cn(
          "z-40 duration-500 bg-white",
          scrollDirection === "down" && "sticky top-0"
        )}
      >
        <div className="container">
          <div className="mx-auto flex flex-col items-stretch justify-center">
            <div
              className={cn(
                "container flex items-center justify-between py-4",
                pulseConfig.LANGUAGE !== "ar" && "flex-row-reverse"
              )}
            >
              <a href="/" aria-label="home" className="">
                <Logo className="fill-black" size={40} />
              </a>

              <nav className="items-start justify-center text-white flex w-full mx-5">
                <div className="w-full items-start justify-center text-black sm:block lg:flex ltr:lg:flex-row-reverse">
                  {status === "pending" ? (
                    <>
                      <div className={cn("w-5", "h-[88px]")} />
                    </>
                  ) : (
                    menus
                      ?.getMenuByKey("h-main")
                      ?.map((menuItem: any, index: number) => (
                        <div
                          key={index}
                          className={cn(
                            "relative menu-item bg-greyBackground hidden lg:block basis-full flex-col items-start justify-start",
                            `group/item${index}`,
                            pathname.length > 2 &&
                              menuItem?.url?.includes(
                                pathname?.split("/")[1]
                              ) &&
                              " active color-" + menuItem.url.substring(1)
                          )}
                        >
                          <div className="flex w-full items-center relative">
                            <Link
                              href={menuItem?.url ? menuItem?.url : ""}
                              onClick={undefined}
                              aria-label={menuItem?.title}
                              className={cn(
                                "flex cursor-pointer text-xl miniDeskMin:text-[1.1rem] miniDeskMax:text-xl flex-1 min-w-fit",
                                "py-1.5 rtl:pr-1.5 ltr:pl-1.5 menu-item-title",
                                "justify-start",
                                menuItem?.subMenus &&
                                  menuItem?.subMenus?.length > 0 &&
                                  "flex items-center",
                                pulseConfig.LANGUAGE !== "ar" &&
                                  "flex-row-reverse"
                              )}
                              style={{
                                borderColor: menuItem?.color,
                                borderRightWidth: "9px",
                                paddingRight: "5px",
                              }}
                            >
                              {menuItem?.title}
                              {menuItem?.subMenus &&
                                menuItem?.subMenus?.length > 0 && (
                                  <ArrowDownIcon
                                    size={12}
                                    className={cn(
                                      "rtl:mr-1 ltr:ml-1 transition-transform duration-75 hover:fill-primary fill-black",
                                      `group-hover/item${index}:rotate-180`
                                    )}
                                  />
                                )}
                            </Link>
                            {menuItem?.subMenus &&
                              menuItem?.subMenus?.length > 0 && (
                                <div
                                  className={cn(
                                    "absolute right-0 top-full border !border-t-0 !border-b-0 rtl:!border-l-greyBackgroundDim/35 ltr:!border-r-greyBackgroundDim/35 bg-greyBackground z-10",
                                    " flex-col space-y-2 transition-opacity opacity-0 hidden w-full",
                                    `sub-menu-group/${index}`,
                                    "text-black"
                                  )}
                                  style={{
                                    borderColor: menuItem?.color,
                                    borderRightWidth: "9px",
                                    borderLeftWidth: "0px",
                                  }}
                                >
                                  {menuItem?.subMenus?.map(
                                    (subItem: any, index: number) => (
                                      <Link
                                        key={index}
                                        className={cn(
                                          "border-greyBackgroundDim/35 w-full px-1.5 text-black hover:text-primary ltr:text-left",
                                          "border-b pb-2",
                                          index === 0 && "border-t pt-2",
                                          menuItem?.title &&
                                            `group/${menuItem?.title}`
                                        )}
                                        href={
                                          decodeURIComponent(subItem?.title) ===
                                            locale.todaysRelease && dailyPdfURL
                                            ? dailyPdfURL
                                            : subItem?.url || ""
                                        }
                                        aria-label={subItem?.title}
                                      >
                                        {subItem?.title}
                                      </Link>
                                    )
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                      ))
                  )}
                </div>
              </nav>

              <img
                src="/images/samaha.png"
                alt="samaha"
                className={cn(
                  "brightness-100 h-[70px] select-none miniDeskMin:max-h-[52px] miniDeskMax:max-h-[unset]",
                  pulseConfig.LANGUAGE === "ar" && "transform -scale-x-100"
                )}
                style={{
                  color: "transparent",
                  filter: "contrast(1.2)",
                  // transform: "scaleX(-1)",
                }}
                width={60}
                height={80}
              />
            </div>
          </div>

          {/* Border */}
          <div className="w-full bg-gray-300 my-4 -mt-8 h-[2px] lg:mt-0"></div>
        </div>
      </header>

      {/* Search Form & Clock  */}
      <div
        className={cn(
          "container flex justify-between w-full pb-6",
          (isNewsPage || isNewsPaperDetails) && "pb-1"
        )}
      >
        {isHomePage || isNewsPage || isNewsPaperDetails ? (
          <div
            className={cn(
              "flex flex-col space-y-1.5 h-full my-auto",
              (isNewsPage || isNewsPaperDetails) && "my-auto space-y-0"
            )}
          >
            <label
              className={cn(
                "text-2xl  leading-5",
                isNewsPage || isNewsPaperDetails
                  ? "md:text-4xl"
                  : "md:text-[28px]"
              )}
            >
              {/* {isHomePage ? "عناوين رئيسية" : ""} */}
              {/* {isNewsPage || isNewsPaperDetails ? "جريدة يوم " : ""} */}
            </label>

            {!(isNewsPage || isNewsPaperDetails) ? (
              <label className="text-masterRed text-2xl mt-auto leading-4">
                {formattedDate}
              </label>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div
          className={cn(
            "mr-auto bg-greyBackgroundDim max-w-[250px] w-full h-12 z-[1] transition-all"
          )}
        >
          <form
            className="flex items-center h-full w-full p-2"
            onSubmit={handleSubmit}
          >
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-white sr-only hidden"
            >
              {locale.search}
            </label>
            <div
              className={cn(
                "relative flex items-center",
                pulseConfig.LANGUAGE !== "ar" && "flex-row-reverse"
              )}
            >
              <div
                onClick={() => handleSubmit()}
                className="flex items-center cursor-pointer z-10"
              >
                <SearchIcon size={24} className="fill-black" />
              </div>

              <input
                type="search"
                id="default-search"
                ref={searchInputRef}
                className="rtl:mr-3 ltr:ml-3 m-auto block w-full text-center py-1 bg-white ps-12 text-greyText placeholder-greyText bg-transparent focus:ring-white/0 focus:border-none outline-0"
                placeholder={locale.searchPlaceHolder}
                required
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DeskMenu;
