"use client";
import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { extractSection } from "@/utils/extractSection";
import { usePathname } from "next/navigation";
import pulseConfig from "@/pulsex.config";
import { cn } from "@pulsex/utils";

export const ThreeCardsGridWidget = ({
  data,
  isMobile,
  hideCategory,
}: WidgetProps) => {
  const pathname = usePathname();
  const colorTheme = extractSection(pathname);
  if (!data?.posts?.length) return;
  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} colorTheme={colorTheme || ""} />

      <div className="max-md:flex-col max-md:space-y-2 flex">
        <Card
          widgetTitle={data?.title}
          widgetColor={data?.widgetColor || colorTheme}
          className={"md:w-1/2"}
          data={data.posts[0]}
          resolution={3}
          cardOrientation="v"
          isMobile={isMobile}
          cropSize="wide"
          showSummary={false}
          showCategory={hideCategory}
        />
        <div
          className="flex md:w-1/2 md:mr-4"
          dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
        >
          {data.posts.slice(1, 3).map((post: any, index: number) => (
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor || colorTheme}
              key={index}
              className={cn(index>0 && "mr-2 md:mr-4","w-1/2")}
              data={post}
              resolution={1.5}
              cardOrientation="v"
              cropSize="wide"
              isMobile={isMobile}
              customSummary="line-clamp-4"
              showCategory={hideCategory}
            />
          ))}
        </div>
      </div>

      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
