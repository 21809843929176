"use client";
import { TitleSection } from "@/ui/Title/Index";
import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { cn } from "@pulsex/utils";
import { InsideBanner } from "@/components/InsideBanner/Index";
import { extractSection } from "@/utils/extractSection";
import { usePathname } from "next/navigation";
import pulseConfig from "@/pulsex.config";

export const ThreeCardsGridTwoTextAsideWidget = ({
  data,
  isMobile,
  hideCategory,
}: WidgetProps) => {
  const pathname = usePathname();
  const colorTheme = extractSection(pathname);
  if (!data?.posts?.length) return;
  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} colorTheme={colorTheme || ""} />

      <div className="flex flex-col space-y-4 max-md:space-y-2 w-full">
        <div className="max-md:flex-col max-md:space-y-2 flex md:space-x-3 lg:space-x-4   rtl:md:!space-x-reverse ltr:md:flex-row-reverse    ">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={"md:w-1/2 max-md:flex-row"}
            data={data.posts[0]}
            cardOrientation="h"
            isMobile={isMobile}
            cropSize="square"
            customSummary="line-clamp-5"
            showCategory={hideCategory}
          />
          <div
            className="flex-col md:space-y-4 flex md:w-1/2 max-md:grid grid-cols-2 max-md:gap-4"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            {data.posts.slice(1, 3).map((post: any, index: number) => (
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor || colorTheme}
                key={index}
                className={""}
                data={post}
                cardOrientation="h"
                isMobile={isMobile}
                customSummary="line-clamp-1"
                customTitle={
                  post?.summary || post?.textContent
                    ? "line-clamp-1"
                    : "line-clamp-2 leading-7"
                }
                showImage={false}
                showCategory={hideCategory}
              />
            ))}
          </div>
        </div>
        <div className="max-md:flex-col max-md:space-y-2 flex md:space-x-3 lg:space-x-4   rtl:md:!space-x-reverse ltr:md:flex-row-reverse   ">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={"md:w-1/2 max-md:flex-row"}
            data={data.posts[3]}
            resolution={3}
            cardOrientation="h"
            isMobile={isMobile}
            cropSize="square"
            customSummary="line-clamp-5"
            showCategory={hideCategory}
          />
          <div className="max-md:flex-col max-md:space-y-2 flex md:space-x-3 lg:space-x-4   rtl:md:!space-x-reverse ltr:md:flex-row-reverse    md:w-1/2">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor || colorTheme}
              className={"md:w-1/2"}
              data={data.posts[4]}
              resolution={3}
              cardOrientation="v"
              cropSize="wide"
              isMobile={isMobile}
              showSummary={false}
              showAuthor={true}
              showCategory={hideCategory}
            />
            <InsideBanner
              isMobile={isMobile}
              type="tall"
              className="md:w-1/2"
            />
          </div>
        </div>
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
