"use client";
import { useState, useEffect } from "react";
export const breakpoints = {
    "2xs": 200, // Max-width for small screens
    xs: 300, // Max-width for small screens
    sm: 576, // Max-width for small screens
    md: 768, // Max-width for medium screens
    lg: 992, // Max-width for large screens
    xl: 1200, // Max-width for extra-large screens
    "2xl": 1400, // Max-width for 2x extra-large screens
};
const getScreenSize = (width) => {
    if (width <= breakpoints["2xs"])
        return "2xs";
    if (width <= breakpoints["xs"])
        return "xs";
    if (width <= breakpoints["sm"])
        return "sm";
    if (width <= breakpoints["md"])
        return "md";
    if (width <= breakpoints["lg"])
        return "lg";
    if (width <= breakpoints["xl"])
        return "xl";
    if (width <= breakpoints["2xl"])
        return "2xl";
    return "2xl";
};
const useResponsive = () => {
    const [screenSize, setScreenSize] = useState(() => {
        if (typeof window !== "undefined")
            return getScreenSize(window.innerWidth);
        return "2xl";
    });
    useEffect(() => {
        if (typeof window === "undefined")
            return;
        const handleResize = () => {
            if (typeof window !== "undefined")
                setScreenSize(getScreenSize(window.innerWidth));
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const isSmallerThan = (size) => {
        const currentSize = breakpoints[screenSize];
        const targetSize = breakpoints[size];
        return currentSize <= targetSize;
    };
    return { size: screenSize, isSmallerThan };
};
export default useResponsive;
