import { WidgetProps } from "@/types/types";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import SwipperWrapper from "@/components/SwiperWrapper/Index";
import { useState, useRef } from "react";
import { SwiperSlide } from "swiper/react";
import Link from "next/link";
import { cn } from "@pulsex/utils";
import ImageUI from "@/ui/Image/Index";
import { ArrowRightIcon } from "@/icons/ArrowRightIcon";

export const WritersHomeWidget = ({ data, isMobile }: WidgetProps) => {
  const [swiperInstance, setSwiperInstance] = useState<any>();
  const [carouselEnd, setCarouselEnd] = useState(false);
  const [carouselStart, setCarouselStart] = useState(true);
  const paginationRef = useRef(null);
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} forceTitleBlack={true} />

      {/* //grid max-md:grid-cols-2 grid-cols-4 gap-4 */}
      <div className="max-w-full w-full overflow-hidden relative  py-6 lg:py-8">
        <div
          className={cn(
            "z-[3] cursor-pointer p-1.5 select-none",
            "absolute top-1/2 -translate-y-1/2 rtl:right-0 ltr:left-0"
          )}
          onClick={() => swiperInstance.slidePrev()}
        >
          <ArrowRightIcon
            className={cn(
              "cursor-pointer ltr:rotate-180",
              carouselStart
                ? "fill-gray-500"
                : "fill-minorTheme hover:fill-minorTheme/85"
            )}
            size={25}
          />
        </div>
        <div
          className={cn(
            "z-[3] cursor-pointer p-1.5 select-none",
            " disabled:bg-slate-400 absolute top-1/2 -translate-y-1/2 rtl:left-0 ltr:right-0"
          )}
          onClick={() => swiperInstance.slideNext()}
        >
          <ArrowRightIcon
            className={cn(
              "cursor-pointer rtl:rotate-180",
              carouselEnd
                ? "fill-gray-500"
                : "fill-minorTheme hover:fill-minorTheme/85"
            )}
            size={25}
          />
        </div>
        <div className="mt-1 w-full max-w-full aside-gradients">
          <SwipperWrapper
            paginationRef={paginationRef}
            swiperInstance={swiperInstance}
            setSwiperInstance={setSwiperInstance}
            setCarouselEnd={setCarouselEnd}
            setCarouselStart={setCarouselStart}
            centeredSlides={true}
            effect="coverflow"
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 130,
              modifier: 1.2,
              slideShadows: false,
            }}
            loop={true}
            breakpoints={{
              768: {
                slidesPerView: 4,
              },
            }}
          >
            {data?.posts?.map((item: any, index: any) => (
              <SwiperSlide key={index} className="select-none">
                <div className=" flex items-center justify-center flex-col space-y-2">
                  <Link
                    href={item?.url || " "}
                    aria-label={item?.title}
                    className={cn(
                      "overflow-hidden box-content h-[140px] w-[140px] rounded-full border-[3px] border-minorTheme/80"
                    )}
                  >
                    <ImageUI
                      data={item}
                      cropSize={"square"}
                      className="rounded-none w-full"
                      resolution={0.49}
                    />
                  </Link>

                  <Link
                    aria-label={item?.title}
                    href={item?.url || " "}
                    className={cn(
                      "cursor-pointer line-clamp-2 relative max-sm:my-3",
                      "text-minorTheme text-3xl font-amine-light leading-7"
                    )}
                  >
                    {item?.title}
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          </SwipperWrapper>
        </div>
      </div>
      <MoreWrap moreUrl={data?.moreLink} customText={"text-writersTheme"} />
    </SectionWrap>
  );
};
