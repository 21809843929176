import { IconProps } from "../types/types";


const BurgerIcon = ({ className, size }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 1.2 1.2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M0.2 0.35a0.05 0.05 0 0 1 0.05 -0.05h0.7a0.05 0.05 0 1 1 0 0.1H0.25a0.05 0.05 0 0 1 -0.05 -0.05m0 0.25a0.05 0.05 0 0 1 0.05 -0.05h0.7a0.05 0.05 0 1 1 0 0.1H0.25a0.05 0.05 0 0 1 -0.05 -0.05m0 0.25a0.05 0.05 0 0 1 0.05 -0.05h0.7a0.05 0.05 0 1 1 0 0.1H0.25a0.05 0.05 0 0 1 -0.05 -0.05" />
    </svg>
  );
};

export default BurgerIcon;
