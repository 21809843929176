import FacebookIcon from "@/icons/FacebookIcon";
import TelegramIcon from "@/icons/TelegramIcon";
import WhatsAppIcon from "@/icons/WhatsAppIcon";
import XIcon from "@/icons/XIcon";
import pulseConfig from "@/pulsex.config";
import CopyButton from "@/ui/CopyButton/Index";
import { cn } from "@pulsex/utils";
import {
  handleFacebookShare,
  handleTelegramShare,
  handleTwitterShare,
  handleWhatsAppShare,
} from "@pulsex/utils";
import React from "react";

const SocialsBox = ({
  data,
  theme,
  className,
  withCopyBtn = false,
  isMobile = false,
}: any) => {
  const size = isMobile ? 16 : 20;

  const decodedTitle = encodeURIComponent(data?.title);
  const decodedURL = encodeURIComponent(data?.url);
  return (
    <div
      className={cn(
        "flex flex-row items-center justify-center",
        className
      )}
    >
      {/* Facebook share */}
      <div
        onClick={() =>
          handleFacebookShare(
            decodedTitle,
            data?.shortUrl
              ? data?.shortUrl
              : pulseConfig.CLIENT_URL + decodedURL
          )
        }
        className={cn(
          "flex h-fit w-fit  ml-1 items-center justify-center rounded-full p-1.5 cursor-pointer rtl:ml-1",
          theme === "dark"
            ? "bg-black hover:bg-black/90"
            : "bg-white hover:bg-black/5"
        )}
      >
        <FacebookIcon
          size={size}
          className={cn(theme === "dark" ? "fill-white" : "fill-black")}
        />
      </div>

      {/* Twitter X share */}
      <div
        onClick={() =>
          handleTwitterShare(
            decodedTitle,
            data?.shortUrl
              ? data?.shortUrl
              : pulseConfig.CLIENT_URL + decodedURL
          )
        }
        className={cn(
          "flex h-fit w-fit  ml-1 items-center justify-center rounded-full p-1.5 cursor-pointer rtl:ml-1",
          theme === "dark" ? "bg-black hover:bg-black/90" : "bg-white"
        )}
      >
        <XIcon
          size={size}
          className={cn(theme === "dark" ? "fill-white" : "fill-black")}
        />
      </div>

      <div
        onClick={() =>
          handleTelegramShare(
            decodedTitle,
            data?.shortUrl
              ? data?.shortUrl
              : pulseConfig.CLIENT_URL + decodedURL
          )
        }
        className={cn(
          "flex h-fit w-fit  ml-1 items-center justify-center rounded-full p-1.5 cursor-pointer rtl:ml-1",
          theme === "dark" ? "bg-black hover:bg-black/90" : "bg-white"
        )}
      >
        <TelegramIcon
          size={size}
          className={cn(theme === "dark" ? "fill-white" : "fill-black")}
        />
      </div>
      <div
        onClick={() =>
          handleWhatsAppShare(
            decodedTitle,
            data?.shortUrl
              ? data?.shortUrl
              : pulseConfig.CLIENT_URL + decodedURL
          )
        }
        className={cn(
          "flex h-fit w-fit  ml-1 items-center justify-center rounded-full p-1.5 cursor-pointer rtl:ml-1",
          theme === "dark" ? "bg-black hover:bg-black/90" : "bg-white"
        )}
      >
        <WhatsAppIcon
          size={size}
          className={cn(theme === "dark" ? "fill-white" : "fill-black")}
        />
      </div>
      {withCopyBtn ? (
        <>
          <CopyButton size={size} shortUrl={data?.shortUrl} />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default SocialsBox;
