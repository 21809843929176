"use client";
import React from "react";
import { WidgetProps } from "@/types/types";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { OpinionCard } from "@/components/OpinionCard/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import { usePathname } from "next/navigation";
import { useLoadMore } from "@pulsex/hooks";
import pulseConfig from "@/pulsex.config";
import SpinnerIcon from "@/icons/SpinnerIcon";
import { cn } from "@pulsex/utils";
import { locale } from "@/locale";

export const OpinionGridWidget = ({ data, dailyPdf }: WidgetProps) => {
  const LoadQ = useLoadMore(pulseConfig, data?.loadMoreLink, data?.posts);
  const fetchNext = () => {
    if (LoadQ.hasNextPage && !LoadQ.isFetchingNextPage) LoadQ.fetchNextData();
    else console.log("no more data");
  };

  const pdfShow = data?.code.includes("pdfShow") ? true : false;
  const pathname = usePathname();

  const hideStaticTitle = decodeURIComponent(pathname).includes("قضايا");
  const withLoadMore = data?.mobileWidgetKey === "loadmore";

  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="pt-4 pb-14">
      <AsideTitleColumn
        data={data}
        dailyPdf={dailyPdf}
        pdf={pdfShow}
        hideStaticTitle={hideStaticTitle}
        customPdf="sticky top-36 md:mt-[40px] mt-10"
      />

      {withLoadMore ? (
        <div className="flex flex-col max-md:py-6 w-full">
          <div
            className="w-full grid grid-cols-2 md:grid-cols-4"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            {LoadQ?.data &&
              LoadQ?.data?.map((item: any, index: any) => (
                <OpinionCard
                  className={cn(
                    "pt-3 pb-6 mr-3",
                    index % 4 === 0 && "md:mr-0",
                    index >= 4 && "md:mt-3",
                    index % 2 === 0 && "max-md:mr-0",
                    index >= 2 && "max-md:mt-3"
                  )}
                  data={item}
                  key={index}
                />
              ))}
          </div>

          {LoadQ.hasNextPage && (
            <div className="py-6">
              <div
                className={cn(
                  "flex space-x-2.5 w-fit cursor-pointer mx-auto",
                  "text-lg text-black px-3 py-2",
                  "border border-black",
                  LoadQ?.isFetching
                    ? "border-transparent cursor-auto"
                    : "lg:hover:border-masterRed lg:hover:text-masterRed"
                )}
                onClick={() => fetchNext()}
              >
                {LoadQ?.isFetching ? (
                  <SpinnerIcon className={"fill-masterRed"} />
                ) : (
                  locale.showMore
                )}
              </div>
            </div>
          )}
          {!LoadQ.isLoading && !LoadQ.hasNextPage && (
            <div className={cn("flex space-x-2.5 w-fit py-8 mx-auto")}>
              <div className="text-lg text-black px-3 py-2">
                {locale.noMore}
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div
            className="w-full grid grid-cols-2 md:grid-cols-4"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            {data?.posts?.map((item: any, index: any) => (
              <OpinionCard
                className={cn(
                  "pt-3 pb-6 mr-3",
                  index % 4 === 0 && "md:mr-0",
                  index >= 4 && "md:mt-3",
                  index % 2 === 0 && "max-md:mr-0",
                  index >= 2 && "max-md:mt-3"
                )}
                data={item}
                key={index}
              />
            ))}
          </div>
          <MoreWrap moreUrl={data?.moreLink} />
        </>
      )}
    </SectionWrap>
  );
};
