import Block from "../entities/blockEntity";
import widgetModel from "./widgetModel";
export default function blockPageModel(config, rawPage) {
    return {
        title: rawPage === null || rawPage === void 0 ? void 0 : rawPage.title,
        id: rawPage === null || rawPage === void 0 ? void 0 : rawPage.id,
        seo: rawPage === null || rawPage === void 0 ? void 0 : rawPage.seo,
        blocks: getBlocks(config, rawPage === null || rawPage === void 0 ? void 0 : rawPage.widgets),
    };
}
function getBlocks(config, widgets) {
    if ((widgets === null || widgets === void 0 ? void 0 : widgets.length) === 0) {
        console.error("no widgets passed to getBlocks in pageModel");
        return [];
    }
    const rows = [];
    widgets === null || widgets === void 0 ? void 0 : widgets.forEach((widget) => {
        const group = widget.group;
        const mappedWidget = widgetModel(config, widget);
        if (!group) {
            const block = new Block(config, widget.style, widget.title, mappedWidget ? [mappedWidget] : []);
            rows.push(block);
        }
        else {
            const block = rows.find((block) => block.id === group);
            if (block) {
                block.addWidget(mappedWidget);
            }
            else {
                const block = new Block(config, group, widget.groupTitle, mappedWidget ? [mappedWidget] : []);
                rows.push(block);
            }
        }
    });
    return rows;
}
