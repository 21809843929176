import postModel from "./postModel";
export default function widgetModel(config, rawWidget) {
    var _a, _b, _c;
    if (rawWidget &&
        rawWidget.code &&
        !rawWidget.code.includes("script") &&
        !rawWidget.code.includes("amp")) {
        const posts = (_a = rawWidget === null || rawWidget === void 0 ? void 0 : rawWidget.posts) === null || _a === void 0 ? void 0 : _a.map((post) => postModel(config, post));
        return {
            title: rawWidget.title,
            code: rawWidget.code,
            style: rawWidget.style,
            location: rawWidget === null || rawWidget === void 0 ? void 0 : rawWidget.location,
            groupTitle: rawWidget === null || rawWidget === void 0 ? void 0 : rawWidget.groupTitle,
            group: rawWidget === null || rawWidget === void 0 ? void 0 : rawWidget.group,
            mainPost: posts && posts.length > 0 ? posts[0] : null,
            moreLink: rawWidget.moreLink,
            loadMoreLink: rawWidget.loadMoreLink,
            widgetImage: config.MEDIA_URL + rawWidget.widgetImage,
            posts,
            tabData: rawWidget === null || rawWidget === void 0 ? void 0 : rawWidget.tabData,
            apiAdvertisement: rawWidget === null || rawWidget === void 0 ? void 0 : rawWidget.apiAdvertisement,
            mobileWidgetKey: rawWidget === null || rawWidget === void 0 ? void 0 : rawWidget.mobileWidgetKey,
            widgetColor: (_b = rawWidget === null || rawWidget === void 0 ? void 0 : rawWidget.viewOptions) === null || _b === void 0 ? void 0 : _b.Color,
            subMenus: (_c = rawWidget === null || rawWidget === void 0 ? void 0 : rawWidget.viewOptions) === null || _c === void 0 ? void 0 : _c.SubMenus,
        };
    }
}
