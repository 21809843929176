import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/opt/buildhome/repo/apps/alakhbar/src/components/Footer/Index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Menu"] */ "/opt/buildhome/repo/apps/alakhbar/src/components/Menu/Index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageBuilder"] */ "/opt/buildhome/repo/apps/alakhbar/src/components/PageBuilder.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Clarity"] */ "/opt/buildhome/repo/apps/alakhbar/src/third-party/Clarity.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAnalytics"] */ "/opt/buildhome/repo/apps/alakhbar/src/third-party/GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleTagManagerNoScript"] */ "/opt/buildhome/repo/apps/alakhbar/src/third-party/GoogleTagManagerNoScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/apps/alakhbar/src/widgets/SearchSection/page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hydrate","useHydrate"] */ "/opt/buildhome/repo/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/opt/buildhome/repo/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider","defaultContext","useQueryClient"] */ "/opt/buildhome/repo/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/opt/buildhome/repo/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["unstable_batchedUpdates"] */ "/opt/buildhome/repo/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/opt/buildhome/repo/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/opt/buildhome/repo/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating"] */ "/opt/buildhome/repo/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/opt/buildhome/repo/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/opt/buildhome/repo/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/opt/buildhome/repo/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18.3.1/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
