"use client";
import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { extractSection } from "@/utils/extractSection";
import { usePathname } from "next/navigation";
import pulseConfig from "@/pulsex.config";
import { cn } from "@pulsex/utils";

export const FourCardsGridTwoTextAsideWidget = ({
  data,
  isMobile,
  hideCategory,
}: WidgetProps) => {
  const pathname = usePathname();
  const colorTheme = extractSection(pathname);
  if (!data?.posts?.length) return;
  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} colorTheme={colorTheme || ""} />

      <div className="flex flex-col space-y-4 max-md:space-y-2 w-full">
        <div className="max-md:flex-col max-md:space-y-2 flex">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={"md:w-1/2"}
            data={data.posts[0]}
            resolution={3}
            cropSize="wide"
            cardOrientation="v"
            isMobile={isMobile}
            customSummary="line-clamp-1"
            showCategory={hideCategory}
          />
          <div
            className="md:w-1/2 flex md:mr-4"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            {data.posts.slice(1, 3).map((post: any, index: number) => (
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor || colorTheme}
                key={index}
                className={cn("w-1/2", index > 0 && "max-md:mr-2 mr-4")}
                data={post}
                resolution={3}
                cardOrientation="v"
                isMobile={isMobile}
                customSummary="line-clamp-4"
                showCategory={hideCategory}
              />
            ))}
          </div>
        </div>
        <div className="max-md:flex-col max-md:space-y-2 flex">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={"md:w-1/2 max-md:flex-row"}
            data={data.posts[3]}
            cardOrientation="h"
            isMobile={isMobile}
            cropSize="square"
            customSummary="line-clamp-4 max-lg:line-clamp-3"
            showCategory={hideCategory}
          />
          <div className="md:w-1/2 flex  md:mr-4">
            {data.posts.slice(4, 6).map((post: any, index: number) => (
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor || colorTheme}
                key={index}
                className={cn("w-1/2", index > 0 && "max-md:mr-2 mr-4")}
                data={post}
                cardOrientation="h"
                isMobile={isMobile}
                customSummary="line-clamp-1"
                showSummary={isMobile ? false : true}
                customTitle={"line-clamp-1 md:line-clamp-2"}
                showImage={false}
                customMetaFlex={"md:pt-3 pt-4"}
                showCategory={hideCategory}
              />
            ))}
          </div>
        </div>
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
