import SectionWrap from "@/components/SectionWrap/Index";
import { UrgentWidget } from "@/components/Urgent/Index";
import { WidgetProps } from "@/types/types";
import Author from "@/ui/Author/Index";
import { Category } from "@/ui/Category/Index";
import ImageUI from "@/ui/Image/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import { HeroSlider } from "@/widgets/HeroSlider/Index";
import { cn } from "@pulsex/utils";
import Link from "next/link";

export const FeaturedHomeWidget = ({
  data,
  urgentNewsAside,
  isMobile,
}: WidgetProps) => {
  if (!data?.posts?.length) return;
  const isDailyEdition = data?.code.includes("daily") ? true : false;

  return (
    <SectionWrap className="home-section max-md:pt-3 md:pb-5 pt-5 pb-10 border-t-transparent">
      <div className="w-full flex max-lg:flex-col">
        <div
          className={cn(
            "lg:w-2/3 w-full",
            isDailyEdition ? "lg:w-2/3" : "lg:w-[70%]"
          )}
        >
          {isDailyEdition ? (
            <div className="flex w-full">
              <div className="md:w-2/3 relative">
                <div className="z-[1] absolute top-0 left-0 flex flex-col !justify-start bg-gradient-to-b bg-black/35 w-full h-fit mt-auto to-transparent p-3 text-white">
                  {data?.posts[0]?.tag?.title ? (
                    <Category
                      item={data?.posts[0]?.tag}
                      className={cn("pb-1 text-xl !text-white")}
                    />
                  ) : (
                    ""
                  )}
                  <Link
                    aria-label={data?.posts[0]?.title}
                    href={
                      data?.posts[0]?.url
                        ? `${data?.posts[0]?.url}`
                        : data?.posts[0]?.publicUrl
                        ? `${data?.posts[0]?.publicUrl}`
                        : " "
                    }
                    className={cn(
                      "cursor-pointer line-clamp-2 relative max-sm:my-3",
                      "text-3xl lg:text-4xl leading-tight text-white",
                      "rtl:pl-1.5 ltr:pr-1.5"
                    )}
                  >
                    {data?.posts[0]?.title}
                  </Link>
                </div>
                <ImageUI
                  data={data?.posts[0]}
                  cropSize="square"
                  className="rounded-none"
                />
              </div>
              <div className="md:w-1/3 bg-greyBackground flex flex-col">
                {data?.posts &&
                  data?.posts?.slice(1, 5).map((item: any, index: any) => (
                    <div
                      className="p-2 flex flex-col border-b border-black"
                      key={index}
                    >
                      {item.tag?.title || true ? (
                        <Category
                          item={item?.tag}
                          className={cn("text-[18px]")}
                        />
                      ) : (
                        ""
                      )}
                      {item?.title ? (
                        <Link
                          href={item?.url ? item?.url : ""}
                          aria-label={item?.title}
                          className={cn(
                            "cursor-pointer line-clamp-2 relative max-sm:my-3",
                            "text-[22px] leading-6 text-black"
                          )}
                        >
                          {item?.title}
                        </Link>
                      ) : (
                        ""
                      )}

                      <Author item={data?.item} className={cn("mt-2")} />
                    </div>
                  ))}
                <MoreWrap
                  moreUrl={data?.moreLink}
                  className={"mt-auto pb-2"}
                  customText={"text-greyText"}
                />
              </div>
            </div>
          ) : (
            <HeroSlider
              widgetTitle={data?.title}
              data={data}
              isMobile={isMobile}
            />
          )}
        </div>

        <div
          className={cn(
            "lg:w-1/3 w-full lg:mr-4 max-lg:mt-4 max-md:mt-3",
            !urgentNewsAside ? "bg-gray-100" : "max-lg:min-h-[350px]",
            isDailyEdition ? "lg:w-1/3" : "lg:w-[30%]"
          )}
        >
          {urgentNewsAside ? (
            <UrgentWidget
              urgentNewsAside={urgentNewsAside}
              isDailyEdition={isDailyEdition}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </SectionWrap>
  );
};
