import { IconProps } from "../types/types";

const CameraIcon = ({ className = "fill-white", size = 20 }: IconProps) => {
  return (
    <svg
      strokeWidth="0"
      viewBox="0 0 256 256"
      className={className}
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M168 136a40 40 0 1 1 -80 0 40 40 0 0 1 80 0z" />
      <path d="M32 64A32 32 0 0 0 0 96v96a32 32 0 0 0 32 32h192a32 32 0 0 0 32 -32V96a32 32 0 0 0 -32 -32h-18.752a32 32 0 0 1 -22.624 -9.376l-13.248 -13.248A32 32 0 0 0 146.752 32H109.248a32 32 0 0 0 -22.624 9.376L73.376 54.624A32 32 0 0 1 50.752 64zm8 32a8 8 0 1 1 0 -16 8 8 0 0 1 0 16zm144 40a56 56 0 1 1 -112 0 56 56 0 0 1 112 0z" />
    </svg>
  );
};

export default CameraIcon;
