export const extractDate = (date) => {
    const pattern = /(\w+)\s(\d{1,2})\s(\w+)\s(\d{4})/;
    const match = date === null || date === void 0 ? void 0 : date.match(pattern);
    if (match) {
        return {
            day: match[1],
            dayNumber: parseInt(match[2]) > 9 ? match[2] : "0" + match[2],
            month: match[3],
            year: match[4],
        };
    }
    return null;
};
export const getExactDate = (date) => {
    const dateObj = new Date(date);
    const options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(dateObj);
    const day = String(dateObj.getDate());
    const year = dateObj.getFullYear();
    return { day, month, year };
};
export const extractDateWithTime = (dateTime) => {
    const pattern = /(\d{4})-(\d{2})-(\d{2})\s(\d{2}):(\d{2})/;
    const match = dateTime === null || dateTime === void 0 ? void 0 : dateTime.match(pattern);
    if (match) {
        const hours = parseInt(match[4]);
        const minutes = parseInt(match[5]);
        return {
            year: match[1],
            month: match[2],
            day: match[3],
            time: hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59
                ? `${match[4]}:${match[5]}`
                : null,
        };
    }
    return null;
};
export const getMonthlyIncrementsUntilCurrent = (dateStr) => {
    const startDate = new Date(dateStr);
    if (isNaN(startDate.getTime())) {
        throw new Error("Invalid date string provided.");
    }
    const currentDate = new Date();
    const monthlyIncrements = [];
    let iterDate = new Date(startDate);
    while (iterDate.getFullYear() < currentDate.getFullYear() ||
        (iterDate.getFullYear() === currentDate.getFullYear() &&
            iterDate.getMonth() <= currentDate.getMonth())) {
        const formattedDate = `${iterDate.getFullYear()}-${String(iterDate.getMonth() + 1)}`;
        monthlyIncrements.push(formattedDate);
        iterDate.setMonth(iterDate.getMonth() + 1);
    }
    return monthlyIncrements;
};
export var Month;
(function (Month) {
    Month[Month["january"] = 1] = "january";
    Month[Month["february"] = 2] = "february";
    Month[Month["march"] = 3] = "march";
    Month[Month["april"] = 4] = "april";
    Month[Month["may"] = 5] = "may";
    Month[Month["june"] = 6] = "june";
    Month[Month["july"] = 7] = "july";
    Month[Month["august"] = 8] = "august";
    Month[Month["september"] = 9] = "september";
    Month[Month["october"] = 10] = "october";
    Month[Month["november"] = 11] = "november";
    Month[Month["december"] = 12] = "december";
})(Month || (Month = {}));
