"use client";
import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { extractSection } from "@/utils/extractSection";
import { usePathname } from "next/navigation";
import pulseConfig from "@/pulsex.config";
import { cn } from "@pulsex/utils";

export const FourCardsGridOneTextAsideWidget = ({
  data,
  isMobile,
  hideCategory,
}: WidgetProps) => {
  const pathname = usePathname();
  const colorTheme = extractSection(pathname);
  if (!data?.posts?.length) return;
  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} colorTheme={colorTheme || ""} />

      <div className="flex max-md:flex-col max-md:space-y-2 md:space-x-4  rtl:!space-x-reverse ltr:md:flex-row-reverse w-full">
        <div className="flex-col space-y-4 max-md:space-y-2 flex md:w-1/2">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={""}
            data={data.posts[0]}
            cardOrientation="v"
            isMobile={isMobile}
            cropSize="wide"
            customSummary="line-clamp-1 md:hidden lg:block lg:line-clamp-1"
            showSummary={true}
            customTitle={
              data.posts[0]?.summary ? "line-clamp-1" : "line-clamp-2"
            }
            showCategory={hideCategory}
            customMetaFlex={"md:pt-3 lg:pt-4"}
          />
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={""}
            data={data.posts[1]}
            cardOrientation="h"
            isMobile={isMobile}
            showImage={false}
            customSummary="line-clamp-1"
            customTitle={
              data.posts[1]?.summary || data.posts[1]?.textContent
                ? "line-clamp-1"
                : "line-clamp-2"
            }
            showCategory={hideCategory}
            customMetaFlex={"md:pt-3 lg:pt-3.5 pt-3.5"}
          />
        </div>
        <div className="flex-col space-y-4 max-md:space-y-2 flex md:w-1/2">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={" max-md:flex-row"}
            data={data.posts[2]}
            cardOrientation="h"
            isMobile={isMobile}
            cropSize="square"
            customSummary="md:line-clamp-3 line-clamp-4"
            customImage="lg:min-w-[51%] lg:max-w-[51%]"
            showCategory={hideCategory}
          />

          <div
            className="flex w-full"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            {data.posts.slice(3, 5).map((post: any, index: number) => (
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor || colorTheme}
                key={index}
                className={cn(index>0 && "mr-2 md:mr-4","w-1/2")}
                data={post}
                cardOrientation="v"
                isMobile={isMobile}
                showSummary={false}
                showCategory={hideCategory}
              />
            ))}
          </div>
        </div>
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
