import { cn } from "@pulsex/utils";

export default function HeroSkeleton({ className }: any) {
  return (
    <div
      className={cn(
        "w-full max-md:mt-3 border-t-2 border-t-black max-md:pt-3 pt-5 pb-8",
        className
      )}
    >
      {/* image */}
      <div className="h-[70vh] lg:h-[529.25px] bg-neutral-300/40" />
    </div>
  );
}