import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { InsideBanner } from "@/components/InsideBanner/Index";
import {
  MEDIUM_CARD_RESOLUTION_FACTOR,
  SMALL_CARD_RESOLUTION_FACTOR,
} from "@/constants";
import pulseConfig from "@/pulsex.config";
import { getViewport } from "@/utils/getViewPort";
import { useState, useEffect } from "react";
import cn from "@pulsex/utils/dist/lib/cn";

export const WorldHomeWidget = ({
  data,
  isMobile,
  insideBanners,
}: WidgetProps) => {
  const { viewWidth } = getViewport();
  const [deskWidth, setDeskWidth] = useState(0);

  useEffect(() => {
    if (viewWidth && viewWidth > 1520) {
      setDeskWidth(650);
    }
  }, [viewWidth]);
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} />

      <div className="flex flex-col w-full">
        <div className="max-md:flex-col flex">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor}
            className={"md:w-1/2"}
            data={data.posts[0]}
            resolution={deskWidth > 0 ? 2.2 : MEDIUM_CARD_RESOLUTION_FACTOR}
            cropSize="wide"
            cardOrientation="v"
            isMobile={isMobile}
            showSummary={false}
          />

          <div
            className="flex md:w-1/2 md:mr-4 max-md:mt-2"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            {data.posts.slice(1, 3).map((post: any, index: number) => (
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor}
                key={index}
                className={cn("w-1/2", index > 0 && "mr-2 md:mr-4")}
                data={post}
                resolution={SMALL_CARD_RESOLUTION_FACTOR}
                cropSize="wide"
                cardOrientation="v"
                isMobile={isMobile}
                customSummary="line-clamp-3 lg:line-clamp-5"
                customTitle="line-clamp-2 lg:line-clamp-3"
              />
            ))}
          </div>
        </div>
        {
          data.posts?.[3] ?
        
        <div className="max-md:flex-col flex  max-md:mt-2 md:mt-4">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor}
            className={"md:w-1/2"}
            data={data.posts[3]}
            cardOrientation="h"
            isMobile={isMobile}
            showSummary={false}
            showImage={false}
          />
          <div className="max-md:flex-col flex md:mr-4">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              className={"md:w-1/2"}
              data={data.posts[4]}
              cardOrientation="h"
              isMobile={isMobile}
              showSummary={false}
              showImage={false}
              customMetaFlex={"md:pt-3"}
            />
            {insideBanners &&
              insideBanners?.worldWidgetInsideBanners &&
              insideBanners?.worldWidgetInsideBanners.map(
                (banner: any, index: number) => (
                  <InsideBanner
                    isMobile={isMobile}
                    data={banner}
                    type="wide"
                    className="md:w-1/2"
                    key={index}
                  />
                )
              )}
          </div>
        </div>: ''}
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
