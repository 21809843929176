import { IconProps } from "../types/types";

const XIcon = ({ className, size }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 7.5 7.5"
      className={className}
    >
      <path d="M6.593 6.5 4.394 3.295l.004.003L6.38 1h-.662L4.103 2.87 2.82 1H1.083l2.053 2.993L.97 6.5h.662l1.796-2.08L4.855 6.5zm-4.035-5L5.643 6h-.525L2.03 1.5z" />
    </svg>
  );
};

export default XIcon;
