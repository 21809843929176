"use client";
import { useMenus } from "@pulsex/hooks";
import DeskMenu from "./DeskMenu";
import MobileMenu from "./MobileMenu";
import pulseConfig from "@/pulsex.config";
import MenuSkeleton from "../Skeletons/Menu/MenuSkeleton";
import { useEffect, useState } from "react";
import { useScroll } from "@/utils/getScroll";

export const Menu = ({ isMobile,isApple }: any) => {
  const { menus, status } = useMenus(pulseConfig);
  const [scrolled, setScrolling] = useState(false);
  const [isMac, setIsMac] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isApple) {
      setIsMac(false);
      setIsLoading(false)
      return;
    }

    const checkMac = () => {
      setIsLoading(true)
      try {
        const width = window.innerWidth;
        setIsMac(width >= 1280);
        setIsLoading(false)
      } catch (error) {
        console.error("Error detecting Mac device:", error);
        setIsLoading(false)
      }
    };

    checkMac();
  }, [isApple]);


  const { scrollDirection } = useScroll();
  const handleScroll = () => {
    const isScrolled = window.scrollY > 180;
    if (isScrolled !== scrolled) {
      setScrolling(isScrolled);
    }

    if (window.scrollY === 0) {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {status === "success" && !isLoading ? (
        <>
          {isMobile && !isMac ? (
            <MobileMenu menus={menus!} status={status} />
          ) : (
            <>
              <DeskMenu
                menus={menus!}
                status={status}
                scrolled={scrolled}
                scrollDirection={scrollDirection}
              />
            </>
          )}
        </>
      ) : (
        <MenuSkeleton isMobile={isMobile} />
      )}
    </>
  );
};
