"use client";
import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { extractSection } from "@/utils/extractSection";
import { usePathname } from "next/navigation";
import pulseConfig from "@/pulsex.config";
import { cn } from "@pulsex/utils";

export const SixCardsGridWidget = ({
  data,
  isMobile,
  hideCategory,
}: WidgetProps) => {
  const pathname = usePathname();
  const colorTheme = extractSection(pathname);
  if (!data?.posts?.length) return;
  const isHorizontal = data?.code.includes("horizontal") ? true : false;

  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} colorTheme={colorTheme || ""} />

      <div className="flex flex-col w-full">
        <div className="max-md:flex-col  flex">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={"md:w-1/2"}
            data={data.posts[0]}
            resolution={3}
            cropSize="wide"
            cardOrientation="v"
            isMobile={isMobile}
            customSummary="line-clamp-1"
            customTitle="line-clamp-1"
            showCategory={hideCategory}
          />
          <div
            className="flex md:w-1/2 max-md:mt-2 md:mr-4"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            {data.posts.slice(1, 3).map((post: any, index: number) => (
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor || colorTheme}
                key={index}
                className={cn(index > 0 && "md:mr-4 max-md:mr-2","w-1/2" )}
                data={post}
                resolution={3}
                cardOrientation="v"
                isMobile={isMobile}
                customSummary="line-clamp-3"
                showCategory={hideCategory}
              />
            ))}
          </div>
        </div>
        {!isHorizontal ? (
          <div className="max-md:flex-col flex md:mt-4 max-md:mt-2">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor || colorTheme}
              className={"md:w-1/2"}
              data={data.posts[3]}
              cropSize="wide"
              cardOrientation="v"
              isMobile={isMobile}
              customSummary="line-clamp-1"
              showCategory={hideCategory}
            />
            <div
              className="flex max-md:mt-2 md:w-1/2 md:mr-4"
              dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
            >
              {data.posts.slice(4, 6).map((post: any, index: number) => (
                <Card
                  widgetTitle={data?.title}
                  widgetColor={data?.widgetColor || colorTheme}
                  key={index}
                  className={cn(index > 0 && "md:mr-4 max-md:mr-2","w-1/2" )}
                  data={post}
                  cardOrientation="v"
                  isMobile={isMobile}
                  customSummary="line-clamp-3"
                  showCategory={hideCategory}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="max-md:flex-col flex md:mt-4 max-md:mt-2">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor || colorTheme}
              className={"md:w-1/2 max-md:flex-row"}
              data={data.posts[3]}
              cardOrientation="h"
              isMobile={isMobile}
              cropSize="square"
              customSummary="line-clamp-5 lg:line-clamp-5 md:line-clamp-3"
              customImage="lg:min-w-[57.66%] lg:max-w-[57.66%]"
              showCategory={hideCategory}
            />
            <div
              className="flex md:w-1/2 max-md:mt-2 md:mr-4"
              dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
            >
              {data.posts.slice(4, 6).map((post: any, index: number) => (
                <Card
                  widgetTitle={data?.title}
                  widgetColor={data?.widgetColor || colorTheme}
                  key={index}
                  className={cn(index > 0 && "md:mr-4 max-md:mr-2","w-1/2" )}
                  data={post}
                  cardOrientation="v"
                  isMobile={isMobile}
                  showSummary={false}
                  customTitle="md:line-clamp-1 lg:line-clamp-2"
                  customMetaFlex={"pt-3.5"}
                  showCategory={hideCategory}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
