"use client";
import React from 'react';

export const GoogleTagManagerNoScript = ({ tagManagerId }: { tagManagerId: string }) => {
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${tagManagerId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
        aria-hidden="true"
      />
    </noscript>
  );
};