"use client";
import Script from "next/script";

export const GoogleAnalytics = ({
  googleAnalyticsId,
}: {
  googleAnalyticsId: string;
}) => {
  return (
    <>
      <Script
        id="gtag-js"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
      />
      <Script id="gtag-init" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${googleAnalyticsId}', {
            page_path: window.location.pathname,
          });
        `}
      </Script>{" "}
    </>
  );
};
