import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { InsideBanner } from "@/components/InsideBanner/Index";
import {
  MEDIUM_CARD_RESOLUTION_FACTOR,
  SMALL_CARD_RESOLUTION_FACTOR,
} from "@/constants";
import pulseConfig from "@/pulsex.config";
import { useEffect, useState } from "react";
import { getViewport } from "@/utils/getViewPort";
import cn from "@pulsex/utils/dist/lib/cn";

export const SportHomeWidget = ({
  data,
  isMobile,
  insideBanners,
}: WidgetProps) => {
  const { viewWidth } = getViewport();
  const [deskWidth, setDeskWidth] = useState(0);

  useEffect(() => {
    if (viewWidth && viewWidth > 1520) {
      setDeskWidth(650);
    }
  }, [viewWidth]);
  if (!data?.posts?.length) return;
  const isCulture = data?.code.includes("culture") ? true : false;

  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} />
      <div className="w-full flex flex-col">
        <div className="w-full max-md:flex-col flex">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor}
            className={"md:w-1/2"}
            data={data.posts[0]}
            resolution={deskWidth > 0 ? 2.2 : MEDIUM_CARD_RESOLUTION_FACTOR}
            cropSize="wide"
            cardOrientation="v"
            isMobile={isMobile}
            customSummary="line-clamp-1"
          />

          <div
            className="flex md:w-1/2 md:mr-4 max-md:mt-2"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            {data.posts.slice(1, 3).map((post: any, index: number) => (
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor}
                key={index}
                className={cn("w-1/2", index > 0 && "mr-2 md:mr-4")}
                data={post}
                resolution={SMALL_CARD_RESOLUTION_FACTOR}
                cropSize="wide"
                cardOrientation="v"
                isMobile={isMobile}
                customSummary="line-clamp-3"
              />
            ))}
          </div>
        </div>
        {isCulture ? (
          <div className="w-full max-md:flex-col flex md:mt-4 max-md:mt-2">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              className={"md:w-1/2 max-md:flex-row"}
              data={data.posts[3]}
              cropSize="square"
              resolution={1}
              cardOrientation="h"
              isMobile={isMobile}
              customSummary="line-clamp-4 max-lg:line-clamp-3"
              customImage="md:min-w-[55%] md:max-w-[55%]"
            />

            <div className="md:w-1/2 max-md:flex-col flex md:mr-4 max-md:mt-2">
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor}
                className={"md:w-1/2"}
                data={data.posts[4]}
                resolution={isMobile ? 1.3 : SMALL_CARD_RESOLUTION_FACTOR}
                cropSize="wide"
                cardOrientation="v"
                customTitle="md:line-clamp-1 lg:line-clamp-2"
                isMobile={isMobile}
                showSummary={false}
              />
              {data.posts.length > 4 ? (
                <div className="flex flex-col md:w-1/2 md:mr-4 max-md:mt-2">
                  {insideBanners &&
                    insideBanners?.cultureWidgetInsideBanners &&
                    insideBanners?.cultureWidgetInsideBanners.map(
                      (banner: any, index: number) => (
                        <InsideBanner
                          isMobile={isMobile}
                          data={banner}
                          type="medium"
                          key={index}
                          className={cn(index > 0 && "mt-2 md:mt-4")}
                        />
                      )
                    )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {!isCulture ? (
          <div
            className="flex mt-4 max-md:mt-2 max-md:flex-col"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            <div className="flex md:w-1/2">
              {data.posts.slice(3, 5).map((post: any, index: number) => (
                <Card
                  widgetColor={data?.widgetColor}
                  key={index}
                  className={cn("w-1/2", index > 0 && "md:mr-4 max-md:mr-2")}
                  data={post}
                  cropSize="wide"
                  cardOrientation="v"
                  customTitle="line-clamp-2"
                  isMobile={isMobile}
                  resolution={SMALL_CARD_RESOLUTION_FACTOR}
                  showSummary={false}
                />
              ))}
            </div>
            <div className="flex md:w-1/2 max-md:mt-2 md:mr-4">
              <Card
                widgetColor={data?.widgetColor}
                className={cn("w-1/2 ")}
                data={data?.posts[5]}
                cropSize="wide"
                cardOrientation="v"
                customTitle="line-clamp-2"
                isMobile={isMobile}
                resolution={SMALL_CARD_RESOLUTION_FACTOR}
                showSummary={false}
              />
              {data.posts.length > 4
                ? insideBanners &&
                  insideBanners?.sportsWidgetInsideBanners &&
                  insideBanners?.sportsWidgetInsideBanners.map(
                    (banner: any, index: number) => (
                      <InsideBanner
                        isMobile={isMobile}
                        data={banner}
                        type="tall"
                        key={index}
                        className="w-1/2 md:mr-4 max-md:mr-2"
                      />
                    )
                  )
                : ""}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
