"use client";
import { WidgetProps } from "@/types/types";
import DetailsMaincolumn from "../MainColumn/Index";
import { useEffect, useState } from "react";

const DetailsContent = ({ data }: WidgetProps) => {
  const [scriptsLoaded, setScriptsLoaded] = useState({
    twitter: false,
    instagram: false,
    threads: false,
  });

  const cleanContent = data?.mainPost?.content || "";
  const isVideo = data?.mainPost?.postType === "video";

  const loadScript = (src: string) => {
    const existingScript = document.querySelector(`script[src="${src}"]`);

    if (existingScript) {
      return;
    }

    try {
      const script = document.createElement("script");
      script.src = src;
      script.async = false;
      script.charset = "utf-8";
      document.head.appendChild(script);
    } catch (error) {
      console.error(`Error loading script: ${src}`, error);
    }
  };

  useEffect(() => {
    const loadSocialScripts = () => {
      if (cleanContent.includes("threads.net")) {
        loadScript("https://www.threads.net/embed.js");
      } else {
        loadScript("https://www.instagram.com/embed.js");
      }
      loadScript("https://platform.twitter.com/widgets.js");
      loadScript("https://www.tiktok.com/embed.js");
    };

    loadSocialScripts();
  }, [cleanContent]);

  // const allScriptsLoaded = Object.values(scriptsLoaded).every(Boolean);

  // if (!allScriptsLoaded) {
  //   return <div></div>;
  // }
  if (!data?.posts) return;

  return (
    <>
      <div className="mt-6 lg:mt-4 relative mb-5 rtl:mr-4 rtl:max-lg:mr-0 ltr:ml-4 ltr:max-lg:ml-0 max-lg:mt-0  items-stretch justify-start lg:space-x-6  rtl:!space-x-reverse   lg:flex ltr:lg:flex-row-reverse">
        <div className="lg:w-[250px] hidden lg:block"></div>
        {data?.mainPost?.content ? (
          <div className="flex flex-col space-y-5 pb-10 flex-1">
            {!isVideo ? <DetailsMaincolumn data={data?.mainPost} /> : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default DetailsContent;
