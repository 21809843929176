import { WidgetProps } from "@/types/types";
import { Hero } from "@/components/Hero/Index";
import { useState } from "react";
import { ArrowRightIcon } from "@/icons/ArrowRightIcon";
import ImageUI from "@/ui/Image/Index";
import Link from "next/link";
import { getCropImage } from "@pulsex/utils";
import pulseConfig from "@/pulsex.config";

export const MediaSlider = ({ data, isMobile, crop }: WidgetProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleNext = () => {
    setCurrentIndex((prevIndex: number) =>
      prevIndex === gallery.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex: number) =>
      prevIndex === 0 ? gallery?.length - 1 : prevIndex - 1
    );
  };

  if (!data) return;
  const gallery =
    data?.mainPost?.medias?.filter(
      (media: any) => media?.placement === "gallery"
    ) ||
    data?.medias?.filter((media: any) => media?.placement === "gallery") ||
    [];

  return (
    <>
      {gallery.length > 0 ? (
        gallery?.length > 1 ? (
          <div className="relative pt-[56.25%] w-full overflow-hidden bg-greyBackground">
            {gallery.map(
              (item: any, index: number) =>
                currentIndex === index && (
                  <div key={index}>
                    {handleNext && (
                      <div
                        onClick={handleNext}
                        className="select-none absolute group/right max-md:right-0 right-2 top-1/2 -translate-y-1/2 z-10 p-2"
                      >
                        <ArrowRightIcon
                          size={isMobile ? 40 : 50}
                          className="fill-white cursor-pointer group-hover/right:fill-masterRed drop-shadow-md"
                        />
                      </div>
                    )}

                    {handlePrevious && (
                      <div
                        onClick={handlePrevious}
                        className="select-none absolute group/left max-md:left-0 left-2 top-1/2 -translate-y-1/2 z-10 p-2"
                      >
                        <ArrowRightIcon
                          size={isMobile ? 40 : 50}
                          className="rotate-180 fill-white cursor-pointer group-hover/left:fill-masterRed drop-shadow-md"
                        />
                      </div>
                    )}

                    <div className="overflow-hidden z-[9] select-none absolute top-0 left-0 w-full h-full">
                      <img
                        src={
                          crop
                            ? getCropImage(pulseConfig, item?.url, "wide") +
                              "?width=1000"
                            : item?.url + "?width=850"
                        }
                        className="max-w-full w-full h-full object-cover"
                        alt={"image" + index}
                      />
                    </div>
                  </div>
                )
            )}
          </div>
        ) : (
          <div className="relative w-full h-fit overflow-hidden">
            <div className="overflow-hidden z-[9]">
              <img src={gallery[0]?.url + "?width=850"} alt={"image0"} />
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </>
  );
};
