import { convert } from "html-to-text";
//#03 Post Mapping
export default function postModel(config, rawPost) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (!rawPost)
        return;
    const MEDIA_URL = config["MEDIA_URL"];
    const CLIENT_URL = config["CLIENT_URL"];
    const textContent = rawPost.content
        ? convert(rawPost.content, {
            wordwrap: false,
            whitespaceCharacters: " ",
            selectors: [
                { selector: "p", format: "inline" },
                { selector: "*", format: "skip" },
            ],
        })
        : "";
    return {
        id: rawPost.id,
        title: rawPost.title,
        url: rawPost.publicUrl,
        content: rawPost.content,
        textContent: textContent,
        summary: rawPost.summary,
        country: rawPost.country,
        shortUrl: rawPost.url,
        postType: rawPost === null || rawPost === void 0 ? void 0 : rawPost.postType,
        medias: ((_a = rawPost.medias) === null || _a === void 0 ? void 0 : _a.map((media) => {
            var _a;
            const provider = media === null || media === void 0 ? void 0 : media.provider;
            const type = media === null || media === void 0 ? void 0 : media.type;
            const isPDF = (media === null || media === void 0 ? void 0 : media.url) && ((_a = media === null || media === void 0 ? void 0 : media.url) === null || _a === void 0 ? void 0 : _a.endsWith(".pdf"));
            return {
                type: media.type,
                url: (media === null || media === void 0 ? void 0 : media.url)
                    ? provider === "mangopulse" && !isPDF
                        ? MEDIA_URL + "/" + media.url
                        : media.url
                    : "",
                provider: media === null || media === void 0 ? void 0 : media.provider,
                embedUrl: media === null || media === void 0 ? void 0 : media.embedUrl,
                placement: media === null || media === void 0 ? void 0 : media.placement,
            };
        })) || [],
        keywords: rawPost.keywords || [],
        terms: rawPost.terms || [],
        fullUrl: rawPost.publicUrl ? CLIENT_URL + rawPost.publicUrl : undefined,
        displayDate: rawPost.displayDate,
        tag: {
            title: (_b = rawPost.tag) === null || _b === void 0 ? void 0 : _b.title,
            url: (_c = rawPost.tag) === null || _c === void 0 ? void 0 : _c.url,
            color: (_d = rawPost.tag) === null || _d === void 0 ? void 0 : _d.color,
        },
        imageCaption: rawPost.imageCaption || rawPost.thumbnailCaption,
        imageSource: rawPost === null || rawPost === void 0 ? void 0 : rawPost.thumbnailSource,
        publishDate: rawPost.publishDate,
        views: rawPost.views,
        image: rawPost.thumbnail
            ? rawPost.thumbnail.trim() !== ""
                ? MEDIA_URL + "/" + rawPost.thumbnail
                : undefined
            : undefined,
        author: {
            name: (_e = rawPost.author) === null || _e === void 0 ? void 0 : _e.name,
            icon: (_f = rawPost.author) === null || _f === void 0 ? void 0 : _f.icon,
            summary: (_g = rawPost.author) === null || _g === void 0 ? void 0 : _g.summary,
            id: (_h = rawPost.author) === null || _h === void 0 ? void 0 : _h.id,
        },
        date: rawPost.date,
        metas: (_j = rawPost.metas) === null || _j === void 0 ? void 0 : _j.map((meta) => ({
            title: meta.title,
            value: meta.value,
            key: meta.key,
        })),
        relatives: (_k = rawPost.relatives) === null || _k === void 0 ? void 0 : _k.map((rel) => ({
            relationName: rel.relationName,
            direction: rel.direction,
            posts: rel.posts,
        })),
        relativesCount: rawPost.relativesCount,
        readingTimeInMinutes: rawPost.readingTimeInMinutes,
        disclaimer: rawPost.disclaimer,
        isVideo: rawPost.isVideo,
    };
}
