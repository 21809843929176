import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { Time } from "@/ui/Time/Index";
import Link from "next/link";
import { cn } from "@pulsex/utils";
import { getNewspaperDate } from "@/utils/getNewspaperDate";
import pulseConfig from "@/pulsex.config";

export const NewsPaperGridWidget = ({ data, isMobile }: WidgetProps) => {
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} />

      <div
        className="grid grid-cols-2 md:grid-cols-4 w-full"
        dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
      >
        {data.posts.map((item: any, index: number) => {
          const dateValue = getNewspaperDate(item);
          return (
            <div
              className={cn(
                "flex flex-col space-y-1 pt-3 pb-6 mr-3",
                // index % 4 === 0 && "md:mr-0",
                index >= 4 && "md:mt-3",
                // index % 2 === 0 && "max-md:mr-0",
                index >= 2 && "max-md:mt-3"
              )}
              key={index}
            >
              <Link
                href={item?.url || ""}
                className="w-full bg-gray-400 pt-[147.04%] overflow-hidden relative"
              >
                {item?.image ? (
                  <img
                    src={item?.image}
                    className={cn(
                      "max-w-full w-full h-full inset-0 absolute object-cover",
                      "border-2 border-black lg:border-t-0"
                    )}
                  />
                ) : (
                  <img
                    src={"/svgs/fallback/default-paper.svg"}
                    className={cn(
                      "max-w-full w-full h-full  inset-0 absolute",
                      "border-2 border-black lg:border-t-0"
                    )}
                  />
                )}
              </Link>
              {/* <div className="text-2xl leading-6">العدد {item?.title}</div> */}
              {item?.displayDate || dateValue ? (
                dateValue ? (
                  <div className={cn("text-[#848484] leading-6 text-sm")}>
                    {dateValue}
                  </div>
                ) : (
                  <Time
                    item={item?.displayDate}
                    className={cn("text-sm leading-6")}
                  />
                )
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>

      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
