import { locale } from "@/locale";
import { cn } from "@pulsex/utils";
import Link from "next/link";
import React from "react";

type AuthorProps = { item: any; className?: string };

const Author = ({ item, className }: AuthorProps) => {
  return item?.name ? (
    <Link
      href={item?.id ? `/${item?.id}` : " "}
      className={cn("text-sm w-fit cursor-pointer text-minorTheme", className)}
    >
      {item?.name}
    </Link>
  ) : (
    <div
      className={cn("text-sm w-fit cursor-pointer text-minorTheme", className)}
    >
      {locale.akhbar}
    </div>
  );
};

export default Author;
