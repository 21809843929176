interface Media {
  type: string;
  url: string;
  key?: string;
  value?: string;
}

interface Data {
  postType?: string;
  metas?: Media[];
}

const monthMap: { [key: string]: number } = {
  "كانون الثاني": 0,
  "كانون ثاني": 0,
  شباط: 1,
  آذار: 2,
  اذار: 2,
  أذار: 2,
  نيسان: 3,
  أيار: 4,
  ايار: 4,
  حزيران: 5,
  تموز: 6,
  آب: 7,
  اب: 7,
  أيلول: 8,
  ايلول: 8,
  "تشرين أول": 9,
  "تشرين اول": 9,
  "تشرين الاول": 9,
  "تشرين الأول": 9,
  "تشرين ثاني": 10,
  "تشرين الثاني": 10,
  "كانون اول": 11,
  "كانون أول": 11,
};

const reverseMonthMap = Object.entries(monthMap).reduce(
  (acc, [name, index]) => {
    acc[index] = name;
    return acc;
  },
  {} as { [key: number]: string }
);

export const getNewspaperDate = (
  data?: Data,
  monthDate?: boolean
): string | undefined => {
  if (!data) return undefined;

  const dateMeta = data?.metas?.find((media) => {
    const isDateKey = media?.key === "edition-date" || media?.key === "date";
    return isDateKey && media.value;
  });

  const dateValue =
    (data?.postType === "newspaper" ||
      data?.postType === "Newspaper Articles") &&
    dateMeta?.value;

  if (!dateValue) return undefined;

  const parts = dateValue.split(" ");

  // if (parts.length === 1) {
  //   if (parts[0].includes("/")) {
  //     const [day, month, year] = parts[0].split("/").map(Number);
  //     if (monthDate && month !== undefined) {
  //       return `${year} ${reverseMonthMap[month - 1]} ${day}`;
  //     }
  //     return parts[0].replace(/\//g, ".");
  //   } else if (parts[0].match(/^\d{2}:\d{2}$/)) {
  //     return "اليوم";
  //   } else {
  //     return parts[0];
  //   }
  // }

  // if (parts.length === 2) {
  //   if (parts[0] === "اليوم") {
  //     return "اليوم";
  //   } else if (parts[0].includes("/")) {
  //     const [day, month, year] = parts[0].split("/").map(Number);
  //     if (monthDate && month !== undefined) {
  //       return `${year} ${reverseMonthMap[month - 1]} ${day}`;
  //     }
  //     return parts[0].replace(/\//g, ".");
  //   }
  // }

  return dateValue;
};
