"use client";
import { getMangoTracker, postPageView } from "@pulsex/services";
import { getWidgetGrouping } from "@/utils/getWidgetGrouping";
import React, { useState, useEffect, Suspense } from "react";
import HeroSkeleton from "./Skeletons/Hero/HeroSkeletons";
import pulseConfig, { IS_LIVE } from "@/pulsex.config";
import WidgetRender from "@/components/WidgetRender";
import { usePathname } from "next/navigation";
import { useWidgetPage } from "@pulsex/hooks";
import { Widget } from "@pulsex/types";
import { IS_LOCAL } from "@/constants";
import JSONLD from "@/lib/seo/JsonLd";
import { Logo } from "@/icons/Logo";
import { locale } from "@/locale";
import useDailyPdf from "@/hooks/useDailyPdf";
interface PageBuilderProps {
  url: string;
  isBeta: string | boolean;
  isMobile: boolean;
  isApple: boolean;
  pageData?: any;
  dailyPdf?: any;
}

export const PageBuilder = ({
  url,
  isBeta,
  isMobile,
  isApple,
  pageData,
  dailyPdf,
}: PageBuilderProps) => {
  const pathname = usePathname();
  const [documentId, setDocumentId] = useState<any>();
  const [documentGuid, setDocumentGuid] = useState<any>(undefined);
  const [documentType, setDocumentType] = useState<any>(undefined);
  const [articleJsonLd, setArticleJsonLd] = useState<any>();

  const { data, isLoading } = useWidgetPage(pulseConfig, url, pageData);

  const { data: dailyPdfData, isLoading: isDailyPdfLoading } = useDailyPdf(
    pulseConfig,
    dailyPdf
  );

  const [widgetStates, setWidgetStates] = useState<any>(undefined);

  function generateUserId() {
    return `user-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  }

  const userId =
    pathname && documentId && documentType ? generateUserId() : undefined;

  useEffect(() => {
    if (!isLoading && data && !widgetStates) {
      const groupedWidgets = getWidgetGrouping(data);
      if (groupedWidgets) {
        setWidgetStates(groupedWidgets);
      }
    }
  }, [isLoading, data, widgetStates, pathname]);

  useEffect(() => {
    if (!IS_LOCAL && IS_LIVE) {
      setDocumentGuid(data?.postId ? data?.postId : data?.id);
      setDocumentId(data?.id);
      if (data?.seo?.type) setDocumentType(data?.seo?.type);
    }
    // if (data?.seo) {
    //   setArticleJsonLd(data?.seo);
    // }
  }, []);

  useEffect(() => {
    if (documentGuid && documentType && pathname) {
      postPageView(pulseConfig, userId, documentId, documentType);
    }

    if (documentId && pathname) {
      getMangoTracker(pulseConfig, documentId);
    }
  }, [documentGuid, documentType, documentId, pathname]);

  function Loading() {
    return (
      <section className="">
        <HeroSkeleton />
      </section>
    );
  }
  //|| (!widgetStates && data?.widgets?.length)

  if (isLoading || (!widgetStates && data?.widgets?.length)) {
    return <Loading />;
  }

  if (!data || !data?.widgets?.length) {
    return (
      <>
        <div className="flex items-center h-screen flex-col space-y-4 max-md:space-y-6 py-10">
          <Logo className="fill-black" size={75} />

          <div className="text-4xl font-bold max-md:text-2xl">
            صفحة غير موجودة
          </div>

          <p className="text-lg text-[#4c4c4c] flex max-md:px-4 max-md:text-center">
            عذراً، فإن الصفحة التي تبحث عنها غير موجودة أو تم حذفها.. يرجى
            التأكد من الرابط أو التوجه الى
          </p>
          <a
            href="/"
            className="text-masterRed text-xl border-none rtl:mr-1 ltr:ml-1"
          >
            {locale.HomePage}
          </a>
        </div>
      </>
    );
  }

  return (
    <Suspense fallback={<Loading />}>
      <>
        {/* <FloatingUrgentNewsBox /> */}
        {data?.widgets?.map((item: Widget, index: number) => (
          <React.Fragment key={index}>
            {WidgetRender(item, data, widgetStates, isMobile, dailyPdfData,isApple)}
          </React.Fragment>
        ))}
        {/* {articleJsonLd ? <JSONLD data={articleJsonLd} /> : ""} */}
      </>
    </Suspense>
  );
};
