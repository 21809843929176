"use client";
import React from "react";
import { WidgetProps } from "@/types/types";
import { DetailsAsideColumn } from "../../Aside/Index";
import { DetailsHeader } from "../../Header/Index";
import { MediaSlider } from "@/widgets/MediaSlider/Index";
import { VideoPlayer } from "@/components/VideoPlayer/Index";

export const DetailsMediaTemplate = ({
  data,
  isMobile,
  asideWidget,
  dailyPdf
}: WidgetProps) => {
  if (!data?.posts) return;

  const isVideo = data?.code.includes("video") ? true : false;

  return (
    <>
      <div className="gap-4 sm:flex md:flex-row lg:mt-5 mb-6">
        <DetailsAsideColumn
          data={data}
          isMobile={isMobile}
          asideWidget={asideWidget}
          dailyPdf={dailyPdf}
        />
        <div className="flex !flex-col border border-black max-md:hidden"></div>
        <div className="flex flex-col w-full ">
          <DetailsHeader data={data} isMobile={isMobile} />
          {isVideo ? (
            <VideoPlayer data={data} />
          ) : (
            <MediaSlider data={data} isMobile={isMobile} />
          )}
        </div>
      </div>
    </>
  );
};
