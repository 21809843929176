"use client";
import React, { useState } from "react";
import { WidgetProps } from "@/types/types";
import SocialsBox from "@/components/SocialsBox/Index";
import Link from "next/link";
import pulseConfig from "@/pulsex.config";
import { cn, getCleanUrl } from "@pulsex/utils";
import { Category } from "@/ui/Category/Index";
import { AuthorRelatedTextCardsWidget } from "./AuthorRelatedTextCards/Index";
import DailyPdf from "@/components/DailyPdf/Index";
import { formatArabicDate } from "@/utils/formatDate";
import { getNewspaperDate } from "@/utils/getNewspaperDate";
import { locale } from "@/locale";

export const DetailsAsideColumn = ({
  data,
  isMobile,
  asideWidget,
  dailyPdf,
}: WidgetProps) => {
  const [error, setError] = useState<any>();

  if (!data?.mainPost) return;

  const dateValue = getNewspaperDate(data?.mainPost, true);
  const isCaricature = data?.mainPost.postType === "opinion-caricature";

  return (
    <div className="md:w-[188px] lg:w-[280px]">
      <div className="flex flex-col max-md:flex-row border-none py-3 sm:block  max-md:p-0 max-md:py-2 md:block md:border-b-2 md:border-b-black ">
        {data?.mainPost?.tag?.title ? (
          <div>
            <Category
              item={data?.mainPost?.tag}
              className="text-4xl max-md:text-3xl font-amine-bold rtl:pl-2 ltr:pr-2 border-none"
            />
          </div>
        ) : isCaricature ? (
          <div className="text-4xl max-md:text-3xl font-amine-bold rtl:pl-2 ltr:pr-2 border-none mt-2 max-md:mr-3">
            كاريكاتور
          </div>
        ) : (
          ""
        )}

        {data?.mainPost?.terms && data?.mainPost?.terms[0]?.title ? (
          <div className="max-md:mt-auto max-md:h-fit">
            <Category
              item={data?.mainPost?.terms[0]}
              className="text-2xl max-md:text-xl max-md:mt-auto h-fit pr-2 !text-black border-none"
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="meta hidden border-y-2 border-b-black border-t-black rtl:md:pr-0 ltr:md:pl-0 p-6 pt-4 sm:block">
        <Link
          href={
            data?.mainPost?.author?.id ? `/${data?.mainPost?.author?.id}` : ""
          }
          className="ltr:flex ltr:justify-end"
        >
          <img
            alt={data?.mainPost?.author?.name}
            src={
              !error && data?.mainPost?.author?.icon
                ? pulseConfig.MEDIA_URL +
                  getCleanUrl(data?.mainPost?.author?.icon) +
                  `?width=${160}&height=${160}&mode=crop`
                : "/images/fallbackAuthor.png"
            }
            width={160}
            height={160}
            // onError={() => {
            //   if (!error) {
            //     setError(true);
            //   }
            // }} [#ddc34b]
            className="mb-2 h-24 w-24 rounded-full border-2 border-masterRed bg-primary-red  object-cover md:h-36 md:w-36"
          />
        </Link>
        {data?.mainPost?.author?.name ? (
          <Link
            href={
              data?.mainPost?.author?.id ? `/${data?.mainPost?.author?.id}` : ""
            }
            className="font-amine-light text-3xl"
          >
            {data?.mainPost?.author?.name}
          </Link>
        ) : (
          ""
        )}
        {!data?.mainPost?.author?.name ? (
          <p className="font-amine-light text-3xl">الأخبار</p>
        ) : (
          ""
        )}

        {data?.mainPost?.displayDate && !dateValue ? (
          <p className="text-base text-[#736357] md:text-lg lg:text-xl mt-3">
            {data?.mainPost?.displayDate}
          </p>
        ) : (
          ""
        )}
        {dateValue ? (
          <p className="text-base text-[#736357] md:text-lg lg:text-xl mt-3">
            {dateValue}
          </p>
        ) : (
          ""
        )}
      </div>
      {asideWidget ? <AuthorRelatedTextCardsWidget data={asideWidget} /> : ""}
      <DailyPdf
        template="details"
        className="max-md:hidden"
        pdfData={dailyPdf}
      />

      <div
        className={cn(
          //sticky top-[180px]
          "max-md:hidden mb-10 border-none sm:border-b-2 sm:border-b-black sm:pt-4"
          // asideWidget ? "pt-2 md:pt-0" : "sm:pt-6"
        )}
      >
        <div className="hidden sm:block"></div>
        <div
          className={cn(
            "flex w-full justify-between border-b-2 border-b-black max-md:border-t-2  max-md:border-y-[#616161] py-2",
            asideWidget ? "md:!pt-0 md:p-4" : "md:p-4"
          )}
        >
          <div className="block gap-2 sm:hidden">
            {data?.mainPost?.author?.name ? (
              <Link
                href={
                  data?.mainPost?.author?.id
                    ? `/${data?.mainPost?.author?.id}`
                    : ""
                }
                className="cursor-pointer text-lg max-md:font-amine-light md:text-2xl lg:text-3xl"
              >
                {data?.mainPost?.author?.name}
              </Link>
            ) : (
              ""
            )}
            {!data?.mainPost?.author?.name ? (
              <p className="cursor-pointer text-lg max-md:font-amine-light md:text-2xl lg:text-3xl">
                {locale.akhbar}
              </p>
            ) : (
              ""
            )}
            <p className="text-lg font-black max-md:text-base max-md:leading-4">
              {data?.mainPost?.displayDate}
            </p>
          </div>
          <div className="flex flex-col  items-center justify-start gap-2 text-start sm:block max-md:flex-row md:block md:items-start lg:block lg:items-start ">
            <p className="mb-2 max-md:mb-0 rtl:ml-auto flex font-amine-light rtl:justify-start ltr:justify-end text-start text-lg font-bold md:text-xl lg:text-2xl max-md:font-amine-light">
              {locale.sharePost}
            </p>
            <SocialsBox
              theme="dark"
              data={data?.mainPost}
              className={"max-md:mr-auto"}
              withCopyBtn={true}
              isMobile={isMobile}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
