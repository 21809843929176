export const extractPath = (url) => {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname;
};
export const getCleanUrl = (url) => {
    if (url) {
        const questionMarkIndex = url === null || url === void 0 ? void 0 : url.indexOf("?");
        if (questionMarkIndex !== -1) {
            return url.substring(0, questionMarkIndex);
        }
        return url;
    }
};
export const removePageValue = (url) => {
    const parts = url.split("?");
    if (parts.length !== 2) {
        return url;
    }
    const queryParams = parts[1].split("&");
    const filteredParams = queryParams.filter((param) => !param.startsWith("page="));
    const newUrl = parts[0] +
        (filteredParams.length > 0 ? "?" + filteredParams.join("&") : "");
    return newUrl;
};
