import { WidgetProps } from "@/types/types";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { TitleSection } from "@/ui/Title/Index";
import Link from "next/link";
import { cn } from "@pulsex/utils";
import ImageUI from "@/ui/Image/Index";
import { Card } from "@/components/Card/Index";
import pulseConfig from "@/pulsex.config";
import { useLoadMore } from "@pulsex/hooks";
import SpinnerIcon from "@/icons/SpinnerIcon";
import { locale } from "@/locale";

export const AuthorsListWidget = ({ data, isMobile }: WidgetProps) => {
  const withLoadMore = data?.mobileWidgetKey === "loadmore";

  const LoadQ = useLoadMore(pulseConfig, data?.loadMoreLink, data?.posts);
  const fetchNext = () => {
    if (LoadQ.hasNextPage && !LoadQ.isFetchingNextPage) LoadQ.fetchNextData();
    else console.log("no more data");
  };
  if (!data?.posts?.length) return;

  return (
    <>
      <div className="pb-4">
        {data?.title ? (
          <TitleSection
            title={data?.title}
            moreUrl={data?.moreLink}
            customTitle="max-lg:text-4xl max-md:mt-6"
          />
        ) : (
          ""
        )}
      </div>
      {withLoadMore ? (
        <div className="pb-5">
          {LoadQ?.data &&
            LoadQ?.data?.map((post: any, index: number) => {
              const authorPosts = post?.relatives?.filter(
                (relative: any) => relative?.relationName === "author"
              );

              // if (!authorPosts) return;

              return (
                <SectionWrap
                  className="relative lg:pt-2.5 lg:pb-2.5"
                  key={index}
                >
                  <div className="flex flex-col space-y-2 w-full">
                    <Link
                      aria-label={post?.title}
                      href={post?.url || post?.publicUrl || " "}
                      className={cn(
                        "cursor-pointer line-clamp-2 relative max-sm:my-3",
                        "text-minorTheme max-md:text-4xl max-md:mx-auto text-3xl font-amine-light leading-7"
                      )}
                    >
                      {post?.title}
                    </Link>
                    <div
                      className={cn(
                        "flex lg:items-center justify-center py-2",
                        "md:space-x-6 rtl:space-x-reverse max-md:flex-col max-md:space-y-3"
                      )}
                    >
                      <Link
                        href={post?.url || post?.publicUrl || " "}
                        aria-label={post?.title}
                        className={cn(
                          "overflow-hidden box-content  rounded-full border-[3px] border-minorTheme/80 ",
                          "max-md:mx-auto h-[180px] w-[180px] md:h-[118px] md:w-[118px]"
                        )}
                      >
                        <ImageUI
                          data={post}
                          cropSize={"square"}
                          className="rounded-none w-full"
                        />
                      </Link>

                      <div
                        className="flex-1 flex max-lg:flex-col"
                        dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
                      >
                        <div className="flex lg:mr-3 max-md:flex-col lg:w-1/2">
                          {authorPosts &&
                            authorPosts[0]?.posts &&
                            authorPosts[0]?.posts
                              ?.slice(0, 2)
                              .map((item: any, index: number) => (
                                <Card
                                  widgetColor={data?.widgetColor}
                                  key={index}
                                  className={cn(
                                    "mr-3 w-1/2 max-md:w-full max-md:mr-0",
                                    index > 0 && "max-md:mt-3",
                                    index === 0 && "lg:mr-0"
                                  )}
                                  data={item}
                                  isMobile={isMobile}
                                  showSummary={false}
                                  showImage={false}
                                  showCategory={false}
                                  customBackground="pt-3 max-md:p-1.5"
                                  customAuthor="font-amine-light text-xl"
                                  customTitle="max-md:text-2xl max-sm:text-2xl"
                                />
                              ))}
                        </div>
                        <div className="flex lg:mr-3 max-md:flex-col lg:w-1/2 max-lg:mt-3">
                          {authorPosts &&
                            authorPosts[0]?.posts &&
                            authorPosts[0]?.posts
                              ?.slice(2, 4)
                              .map((item: any, index: number) => (
                                <Card
                                  widgetColor={data?.widgetColor}
                                  key={index}
                                  className={cn(
                                    "mr-3 w-1/2 max-md:w-full max-md:mr-0",
                                    index > 0 && "max-md:mt-3",
                                    index === 0 && "lg:mr-0"
                                  )}
                                  data={item}
                                  isMobile={isMobile}
                                  showSummary={false}
                                  showImage={false}
                                  showCategory={false}
                                  customBackground="pt-3 max-md:p-1.5"
                                  customAuthor="font-amine-light text-xl"
                                  customTitle="max-md:text-2xl max-sm:text-2xl"
                                />
                              ))}
                        </div>
                      </div>
                      <div className="flex justify-center items-end py-1 h-full max-lg:mt-auto">
                        <MoreWrap
                          moreUrl={post?.url}
                          customText={"text-writersTheme max-md:text-xl"}
                          className={cn(
                            "max-md:bg-minorTheme/5 max-md:w-full max-md:self-center",
                            "max-lg:flex max-md:flex max-md:items-center max-md:justify-center",
                            "max-md:mx-3 max-md:py-2 max-md:border max-md:border-writersTheme"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </SectionWrap>
              );
            })}

          {LoadQ.hasNextPage && (
            <div className="py-6">
              <div
                className={cn(
                  "flex space-x-2.5 w-fit cursor-pointer mx-auto",
                  "text-xl text-minorTheme px-3 py-2",
                  "border border-minorTheme",
                  LoadQ?.isFetching
                    ? "border-transparent cursor-auto"
                    : "lg:hover:border-masterRed lg:hover:text-masterRed"
                )}
                onClick={() => fetchNext()}
              >
                {LoadQ?.isFetching ? (
                  <SpinnerIcon className={"fill-masterRed"} />
                ) : (
                  locale.showMore
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="pb-5">
          {data?.posts &&
            data?.posts?.map((post: any, index: number) => {
              const authorPosts = post?.relatives?.filter(
                (relative: any) => relative?.relationName === "author"
              );

              // if (!authorPosts) return;

              return (
                <SectionWrap
                  className="relative lg:pt-2.5 lg:pb-2.5"
                  key={index}
                >
                  <div className="flex flex-col space-y-2 w-full">
                    <Link
                      aria-label={post?.title}
                      href={post?.url || post?.publicUrl || " "}
                      className={cn(
                        "cursor-pointer line-clamp-2 relative max-sm:my-3",
                        "text-minorTheme max-md:text-4xl max-md:mx-auto text-3xl font-amine-light leading-7"
                      )}
                    >
                      {post?.title}
                    </Link>
                    <div
                      className={cn(
                        "flex lg:items-center justify-center py-2",
                        "max-md:flex-col max-md:space-y-3"
                      )}
                    >
                      <Link
                        href={post?.url || post?.publicUrl || " "}
                        aria-label={post?.title}
                        className={cn(
                          "overflow-hidden box-content  rounded-full border-[3px] border-minorTheme/80 ",
                          "max-md:mx-auto h-[180px] w-[180px] md:h-[118px] md:w-[118px]"
                        )}
                      >
                        <ImageUI
                          data={post}
                          cropSize={"square"}
                          className="rounded-none w-full"
                        />
                      </Link>

                      <div
                        className="flex-1 flex max-lg:flex-col"
                        dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
                      >
                        <div className="flex lg:mr-3 max-md:flex-col lg:w-1/2">
                          {authorPosts &&
                            authorPosts[0]?.posts &&
                            authorPosts[0]?.posts
                              ?.slice(0, 2)
                              .map((item: any, index: number) => (
                                <Card
                                  widgetColor={data?.widgetColor}
                                  key={index}
                                  className={cn(
                                    "mr-3 w-1/2 max-md:w-full max-md:mr-0",
                                    index > 0 && "max-md:mt-3",
                                    index === 0 && "lg:mr-0"
                                  )}
                                  data={item}
                                  isMobile={isMobile}
                                  showSummary={false}
                                  showImage={false}
                                  showCategory={false}
                                  customBackground="pt-3 max-md:p-1.5"
                                  customAuthor="font-amine-light text-xl"
                                  customTitle="max-md:text-2xl max-sm:text-2xl"
                                />
                              ))}
                        </div>
                        <div className="flex lg:mr-3 max-md:flex-col lg:w-1/2 max-lg:mt-3">
                          {authorPosts &&
                            authorPosts[0]?.posts &&
                            authorPosts[0]?.posts
                              ?.slice(2, 4)
                              .map((item: any, index: number) => (
                                <Card
                                  widgetColor={data?.widgetColor}
                                  key={index}
                                  className={cn(
                                    "mr-3 w-1/2 max-md:w-full max-md:mr-0",
                                    index > 0 && "max-md:mt-3",
                                    index === 0 && "lg:mr-0"
                                  )}
                                  data={item}
                                  isMobile={isMobile}
                                  showSummary={false}
                                  showImage={false}
                                  showCategory={false}
                                  customBackground="pt-3 max-md:p-1.5"
                                  customAuthor="font-amine-light text-xl"
                                  customTitle="max-md:text-2xl max-sm:text-2xl"
                                />
                              ))}
                        </div>
                      </div>
                      <div className="flex justify-center items-end py-1 h-full max-lg:mt-auto md:mr-4">
                        <MoreWrap
                          moreUrl={post?.url}
                          customText={"text-writersTheme max-md:text-xl"}
                          className={cn(
                            "max-md:bg-minorTheme/5 max-md:w-full max-md:self-center",
                            "max-lg:flex max-md:flex max-md:items-center max-md:justify-center",
                            "max-md:mx-3 max-md:py-2 max-md:border max-md:border-writersTheme"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </SectionWrap>
              );
            })}
        </div>
      )}
    </>
  );
};
