export default function getCropImage(config, url, cropSize) {
    //please add parameters not remove v-?
    if (url) {
        const dotIndex = url.lastIndexOf(".");
        const lastSlashIndex = url.lastIndexOf("/");
        if (lastSlashIndex !== -1 && dotIndex > lastSlashIndex + 1) {
            const number = url.substring(lastSlashIndex + 1, dotIndex);
            if (!isNaN(number) && number < 9 && number > 0) {
                url =
                    url.substring(0, lastSlashIndex) + url.substring(dotIndex);
            }
        }
        const questionMarkIndex = url.indexOf("?");
        let cleanUrl = questionMarkIndex !== -1
            ? url.substring(0, questionMarkIndex)
            : url;
        const extensionIndex = cleanUrl.lastIndexOf(".");
        if (config["cropRatios"][cropSize] && extensionIndex !== -1) {
            return (cleanUrl.slice(0, extensionIndex) +
                `/${config["cropRatios"][cropSize]}` +
                cleanUrl.slice(extensionIndex));
        }
        return cleanUrl;
    }
}
