"use client";
import React from "react";
import { WidgetProps } from "@/types/types";
import SectionWrap from "@/components/SectionWrap/Index";
import { Card } from "@/components/Card/Index";
import { TitleSection } from "@/ui/Title/Index";
import pulseConfig from "@/pulsex.config";

export const AuthorRelatedTextCardsWidget = ({ data }: WidgetProps) => {
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="!py-5 max-md:hidden flex-col border-none gap-3">
      {data?.title ? (
        <TitleSection
          title={data?.title}
          moreUrl={data?.moreLink}
          customTitle="!text-black font-amine-light !text-2xl lg:!text-3xl"
          colorTheme={data?.widgetColor || data?.mainPost?.tag?.color}
        />
      ) : (
        ""
      )}

      <div
        className="grid grid-cols-1 gap-3 w-full"
        dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
      >
        {data?.posts?.map((item: any, index: any) => (
          <Card
            widgetColor={data?.widgetColor}
            key={index}
            data={item}
            className=""
            cardOrientation="h"
            customTitle="line-clamp-2"
            showImage={false}
            showSummary={false}
          />
        ))}
      </div>
    </SectionWrap>
  );
};
