"use client";

import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import { IPulseXConfig } from "@pulsex/types";
import getDailyPdf from "@/services/getDailyPdf";
import {widgetModel} from "@pulsex/models";

export default function useDailyPdf(config: IPulseXConfig,initialData?: any) {
  const query: QueryObserverResult<any, Error> = useQuery(
    ["DAILY_PDF"],
    () => getDailyPdf(config),
    {
      cacheTime: 1000 * 60 * 30,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  const mappedPosts: any = [];

  if (query.isSuccess && query.data && query.data?.widgets) {
    query.data?.widgets.forEach((item: any) => {
      mappedPosts.push(widgetModel(config, item));
    });
  }

  return {
    isLoading: query.isLoading,
    data: mappedPosts.length > 0 ? mappedPosts[0] : undefined,
  };
}