"use client";
import React from "react";
import { WidgetProps } from "@/types/types";
import SectionWrap from "@/components/SectionWrap/Index";
import { Card } from "@/components/Card/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import { extractSection } from "@/utils/extractSection";
import { usePathname } from "next/navigation";
import pulseConfig from "@/pulsex.config";

export const FourTextCardsGridWidget = ({ data }: WidgetProps) => {
  const pathname = usePathname();
  const colorTheme = extractSection(pathname);
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="pt-4 pb-14">
      <AsideTitleColumn data={data} colorTheme={colorTheme || ""} />

      <div
        className="w-full grid grid-cols-2 md:grid-cols-4 gap-4"
        dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
      >
        {data?.posts?.map((item: any, index: any) => (
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={""}
            data={item}
            showImage={false}
            customSummary="line-clamp-4"
            key={index}
          />
        ))}
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
