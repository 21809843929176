import { formatArabicDate } from "@/utils/formatDate";
import { cn } from "@pulsex/utils";
import React from "react";

type TimeProps = {
  item?: string;
  className?: string;
};

export const Time = ({ item, className }: TimeProps) => {

  return item ? (
    <p
      className={cn("text-[#848484] text-[10px] font-semibold", className)}
      style={{ fontFamily: '"Helvetica Bold", sans-serif' }}
    >
      {/* {formatArabicDate({ arabicDate: item, needDay: false })} */}
      {item}
    </p>
  ) : (
    ""
  );
};
