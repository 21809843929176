import * as React from "react";
import { cn } from "@pulsex/utils";
import { Navigation, Pagination, A11y, EffectCoverflow } from "swiper/modules";
import { Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import pulseConfig from "@/pulsex.config";

export interface SwipperWrapperProps {
  paginationRef: React.RefObject<HTMLDivElement>;
  swiperInstance: any;
  setSwiperInstance: any;
  setCarouselEnd: any;
  setCarouselStart: any;
  children: React.ReactNode;
  breakpoints?: any;
  centeredSlides?: boolean;
  effect?: any;
  coverflowEffect?: any;
  loop?: boolean;
}

export default function SwipperWrapper({
  paginationRef,
  swiperInstance,
  setSwiperInstance,
  setCarouselEnd,
  setCarouselStart,
  children,
  breakpoints,
  effect,
  coverflowEffect,
  centeredSlides = false,
  loop = false,
}: SwipperWrapperProps) {
  return (
    <Swiper
      modules={[Navigation, EffectCoverflow, Pagination, A11y]}
      spaceBetween={8}
      dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
      pagination={{
        el: paginationRef.current,
        clickable: true,
        renderBullet: (index: number, className: any) =>
          `<button class=${className}>${index + 1}</button>`,
      }}
      onSwiper={(swiper: any) => {
        setSwiperInstance(swiper);
        setCarouselStart(swiper.isBeginning);
        setCarouselEnd(swiper.isEnd);
      }}
      slidesPerGroup={1}
      slidesPerView={2}
      breakpoints={breakpoints}
      draggable={"true"}
      centeredSlides={centeredSlides}
      effect={effect}
      coverflowEffect={coverflowEffect}
      loop={loop}
      onSlideChange={(swiper: any) => {
        setCarouselStart(swiper.isBeginning);
        setCarouselEnd(swiper.isEnd);
      }}
    >
      {children}
    </Swiper>
  );
}
