"use client";
import React from "react";
import { WidgetProps } from "@/types/types";
import SectionWrap from "@/components/SectionWrap/Index";
import { Card } from "@/components/Card/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { extractSection } from "@/utils/extractSection";
import { usePathname } from "next/navigation";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import { cn } from "@pulsex/utils";
import pulseConfig from "@/pulsex.config";

export const TwoColumnGridWidget = ({ data, hideCategory }: WidgetProps) => {
  const pathname = usePathname();
  const colorTheme = extractSection(decodeURIComponent(pathname));
  if (!data?.posts?.length) return;
  return (
    <SectionWrap className="pt-4 pb-14">
      <AsideTitleColumn data={data} colorTheme={colorTheme || ""} />

      <div
        className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full"
        dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
      >
        {data?.posts?.map((item: any, index: any) => (
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={cn(
              "border border-gray-100",
              index < 2 && "md:border-t-0"
            )}
            data={item}
            showSummary={false}
            cropSize="wide"
            cardOrientation="v"
            customBackground="bg-[#161616]"
            customAuthor="text-white"
            customTitle="text-[#c9b058]"
            customCategory="!text-white pb-3"
            showCategory={hideCategory}
            key={index}
          />
        ))}
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
