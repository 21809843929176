import { WidgetProps } from "@/types/types";

type Props = {};

const StyleWidget = ({
    data,
    asideWidget,
    title,
    asideAdWidget,
    asideVideosWidget,
}: WidgetProps) => {
    return data?.posts[0]?.content ? (
        <div
            dangerouslySetInnerHTML={{ __html: data?.posts[0]?.content }}
        ></div>
    ) : (
        ""
    );
};

export default StyleWidget;
