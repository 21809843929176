import pulseConfig from "./pulsex.config";

export const localeDic: { [key: string]: any } = {
  en: {
    homePage: "Home Page",
    more: "more",
    showMore: "Show More",
    noMore: "No More Data",
    noAuthorData: "No Posts Found",
    copyMessage: "Link is copied successfully",
    searchIndicatorMessage: "?Still Searching",
    searchSubIndicatorMessage: "Try searching here",
    pdfPages: "PDF Pages",
    todaysPdf: "Today's PDF",
    todaysRelease: "Today's Release",
    socialPages: "Social Media Pages",
    video: "video",
    search: "Search",
    searchPlaceHolder: "Enter Keyword",
    footerMainMessage:
      "The article must be attributed to 'Al-Akhbar' - Commercial use of the work is prohibited - Any modification of the text is prohibited unless stated.",
    footerSubMessage:
      "The content of the 'Al-Akhbar' website is available under the Commons 4.0 license.",
    subscribe: "Subscribe ",
    logIn: "Log In",
    akhbar: "Al Akhbar",
    searchKeyword: "Keyword",
    dateFrom: "Date from",
    dateTo: "Date To",
    chooseDate: "Choose Date",
    advancedSearch: "Advanced Search",
    edition: "Edition ",
    sharePost: "Share the Post",
  },
  ar: {
    homePage: "الصفحة الرئيسية",
    emptyPage: "الصفحة فارغة",
    emptyPageMessage:
      " عذراً، فإن الصفحة فارغة، ولا توجد أي محتويات لعرضها... يرجى التأكد من الرابط أو التوجه الى",
    more: "المزيد",
    showMore: "إظهار المزيد",
    noMore: "لا يوجد المزيد",
    noAuthorData: "لا يوجد منشورات للكاتب ",
    copyMessage: "تم نسخ الرابط بنجاح",
    searchIndicatorMessage: "لا يمكنك آن تجد ما تريد؟",
    searchSubIndicatorMessage: "جّرب البحث هنا",
    pdfPages: "صفحات الـ (PDF)",
    todaysPdf: "جريدة اليوم",
    today: "اليوم",
    pdf: "جريدة",
    todaysRelease: "عدد اليوم",
    socialPages: "صفحات التواصل الاجتماعي",
    video: "فيديو",
    search: "ابحث",
    searchPlaceHolder: "ابحث هنا",
    footerMainMessage:
      ".يتوجب نسب المقال إلى «الأخبار» - يحظر استخدام العمل لأغراض تجارية - يُحظر أي تعديل في النص، ما لم يرد تصريح غير ذلك",
    footerSubMessage: "محتوى موقع «الأخبار» متوفر تحت رخصة المشاع الإبداعي 4.0",
    subscribe: "إشترك الآن",
    logIn: "تسجيل الدخول",
    akhbar: "الأخبار",
    searchKeyword: "وسوم",
    dateFrom: "تاريخ من",
    dateTo: "تاريخ إلى ",
    chooseDate: "إختيار التاريخ",
    advancedSearch: "بحث متقّدم",
    edition: "العدد",
    sharePost: "شارك المقال",
  },
};
export const locale = localeDic[pulseConfig.LANGUAGE ?? "ar"];
