"use client";
import React from "react";
import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import pulseConfig from "@/pulsex.config";
import { cn } from "@pulsex/utils";

export const DetailsTextGrid = ({ data }: WidgetProps) => {
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="pt-4 pb-14">
      <AsideTitleColumn data={data} />

      <div
        className="grid grid-cols-1 md:grid-cols-2 w-full"
        dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
      >
        {data?.posts?.map((item: any, index: any) => (
          <Card
            widgetColor={data?.widgetColor}
            key={index}
            data={item}
            className={cn("max-md:mt-2 mt-4 mr-4 max-md:mr-0")}
            cardOrientation="h"
            customTitle="line-clamp-2"
            showImage={false}
            showSummary={false}
          />
        ))}
      </div>
    </SectionWrap>
  );
};
