import { IconProps } from "../types/types";

export const CloseIcon = ({ className, size }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 3.8 3.8"
      xmlns="http://www.w3.org/2000/svg"
      baseProfile="full"
      xmlSpace="preserve"
      className={className}
    >
      <path d="m1.346 1.108.554.555.554-.554.238.238-.554.553.554.554-.238.238-.554-.554-.554.554-.238-.238.555-.554-.555-.554z" />
    </svg>
  );
};
