"use client";
import { cn } from "@pulsex/utils";
import { Category } from "@/ui/Category/Index";
import Link from "next/link";
import Author from "@/ui/Author/Index";
import { Time } from "@/ui/Time/Index";
import ImageUI from "@/ui/Image/Index";

interface CardProps {
  className?: string;
  showSummary?: boolean;
  showImage?: boolean;
  showAuthor?: boolean;
  showCategory?: boolean;
  customBackground?: string;
  showMediaIcon?: boolean;
  customImage?: string;
  customTitle?: string;
  mediatype?: "video" | "podcast";
  data?: any;
  customCategory?: string;
  customTime?: string;
  showTime?: boolean;
  customSummary?: string;
  customAuthor?: string;
  authorTheme?: string;
  cropSize?: string;
  resolution?: number;
  isMobile?: any;
}
export const WriterCard = ({
  showSummary = true,
  showCategory = true,
  showAuthor = true,
  showImage = true,
  showMediaIcon = false,
  className,
  customBackground,
  customImage,
  customTitle,
  data,
  customCategory,
  customTime,
  showTime = false,
  customSummary,
  customAuthor,
  cropSize,
  resolution,
}: CardProps) => {
  if (!data) return;
  return (
    <div
      className={cn(
        "flex flex-col relative group overflow-hidden bg-writersTheme p-2.5",
        className
      )}
    >
      <div
        className={cn(
          "flex flex-col items-start max-sm:ml-0",
          customBackground
        )}
      >
        <div className={cn("h-full flex flex-col")}>
          {(showCategory && data?.tag?.title) || true ? (
            <Category
              item={data?.tag}
              className={cn("pb-1 !text-masterYellow")}
            />
          ) : (
            ""
          )}
          {/* TODO:  */}
          <Link
            aria-label={data?.title}
            href={
              data?.url
                ? `${data?.url}`
                : data?.publicUrl
                ? `${data?.publicUrl}`
                : " "
            }
            className={cn(
              "cursor-pointer line-clamp-2 relative max-sm:my-3",
              "text-white text-2xl max-sm:text-lg font-black leading-7",
              customTitle
            )}
          >
            {data?.title}
          </Link>

          {(showTime && data?.displayDate) || true ? (
            <Time
              item={data?.displayDate}
              className={cn("text-white max-md:block hidden", customTime)}
            />
          ) : (
            ""
          )}
          <div className="flex items-center justify-between w-full h-fit mt-auto pt-1">
            {showAuthor ? (
              <Author
                className="cursor-pointer text-2xl text-masterYellow font-amine-light"
                item={data?.author}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="text-white mt-auto flex items-end justify-between text-base font-bold text-gray-text pt-4">
        {showImage ? (
          <Link
            href={
              data?.url
                ? `${data?.url}`
                : data?.publicUrl
                ? `${data?.publicUrl}`
                : " "
            }
            aria-label={data?.title}
            className={cn(
              "overflow-hidden mb-3 h-[115px] w-[115px] rounded-full border-[3px] border-yellow-400 md:h-[120px] md:w-[120px] lg:h-[130px] lg:w-[130px]",
              customImage
            )}
          >
            <ImageUI
              data={data}
              cropSize={cropSize}
              resolution={resolution}
              className="rounded-none w-full"
            />
          </Link>
        ) : (
          " "
        )}
        {(showTime && data?.displayDate) || true ? (
          <Time
            item={data?.displayDate}
            className={cn(
              "text-white max-md:hidden ",
              !showImage && "rtl:mr-auto ltr:ml-auto",
              customTime
            )}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
