"use client";
import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { cn } from "@pulsex/utils";
import { InsideBanner } from "@/components/InsideBanner/Index";
import {
  MEDIUM_CARD_RESOLUTION_FACTOR,
  SMALL_CARD_RESOLUTION_FACTOR,
} from "@/constants";
import pulseConfig from "@/pulsex.config";
import { getViewport } from "@/utils/getViewPort";
import { useState, useEffect } from "react";

export const ArabHomeWidget = ({
  data,
  isMobile,
  insideBanners,
}: WidgetProps) => {
  const { viewWidth } = getViewport();
  const [deskWidth, setDeskWidth] = useState(0);

  useEffect(() => {
    if (viewWidth && viewWidth > 1520) {
      setDeskWidth(650);
    }
  }, [viewWidth]);
  
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} />
      <div className="flex flex-col w-full">
        <div className="max-md:flex-col flex">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor}
            className={"md:w-1/2"}
            data={data.posts[0]}
            resolution={deskWidth > 0 ? 2.2 : MEDIUM_CARD_RESOLUTION_FACTOR}
            cropSize="wide"
            cardOrientation="v"
            isMobile={isMobile}
            customSummary="line-clamp-1"
            customTitle="line-clamp-1"
          />

          <div className="flex md:w-1/2 max-md:mt-2 md:mr-4">
            {data.posts.slice(1, 3).map((post: any, index: number) => (
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor}
                key={index}
                className={cn("w-1/2", index > 0 && "mr-2 md:mr-4")}
                data={post}
                resolution={SMALL_CARD_RESOLUTION_FACTOR}
                cardOrientation="v"
                isMobile={isMobile}
                customSummary="line-clamp-3 lg:line-clamp-5"
                customTitle="line-clamp-2 lg:line-clamp-3"
              />
            ))}
          </div>
        </div>
        
        <div className="max-md:flex-col flex md:mt-4 max-md:mt-2 ">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor}
            className={"md:w-1/2 max-md:flex-row"}
            data={data.posts[3]}
            resolution={1}
            cardOrientation="h"
            isMobile={isMobile}
            cropSize="square"
            customSummary=" max-md:line-clamp-1 md:line-clamp-2 lg:line-clamp-4"
            customImage="md:min-w-[55%] md:max-w-[55%] 2xl:min-w-[48%] 2xl:max-w-[48%]"
            customTitle="md:line-clamp-2 lg:line-clamp-3"
          />
          <div className="flex flex-col md:w-1/2 max-md:mt-2 md:mr-4">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              className={""}
              data={data.posts[4]}
              cardOrientation="h"
              isMobile={isMobile}
              customSummary="lg:block md:hidden line-clamp-1 lg:line-clamp-1"
              customTitle={
                data.posts[4]?.summary || data.posts[4]?.textContent
                  ? "line-clamp-1"
                  : "line-clamp-3"
              }
              customMetaFlex={cn(
                !data.posts[4]?.summary ? "md:pt-3" : "md:pt-2.5"
              )}
              showImage={false}
            />
            <div className="max-md:flex-col flex md:mt-4 max-md:mt-2">
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor}
                className={"md:w-1/2"}
                data={data.posts[5]}
                customTitle="line-clamp-1 md:line-clamp-1 lg:line-clamp-2"
                customMetaFlex={"md:pt-3"}
                cardOrientation="h"
                isMobile={isMobile}
                showSummary={false}
                showImage={false}
              />

              {insideBanners &&
                insideBanners?.arabsWidgetInsideBanners &&
                insideBanners?.arabsWidgetInsideBanners.map(
                  (banner: any, index: number) => (
                    <InsideBanner
                      isMobile={isMobile}
                      data={banner}
                      type="medium"
                      className="md:w-1/2 max-md:mt-2 md:mr-4"
                      key={index}
                    />
                  )
                )}
            </div>
          </div>
        </div>
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
