"use client";
import { useQuery } from "@tanstack/react-query";
import { getPostDetails } from "@pulsex/services";
import { postModel } from "@pulsex/models";
export default function usePostDetails(config, postId) {
    // extract the page form url
    const query = useQuery({
        queryKey: ["GET_POST_DETAILS", postId],
        queryFn: () => getPostDetails(config, postId),
        refetchOnWindowFocus: false,
    });
    if (query.isError) {
        console.error("Query Error:", query.error);
    }
    let mappedData;
    if (query.isSuccess && query.data) {
        mappedData = postModel(config, query.data);
    }
    return {
        isLoading: query.isLoading,
        data: mappedData,
    };
}
