import { IconProps } from "../types/types";

export const Logo = ({ className, size = 20 }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size * 3.29}
      height={size}
      viewBox="0 0 442.586 134.666"
      className={className}
    >
      <path
        fill=""
        d="M162.26 125.218q0-.846-.502-1.405-.503-.56-1.336-.559-.815 0-1.323.581-.484.555-.484 1.39 0 .828.49 1.395.514.6 1.324.6.833 0 1.347-.587.484-.562.484-1.415m1.62 3.385h-1.704v-.726q-.932.895-2.065.895-1.394 0-2.318-1.077-.883-1.035-.882-2.453 0-1.457.838-2.473.894-1.085 2.312-1.085 1.214 0 2.115.912v-.743h1.704zm10.528-9.349h1.704v9.349h-1.704zm9.857 4.945h5.569v1.705h-5.569zm18.184 1.019q0-.846-.502-1.405-.502-.56-1.336-.559-.815 0-1.323.581-.484.555-.483 1.39 0 .828.489 1.395.515.6 1.323.6.834 0 1.348-.587.483-.562.484-1.415m1.62 3.385h-1.704v-.726q-.932.895-2.066.895-1.392 0-2.317-1.077-.881-1.035-.881-2.453-.001-1.457.838-2.473.894-1.085 2.312-1.085 1.215 0 2.115.912v-.743h1.704v6.75zm17.025-6.75-2.898 3.239 3.252 3.511h-2.093l-2.868-3.107v3.107h-1.705v-9.349h1.705v5.384l2.489-2.785zm16.008 6.75h-1.671v-2.955q0-.884-.037-1.172-.092-.76-.45-1.035a1.08 1.08 0 0 0-.666-.203q-1.081 0-1.388 1.271-.075.302-.074 1.388v2.707h-1.688v-9.349h1.688v3.291q.988-.86 2.01-.86 1.082 0 1.694.7.582.662.582 1.761zm15.89-3.379q0-.835-.489-1.389-.502-.582-1.323-.582-.828 0-1.342.568-.49.55-.49 1.396 0 .853.484 1.414.513.588 1.348.588.808 0 1.323-.6.489-.565.489-1.395m1.704.017q0 1.418-.883 2.453-.927 1.079-2.323 1.078-1.142 0-2.075-.894v.725h-1.688v-9.348h1.688v3.341q.913-.911 2.125-.91 1.42 0 2.316 1.083.84 1.016.84 2.472m15.422-.023q0-.846-.502-1.405-.503-.56-1.336-.559-.815 0-1.323.581-.483.555-.483 1.39 0 .828.489 1.395.515.6 1.323.6.833 0 1.349-.587.482-.562.483-1.415m1.62 3.385h-1.704v-.726q-.932.895-2.065.895-1.394 0-2.318-1.077-.881-1.035-.881-2.453-.001-1.457.838-2.473.893-1.085 2.312-1.085 1.215 0 2.115.912v-.743h1.704v6.75zm13.936-6.75-.522 1.465q-.298-.148-.492-.148-.392.001-.659.482-.298.528-.273 1.897l.007.328v2.726h-1.688v-6.75h1.468v.861q.475-1.03 1.476-1.03.324.001.683.17m78.521-94.92h17.371v77.468h-17.371zm61.02-16.2h17.371v93.667h-17.371z"
      />
      <path d="M411.81 96.594v-86.13h-17.212v86.13c-.428 11.618-9.977 20.908-21.699 20.908-6.64 0-12.58-2.983-16.562-7.678h-19.702c5.631 14.534 19.743 24.84 36.265 24.84 21.203 0 38.439-16.97 38.871-38.07z" />
      <path d="m354.236 104.584-.081-16.875h-9.619c-1.692-2.598-2.687-4.613-2.687-4.613-2.412-4.588-4.683-16.379-5.138-18.827h.008c-2.973-18.522-18.644-31.124-38.003-31.124-21.472 0-38.88 17.408-38.88 38.881 0 1.738.116 3.451.338 5.13h17.439a21.8 21.8 0 0 1-.615-5.13c0-11.996 9.723-21.719 21.718-21.719 9.836 0 18.142 6.54 20.812 15.508 0 0 2.07 12.15 4.771 17.281 0 0 .994 2.016 2.687 4.613H112.723V38.814h-17.37v48.895H72.99V10.916H55.619v93.668h44.144v-.013c1.372-.092 7.857-.872 11.102-7.354l2.712 7.367zm-240.991 17.733a8.994 8.994 0 0 1-8.994 8.994 8.993 8.993 0 0 1-8.995-8.994 8.99 8.99 0 0 1 8.995-8.993 8.993 8.993 0 0 1 8.994 8.993m193.048-102.06a8.995 8.995 0 1 1-17.989-.001 8.995 8.995 0 0 1 17.989.001M26.318 38.771v57.825c-.427 11.617-9.975 20.907-21.698 20.907a21.8 21.8 0 0 1-4.62-.495v17.385q2.273.271 4.62.272c21.202 0 38.438-16.972 38.87-38.069h.04V38.771zm355.228-16.865a4.484 4.484 0 0 0-4.398-4.208v-.004h-8.418v-.004l-.08.004a2.96 2.96 0 0 1-2.963-2.963 2.96 2.96 0 0 1 2.963-2.964l.08.004v-.004h12.818V10.24H368.65a4.49 4.49 0 1 0 0 8.981h8.341v.004l.08-.004a2.964 2.964 0 0 1 2.95 2.685h-15.819v1.527h17.36v-1.527z" />
      <path
        fill="#d2232a"
        d="M337.808 358.96c-1.661-7.042-7.481-12.685-15.076-13.753-9.885-1.389-19.024 5.498-20.414 15.383-1.389 9.884 5.498 19.023 15.383 20.413a18 18 0 0 0 10.336-1.605c-2.928 10.598-9.394 18.114-20.24 20.601 15.518-.628 28.74-11.452 30.979-27.379a32.7 32.7 0 0 0-.968-13.66"
        transform="translate(-138.707 -345.03)"
      />
      <path
        fill="#d2232a"
        d="M372.923 358.96c-1.661-7.042-7.48-12.685-15.076-13.753-9.886-1.389-19.025 5.498-20.413 15.383-1.391 9.884 5.498 19.023 15.382 20.413a18 18 0 0 0 10.336-1.605c-2.928 10.598-9.396 18.114-20.24 20.601 15.519-.628 28.741-11.452 30.979-27.379a32.65 32.65 0 0 0-.968-13.66"
        transform="translate(-138.707 -345.03)"
      />
    </svg>
  );
};
