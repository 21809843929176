import { cn } from "@pulsex/utils";
import { IconProps } from "../types/types";

export const SlimArrowRightIcon = ({
  className,
  size = 20,
  onClick,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 1.35 1.35"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("rotate-90", className)}
    >
      <path d="M1.037.585.675.225l-.362.36a.037.037 0 1 0 .053.052L.637.368v.717a.037.037 0 1 0 .075 0V.368l.272.269a.037.037 0 0 0 .053-.053Z"></path>
      <path fill="none" d="M0 0h1.35v1.35H0z"></path>
    </svg>
  );
};
