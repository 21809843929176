import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { InsideBanner } from "@/components/InsideBanner/Index";
import {
  MEDIUM_CARD_RESOLUTION_FACTOR,
  SMALL_CARD_RESOLUTION_FACTOR,
} from "@/constants";
import { getViewport } from "@/utils/getViewPort";
import { useState, useEffect } from "react";
import cn from "@pulsex/utils/dist/lib/cn";

export const LebanonHomeWidget = ({
  data,
  isMobile,
  insideBanners,
}: WidgetProps) => {
  const { viewWidth } = getViewport();
  const [deskWidth, setDeskWidth] = useState(0);

  useEffect(() => {
    if (viewWidth && viewWidth > 1520) {
      setDeskWidth(650);
    }
  }, [viewWidth]);
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="  relative">
      <AsideTitleColumn data={data} />

      <div className="flex flex-col w-full">
        <div className="max-md:flex-col flex ">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor}
            className={"md:w-1/2"}
            data={data.posts[0]}
            resolution={deskWidth > 0 ? 2.2 : MEDIUM_CARD_RESOLUTION_FACTOR}
            cropSize="wide"
            cardOrientation="v"
            isMobile={isMobile}
            customSummary="line-clamp-1"
            customTitle="line-clamp-1"
          />
          <div className="flex md:w-1/2 md:mr-4 max-md:mt-2">
            {data.posts.slice(1, 3).map((post: any, index: number) => (
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor}
                key={index}
                className={cn("w-1/2", index > 0 && "mr-2 md:mr-4")}
                data={post}
                resolution={SMALL_CARD_RESOLUTION_FACTOR}
                cardOrientation="v"
                isMobile={isMobile}
                customSummary="2xl:line-clamp-6 line-clamp-4"
                customTitle="line-clamp-2 lg:line-clamp-3"
              />
            ))}
          </div>
        </div>
        <div className="max-md:flex-col flex md:mt-4 max-md:mt-2">
          <div className="flex max-md:flex-col md:w-1/2">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              className={"md:w-1/2"}
              data={data.posts[3]}
              resolution={isMobile ? 1.3 : SMALL_CARD_RESOLUTION_FACTOR}
              cardOrientation="v"
              isMobile={isMobile}
              cropSize="wide"
              showSummary={false}
            />
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              className={cn("md:w-1/2 max-md:mr-0 md:mr-3 max-md:mt-2")}
              data={data.posts[4]}
              resolution={3}
              cardOrientation="h"
              isMobile={isMobile}
              customSummary="line-clamp-3 lg:line-clamp-4 2xl:line-clamp-5"
              customTitle="line-clamp-2 lg:line-clamp-3"
              showImage={false}
            />
          </div>
          <div className="flex flex-col md:w-1/2 md:mr-4">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              className={"max-md:mt-2"}
              data={data.posts[5]}
              resolution={3}
              cardOrientation="h"
              isMobile={isMobile}
              customTitle="line-clamp-1"
              customSummary="line-clamp-1"
              showImage={false}
              customMetaFlex={!data.posts[5]?.summary ? "lg:pt-11" : ""}
            />

            <div className="max-md:flex-col flex max-md:mt-2 mt-4">
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor}
                className={"md:w-1/2"}
                data={data.posts[6]}
                cardOrientation="h"
                isMobile={isMobile}
                showSummary={false}
                showImage={false}
                customTitle="line-clamp-2 md:line-clamp-1 lg:line-clamp-2 "
              />
              {insideBanners &&
                insideBanners?.lebanonWidgetInsideBanners &&
                insideBanners?.lebanonWidgetInsideBanners.map(
                  (banner: any, index: number) => (
                    <InsideBanner
                      isMobile={isMobile}
                      data={banner}
                      type="medium"
                      className="md:w-1/2 max-md:mt-2 md:mr-4"
                      key={index}
                    />
                  )
                )}
            </div>
          </div>
        </div>
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
