"use client";
import useSearchFilter from "@/hooks/useSearchFilters";
import pulseConfig from "@/pulsex.config";
import { cn } from "@pulsex/utils";
import React, { useState, useEffect, useRef } from "react";

interface ISelectProps {
  type: string;
  onSelect: (selectedOption: string) => void;
  initialData?: any;
  className?: string;
}
const DynamicSelect = ({
  type,
  className,
  initialData,
  onSelect,
}: ISelectProps) => {
  const [searchText, setSearchText] = useState("");
  const [optionslist, setOptionsList] = useState<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { data, isLoading } = useSearchFilter(
    pulseConfig,
    searchText,
    initialData || undefined,
    type
  );

  useEffect(() => {
    if (data?.length) {
      setOptionsList(data);
      return;
    }
  }, [data]);

  useEffect(() => {
    if (searchText.trim() === "") {
      setOptionsList([]);
      onSelect("");
      return;
    }
  }, [searchText]);

  const handleSelect = (value: string) => {
    setSearchText(value);
    onSelect(value);
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      className={cn("flex flex-col space-y-1 wf-fll ", className)}
      ref={containerRef}
    >
      <input
        id="input search"
        type="text"
        placeholder={type === "author" ? "ابحث عن الكاتب" : "ابحث عن القسم"}
        value={searchText}
        onChange={(e) => handleSelect(e.target.value)}
        className="h-[38px] w-full px-2"
        onFocus={() => setShowDropdown(true)}
        onBlur={() => setTimeout(() => setShowDropdown(false), 100)}
      />
      {showDropdown && optionslist?.length > 0 && (
        <ul className="w-full max-h-[200px] overflow-y-auto bg-white absolute z-[2] top-[4.2rem] right-0 border">
          {optionslist?.map((item: any, index: number) => (
            <li
              key={index}
              className="p-2 hover:bg-gray-100 cursor-pointer border-b"
              onClick={() => handleSelect(item?.value || item?.key)}
            >
              {item?.value || item?.key}
            </li>
          ))}
        </ul>
      )}

      {/* {showDropdown && selected.length === 0 && searchText && (
        <div className="p-2 bg-white border-b" >لا يوجد نتائج</div>
      )} */}
    </div>
  );
};

export default DynamicSelect;
