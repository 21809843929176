"use client";
import { useQuery } from "@tanstack/react-query";
import { getPage } from "@pulsex/services";
import { blockPageModel } from "@pulsex/models";
export default function useBlockPage(config, pathname) {
    // extract the page form url
    const query = useQuery({
        queryKey: ["GET_PAGE", pathname],
        queryFn: () => getPage(config, pathname),
        refetchOnWindowFocus: false,
    });
    if (query.isError) {
        console.error("Query Error:", query.error);
    }
    let mappedData;
    if (query.isSuccess && query.data) {
        console.log(query === null || query === void 0 ? void 0 : query.data);
        mappedData = blockPageModel(config, query.data);
    }
    return {
        isLoading: query.isLoading,
        data: mappedData,
    };
}
