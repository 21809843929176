import React from "react";
import Link from "next/link";
import { WidgetProps } from "@/types/types";
import { useWindowDimensions } from "@pulsex/hooks";
import { isEmpty } from "@pulsex/utils";
import pulseConfig from "@/pulsex.config";

type Props = {};

export const HorizontalAd = ({ data, isMobile }: WidgetProps) => {
  if (!data?.posts) return;

  const desktopImage = data?.posts[0]?.medias.filter(
    (media: any) => media.placement === "desktop-cover"
  )[0];
  const mobileImage = data?.posts[0]?.medias.filter(
    (media: any) => media.placement === "mobile-cover"
  )[0];
  let backgroundImageUrl = isMobile
    ? pulseConfig.MEDIA_URL + mobileImage?.url
    : pulseConfig.MEDIA_URL + desktopImage?.url;
  return (
    <>
      {!isEmpty(backgroundImageUrl) ? (
        <div className="py-4 mb-5 container ">
          <Link
            href={data?.posts[0]?.url ? `${data?.posts[0]?.url}` : ""}
            className="w-full m-auto max-w-[730px]  min-h-[70px] text-xl text-white  flex justify-center items-center rounded-md overflow-hidden"
            // style={{
            //     backgroundImage: `url(${backgroundImageUrl})`,
            //     backgroundSize: "cover",
            //     backgroundPosition: "center",
            //     width: "100%",
            //     height: "100%",
            // }}
          >
            <img src={backgroundImageUrl} />
          </Link>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
