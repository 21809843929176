import StaticPage from "./StaticPage";
import { Widget } from "@pulsex/types";
import { HorizontalAd } from "@/widgets/Ads/horizontalAd";
import StyleWidget from "@/widgets/Style/Index";
import { HorizontalBanner } from "./Banner/Index";
import { ArabHomeWidget } from "@/widgets/Home/ArabWidget/Index";
import { LebanonHomeWidget } from "@/widgets/Home/LebanonWidget/Index";
import { PeopleHomeWidget } from "@/widgets/Home/PeopleWidget/Index";
import { MediaHomeWidget } from "@/widgets/Home/MediaWidget/Index";
import { SportHomeWidget } from "@/widgets/Home/SportWidget/Index";
import { SupplementHomeWidget } from "@/widgets/Home/SupplementWidget/Index";
import { WorldHomeWidget } from "@/widgets/Home/WorldWidget/Index";
import { FeaturedHomeWidget } from "@/widgets/Home/FeaturedWidget/Index";
import { OpinionHomeWidget } from "@/widgets/Home/OpinionWidget/Index";
import { ChosenHomeWidget } from "@/widgets/Home/ChosenWidget/Index";
import { FeaturedSliderWidget } from "@/widgets/Sections/FeaturedSlider/Index";
import { FourCardsGridThreeTextAsideWidget } from "@/widgets/Sections/FourCardsGridThreeTextAside/Index";
import { FourCardsGridTwoTextAsideWidget } from "@/widgets/Sections/FourCardsGridTwoTextAside/Index";
import { ThreeCardsGridTwoTextAsideWidget } from "@/widgets/Sections/ThreeCardsGridTwoTextAside/Index";
import { TwoCardsGridTwoTextAsideWidget } from "@/widgets/Sections/TwoCardsGridTwoTextAside/Index";
import { MainCardGridThreeTextAsideWidget } from "@/widgets/Sections/MainCardGridThreeTextAside/Index";
import { ThreeCardsGridWidget } from "@/widgets/Sections/ThreeCardsGrid/Index";
import { MediaCardsGridSectionsWidget } from "@/widgets/Sections/MediaCardsGrid/Index";
import { DetailsAsideColumn } from "@/widgets/Details/Aside/Index";
import { DetailsHeader } from "@/widgets/Details/Header/Index";
import { DetailsTextGrid } from "@/widgets/Details/TextGrid/Index";
import { FourTextCardsGridWidget } from "@/widgets/Sections/FourTextCardsGrid/Index";
import { WritersGridWidget } from "@/widgets/Sections/WritersGrid/Index";
import DetailsContent from "@/widgets/Details/Body/Content/Index";
import { MediaCardsTwoColumnGridWidget } from "@/widgets/Details/Media/TwoColumnGrid/Index";
import { MediaCardsFourColumnGridWidget } from "@/widgets/Details/Media/FourColumnGrid/Index";
import { DetailsMediaTemplate } from "@/widgets/Details/Media/DetailsTemplate/Index";
import { MorePageTemplate } from "@/widgets/More/Index";
import { DetailsPDFHeader } from "@/widgets/Details/Body/PDF/Index";
import { NewspaperGridWidget } from "@/widgets/Details/Newspaper/Grid/Index";
import { SubSectionWidget } from "@/widgets/SubSection/Index";
import { WritersHomeWidget } from "@/widgets/Home/WritersWidget/Index";
import { TextCardsListWidget } from "@/widgets/Sections/TextCardsList/Index";
import { OpinionGridWidget } from "@/widgets/Sections/OpinionGrid/Index";
import { TwoColumnGridWidget } from "@/widgets/Sections/TwoColumnsGrid/Index";
import { FiveCardsGridWidget } from "@/widgets/Sections/FiveCardsGrid/Index";
import { FourCardsGridHorizontalSectionsWidget } from "@/widgets/Sections/FourCardsHorizontalGrid/Index";
import { FourColumnGridWidget } from "@/widgets/Sections/FourColumnGrid/Index";
import { FourCardsGridOneTextAsideWidget } from "@/widgets/Sections/FourCardsOneTextAside/Index";
import { TwoCardsGridOneTextAsideWidget } from "@/widgets/Sections/TwoCardsOneTextAside/Index";
import { ThreeCardsGridOneTextAsideWidget } from "@/widgets/Sections/ThreeCardsOneTextAside/Index";
import { SixCardsGridWidget } from "@/widgets/Sections/SixCardsGrid/Index";
import { NewsPaperGridWidget } from "@/widgets/Sections/NewsPaperGrid/Index";
import { AuthorsListWidget } from "@/widgets/Authors/List/Index";
import { AuthorDetailsWidget } from "@/widgets/Authors/Details/Index";
import { ElasticRelatedGrid } from "@/widgets/Sections/ElasticRelatedSection/Index";

export default function WidgetRender(
  item: Widget,
  data: any,
  additionalProps: any,
  isMobile?: boolean,
  dailyPdfData?: any,
  isApple?: boolean,
) {
  const {
    asidePostsWidget,
    insideBanners,
    urgentNewsAside,
    authorWork,
    pdfDetailsContent,
  } = additionalProps || {};

  const showCategory = true;

  switch (item?.style) {
    case "home-featured-widget":
      return (
        <>
          <FeaturedHomeWidget
            data={item}
            isMobile={isMobile}
            urgentNewsAside={urgentNewsAside}
          />
        </>
      );
    case "home-arab-widget":
      return (
        <>
          <ArabHomeWidget
            data={item}
            isMobile={isMobile}
            insideBanners={insideBanners}
          />
        </>
      );
    case "home-lebanon-widget":
      return (
        <>
          <LebanonHomeWidget
            data={item}
            isMobile={isMobile}
            insideBanners={insideBanners}
          />
        </>
      );
    case "home-people-widget":
      return (
        <>
          <PeopleHomeWidget
            data={item}
            isMobile={isMobile}
            insideBanners={insideBanners}
          />
        </>
      );
    case "home-media-widget":
      return (
        <>
          <MediaHomeWidget
            data={item}
            isMobile={isMobile}
            insideBanners={insideBanners}
          />
        </>
      );
    case "home-sport-widget":
    case "home-culture-widget":
      return (
        <>
          <SportHomeWidget
            data={item}
            isMobile={isMobile}
            insideBanners={insideBanners}
          />
        </>
      );
    case "home-supplements-widget":
      return (
        <>
          <SupplementHomeWidget
            data={item}
            isMobile={isMobile}
            insideBanners={insideBanners}
          />
        </>
      );
    case "home-opinion-widget":
      return (
        <>
          <OpinionHomeWidget
            data={item}
            isMobile={isMobile}
            insideBanners={insideBanners}
          />
        </>
      );
    case "home-writers-widget":
      return (
        <>
          <WritersHomeWidget data={item} isMobile={isMobile} />
        </>
      );
    case "home-world-widget":
      return (
        <>
          <WorldHomeWidget
            data={item}
            isMobile={isMobile}
            insideBanners={insideBanners}
          />
        </>
      );
    case "home-chosen-widget":
      return (
        <>
          <ChosenHomeWidget
            data={item}
            isMobile={isMobile}
            insideBanners={insideBanners}
            dailyPdf={dailyPdfData}
          />
        </>
      );
    case "sections-featured-slider-widget":
      return (
        <>
          <FeaturedSliderWidget
            data={item}
            isMobile={isMobile}
            urgentNewsAside={urgentNewsAside}
            dailyPdf={dailyPdfData}
          />
        </>
      );
    case "sections-four-grids-three-text-aside-widget":
      return (
        <>
          <FourCardsGridThreeTextAsideWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-four-grids-one-text-aside-widget":
      return (
        <>
          <FourCardsGridOneTextAsideWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-four-grids-two-text-aside-widget":
      return (
        <>
          <FourCardsGridTwoTextAsideWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-three-grids-two-text-aside-widget":
      return (
        <>
          <ThreeCardsGridTwoTextAsideWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-two-grids-one-text-aside-widget":
      return (
        <>
          <TwoCardsGridOneTextAsideWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-three-grids-one-text-aside-widget":
      return (
        <>
          <ThreeCardsGridOneTextAsideWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-six-grids-widget":
    case "sections-horizontal-six-grids-widget":
      return (
        <>
          <SixCardsGridWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-four-grids-widget":
      return (
        <>
          <FourCardsGridHorizontalSectionsWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-four-columns-grid-widget":
      return (
        <>
          <FourColumnGridWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-four-text-cards-widget":
      return (
        <>
          <FourTextCardsGridWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-writers-grids-widget":
    case "sections-writers-grids-widget-pdfShow":
      return (
        <>
          <WritersGridWidget data={item} isMobile={isMobile} />
        </>
      );
    case "sections-opinions-grids-widget":
    case "sections-opinions-grids-widget-pdfShow":
      return (
        <>
          <OpinionGridWidget
            data={item}
            isMobile={isMobile}
            dailyPdf={dailyPdfData}
          />
        </>
      );
    case "sections-two-grids-two-text-aside-widget":
      return (
        <>
          <TwoCardsGridTwoTextAsideWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-main-grid-three-text-aside-widget":
      return (
        <>
          <MainCardGridThreeTextAsideWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-three-grids-widget":
      return (
        <>
          <ThreeCardsGridWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-media-grid-widget":
      return (
        <>
          <MediaCardsGridSectionsWidget
            data={item}
            isMobile={isMobile}
            insideBanners={insideBanners}
          />
        </>
      );
    case "sections-two-columns-grid-widget":
      return (
        <>
          <TwoColumnGridWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-five-cards-grid-widget":
      return (
        <>
          <FiveCardsGridWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
          />
        </>
      );
    case "sections-paper-grid-widget":
      return (
        <>
          <NewsPaperGridWidget data={item} isMobile={isMobile} />
        </>
      );
    case "horizontal-banner":
    case "horizontal-banner-with-newsletter":
    case "horizontal-banner-add-pdt":
    case "horizontal-banner-add-pdb":
    case "horizontal-banner-add-pd":
    case "horizontal-banner-grey-bg":
    case "horizontal-banner-add-pdt-grey-bg":
    case "horizontal-banner-add-pdb-grey-bg":
    case "horizontal-banner-add-pd-grey-bg":
      return (
        <>
          <HorizontalBanner data={item} isMobile={isMobile} isApple={isApple}/>
        </>
      );
    case "horizontal-ad":
      return (
        <>
          <HorizontalAd data={item} isMobile={isMobile} />
        </>
      );
    case "default-details-widget":
      return (
        <>
          <div className="gap-4 sm:flex ltr:sm:flex-row-reverse md:flex-row lg:mt-2">
            <DetailsAsideColumn
              data={item}
              dailyPdf={dailyPdfData}
              isMobile={isMobile}
              asideWidget={asidePostsWidget}
            />
            <div className="flex !flex-col border border-black max-md:hidden"></div>
            <article className="article flex flex-col md:flex-1">
              <DetailsHeader data={item} isMobile={isMobile} />
              <DetailsContent data={item} isMobile={isMobile} />
            </article>
          </div>
        </>
      );
    case "details-media-widget":
    case "details-video-widget":
      return (
        <>
          <DetailsMediaTemplate
            data={item}
            isMobile={isMobile}
            dailyPdf={dailyPdfData}
          />
        </>
      );
    case "newspaper-details":
      return (
        <>
          <div className="gap-4 sm:flex ltr:md:flex-row-reverse md:flex-row lg:mt-2">
            <DetailsAsideColumn data={item} isMobile={isMobile} />
            <div className="flex !flex-col border border-black max-md:hidden"></div>
            <div className="flex flex-col">
              <DetailsPDFHeader data={item} isMobile={isMobile} />
            </div>
          </div>
        </>
      );
    case "sub-section-widget":
      return (
        <>
          <SubSectionWidget
            data={item}
            pageData={data}
            isMobile={isMobile}
            hideCategory={showCategory}
            dailyPdf={dailyPdfData}
          />
        </>
      );
    case "authors-widget":
      return (
        <>
          <AuthorsListWidget data={item} isMobile={isMobile} />
        </>
      );
    case "author-details-widget":
      return (
        <>
          <AuthorDetailsWidget
            data={item}
            isMobile={isMobile}
            relatedData={authorWork}
          />
        </>
      );
    case "details-other-media-widget":
      return (
        <>
          <MediaCardsTwoColumnGridWidget data={item} isMobile={isMobile} />
        </>
      );
    case "details-media-grid-widget":
      return (
        <>
          <MediaCardsFourColumnGridWidget data={item} isMobile={isMobile} />
        </>
      );
    case "details-text-grid-widget":
      return (
        <>
          <DetailsTextGrid data={item} isMobile={isMobile} />
        </>
      );
    case "sections-text-cards-list-widget":
    case "sections-text-cards-list-pdf-widget":
      return (
        <>
          <TextCardsListWidget
            data={item}
            isMobile={isMobile}
            hideCategory={showCategory}
            pdfDetailsData={pdfDetailsContent}
            insideBanners={insideBanners}
            dailyPdf={dailyPdfData}
          />
        </>
      );
    case "newspaper-grid-widget":
      return (
        <>
          <NewspaperGridWidget data={item} isMobile={isMobile} />
        </>
      );
    case "more-subsection-widget":
      return (
        <>
          <MorePageTemplate data={item} isMobile={isMobile} />
        </>
      );
    case "elastic-related-grid-widget":
      return (
        <>
          <ElasticRelatedGrid
            data={item}
            postId={data?.postId}
            isMobile={isMobile}
          />
        </>
      );
    case "page-static":
      return (
        <>
          <StaticPage data={item} />
        </>
      );
    case "style-widget":
      return (
        <>
          <StyleWidget data={item} />
        </>
      );
    default:
      return null;
  }
}
