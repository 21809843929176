import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { InsideBanner } from "@/components/InsideBanner/Index";
import { cn } from "@pulsex/utils";

export const MediaCardsGridSectionsWidget = ({
  data,
  isMobile,
  insideBanners,
}: WidgetProps) => {
  const isVideo = data?.code.includes("video") ? true : false;

  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} forceTitleBlack={true} />

      <div className="flex flex-col space-y-4 max-md:space-y-2 relative flex-1 overflow-hidden">
        <div className="max-md:flex-col max-md:space-y-2 flex md:space-x-3 lg:space-x-4   rtl:md:!space-x-reverse ltr:md:flex-row-reverse   ">
          <div className="flex flex-col space-y-4 max-md:space-y-2 md:w-1/2">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              data={data.posts[0]}
              cropSize="wide"
              cardOrientation="v"
              isMobile={isMobile}
              showSummary={false}
              customTitle="line-clamp-1"
              showAuthor={false}
              showMediaIcon={true}
              mediatype={isVideo ? "video" : undefined}
              customMetaFlex={"pt-6 2xl:pt-4"}
            />
            {isVideo &&
              insideBanners &&
              insideBanners?.videoWidgetInsideBanners &&
              insideBanners?.videoWidgetInsideBanners.map(
                (banner: any, index: number) => (
                  <InsideBanner
                    isMobile={isMobile}
                    data={banner}
                    type="wide"
                    className=""
                    key={index}
                    magnifyBanner={true}
                  />
                )
              )}
            {!isVideo &&
              insideBanners &&
              insideBanners?.photoWidgetInsideBanners &&
              insideBanners?.photoWidgetInsideBanners.map(
                (banner: any, index: number) => (
                  <InsideBanner
                    isMobile={isMobile}
                    data={banner}
                    type="wide"
                    className=""
                    key={index}
                    magnifyBanner={true}
                  />
                )
              )}
          </div>

          <div className="flex-col space-y-4 max-md:space-y-2 flex md:w-1/2">
            <div className="flex">
              {data.posts.slice(1,3).map((post: any, index: number) => (
                <Card
                  widgetTitle={data?.title}
                  widgetColor={data?.widgetColor}
                  key={index}
                  className={cn(index>0 && "mr-2 md:mr-4","w-1/2")}
                  data={post}
                  resolution={3}
                  cardOrientation="v"
                  cropSize="wide"
                  isMobile={isMobile}
                  showSummary={false}
                  showAuthor={false}
                  showMediaIcon={true}
                  mediatype={isVideo ? "video" : undefined}
                  // customMetaFlex={
                  //   post?.title && post?.title?.length < 27 ? "pt-[24px]" : "pt-3"
                  // }
                  customTitle="min-h-[48px]"
                  customMetaFlex={"pt-2.5 2xl:pt-6"}
                />
              ))}
            </div>
            <div className="flex">
              {data.posts.slice(3, 5).map((post: any, index: number) => (
                <Card
                  widgetTitle={data?.title}
                  widgetColor={data?.widgetColor}
                  key={index}
                  className={cn(index>0 && "mr-2 md:mr-4","w-1/2")}
                  data={post}
                  resolution={3}
                  cardOrientation="v"
                  cropSize="wide"
                  isMobile={isMobile}
                  showSummary={false}
                  showAuthor={false}
                  showMediaIcon={true}
                  mediatype={isVideo ? "video" : undefined}
                  // customMetaFlex={
                  //   post?.title && post?.title?.length < 27 ? "pt-[24px]" : "pt-3"
                  // }
                  customTitle="min-h-[48px]"
                  customMetaFlex={"pt-2.5 2xl:pt-6"}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
