"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendQueryParameters = appendQueryParameters;
class MangopulseClient {
    async post(API_URL, endpoint, bodyParams, queryParams, headers) {
        if (!endpoint)
            return;
        return this.fetch(API_URL, appendQueryParameters(endpoint, queryParams), {
            method: "POST",
            headers: Object.assign({ "Content-Type": "application/json; charset=UTF-8" }, (headers || {})),
            // body: Object.entries(bodyParams)
            //     .map(
            //         ([key, value]) =>
            //             `${encodeURIComponent(key)}=${encodeURIComponent(
            //                 value as any
            //             )}`
            //     )
            //     .join("&"),
            body: JSON.stringify(bodyParams),
        });
    }
    async get(API_URL, endpoint, queryParams) {
        if (!endpoint)
            return;
        return this.fetch(API_URL, appendQueryParameters(endpoint, queryParams));
    }
    async fetch(API_URL, endpoint, options) {
        if (!endpoint)
            return;
        if (endpoint.charAt(0) !== "/")
            endpoint = "/" + endpoint;
        return fetch(API_URL + endpoint, Object.assign({ cache: "no-store", headers: { "content-type": "application/json;charset=UTF-8" } }, options)).then(async (res) => {
            if (!res.ok)
                throw new Error(`HTTP error! Status: ${res.status}`);
            const json = await res.json();
            return json;
        });
    }
}
const client = new MangopulseClient();
exports.default = client;
function appendQueryParameters(url, params) {
    if (!url)
        return "";
    const urlHasParams = url.includes("?");
    const separator = urlHasParams ? "&" : "?";
    params = params !== null && params !== void 0 ? params : {};
    const filteredParams = Object.entries(params)
        .filter(([_, value]) => typeof value !== "function" && typeof value !== "object")
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
    return `${url}${separator}${filteredParams}&platform=headless`;
}
