"use client";
import { useEffect, useState } from "react";
import SectionWrap from "@/components/SectionWrap/Index";
import { cn } from "@pulsex/utils";
import Link from "next/link";
import { Time } from "@/ui/Time/Index";
import pulseConfig from "@/pulsex.config";
import { getNewspaperDate } from "@/utils/getNewspaperDate";
import { useSearch } from "@pulsex/hooks";
import { locale } from "@/locale";
import { AsideSearchColumn } from "@/ui/AsideSearchColumn/Index";
import { useSearchParams } from "next/navigation";
import Pagination from "@/components/Pagination/Index";

export const NewspaperGridWidget = ({ data, types }: any) => {
  // const LoadQ = useLoadMore(pulseConfig, data?.loadMoreLink, data?.posts);
  // const fetchNext = () => {
  //   if (LoadQ?.hasNextPage && !LoadQ.isFetchingNextPage) LoadQ.fetchNextData();
  //   else console.log("no more data");
  // };
  // const triggerRef = useRef(null);

  const [orderBy, setOrderBy] = useState<0 | 1>(0);
  const [page, setPage] = useState(1);
  const searchParams = useSearchParams();
  const searchValue = searchParams.get("q");
  const searchDateFrom = searchParams.get("From");
  let searchDateTo = searchParams.get("To");
  if (searchDateTo) {
    const date = new Date(searchDateTo);
    date.setDate(date.getDate() + 1);
    searchDateTo = date.toISOString().split("T")[0];
  }
  const { data: searchData, isLoading } = useSearch(pulseConfig, {
    q: searchValue || " ",
    orderBy,
    types: ["f322563c-562a-419a-8a7c-8e7fb40b5f35"],
    page,
    from: searchDateFrom ? searchDateFrom + "T00:00:00" : undefined,
    to: searchDateTo ? searchDateTo + "T00:00:00" : undefined,
  });

  const numberOfPages = Math.floor(searchData?.count / 10);

  const handleNext = () => {
    if (page === numberOfPages) return;
    setPage(page + 1);
  };
  const handlePrev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  // useEffect(() => {
  //   let observer: IntersectionObserver | null = null;

  //   try {
  //     if ("IntersectionObserver" in window) {
  //       observer = new IntersectionObserver(
  //         (entries) => {
  //           if (entries[0].isIntersecting && !isLoading) {
  //             fetchNext();
  //           }
  //         },
  //         { threshold: 0.1 } // Trigger when 10% of the element is visible
  //       );

  //       if (triggerRef.current) {
  //         observer.observe(triggerRef.current);
  //       }
  //     } else {
  //       console.warn("IntersectionObserver is not supported on this browser.");
  //     }
  //   } catch (error) {
  //     console.error("Error initializing IntersectionObserver:", error);
  //   }

  //   return () => {
  //     if (observer && triggerRef.current) {
  //       observer.unobserve(triggerRef.current);
  //     }
  //   };
  // }, [data?.isFetching, fetchNext]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page, orderBy]);

  useEffect(() => {
    setPage(1);
  }, [searchDateFrom, searchDateTo]);

  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="relative flex md:flex-row max-md:flex-col max-md:space-y-3 !pb-14">
      <AsideSearchColumn
        className="pt-4"
        data={data}
        template="search"
        newspaper={true}
      />
      {/* <AsideTitleColumn data={data}  template="title"/> */}

      <div className="flex flex-col space-y-10 w-full">
        <div
          className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
          dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
        >
          {searchData?.posts &&
            searchData?.posts?.map((item: any, index: any) => {
              const dateValue = getNewspaperDate(item);

              return (
                <div
                  className={cn(
                    "flex flex-col space-y-1 mr-4 mb-4 max-md:mr-2"
                    // index % 4 === 0 && "md:mr-4",
                    // index >= 4 && "md:mt-4",
                    // index % 2 === 0 && "max-md:mr-2",
                    // index >= 2 && "max-md:mt-4"
                  )}
                  key={index}
                >
                  <Link
                    href={item?.url || ""}
                    className="w-full pt-[147.04%] overflow-hidden bg-gray-300 relative"
                  >
                    <img
                      src={
                        item?.image + "?width=250px" ||
                        "/svgs/fallback/default-paper.svg"
                      }
                      className={cn(
                        "max-w-full w-full h-full absolute top-0 left-0 object-cover",
                        index < 4 && "lg:border-t-0",
                        "border-2 border-black"
                      )}
                    />
                  </Link>

                  <div className="text-2xl leading-6">
                    {locale.edition} {item?.title}
                  </div>
                  {item?.displayDate || dateValue ? (
                    dateValue ? (
                      <div className={cn("text-[#848484] leading-5 text-sm")}>
                        {dateValue}
                      </div>
                    ) : (
                      <Time
                        item={item?.displayDate}
                        className={cn("text-sm !leading-5")}
                      />
                    )
                  ) : (
                    ""
                  )}
                </div>
              );
            })}

          {isLoading
            ? Array.from({ length: 8 }).map((_, index) => (
                <div
                  key={index}
                  className={cn(
                    "pt-[147.04%] bg-gray-300 relative space-y-1 mr-4 max-md:mr-2 mt-2",
                    index % 4 === 0 && "md:mr-4",
                    index >= 4 && "md:mt-4",
                    index % 2 === 0 && "max-md:mr-2",
                    index >= 2 && "max-md:mt-4"
                  )}
                />
              ))
            : ""}
        </div>
        {/* {LoadQ?.hasNextPage && (   )} */}
        {/* <div className="py-6">
            <div
              className={cn(
                "flex space-x-2.5 w-fit cursor-pointer mx-auto",
                "text-xl text-minorTheme px-3 py-2",
                "border border-minorTheme",
                isLoading
                  ? "border-transparent cursor-auto"
                  : "lg:hover:border-masterRed lg:hover:text-masterRed"
              )}
              ref={triggerRef}
              onClick={() => ()}
            >
              {isLoading ? (
                <SpinnerIcon className={"fill-masterRed"} />
              ) : (
                locale.showMore
              )}
            </div>
          </div> */}

        {!isLoading && searchData?.count === 0 && (
          <div className={cn("flex space-x-2.5 w-fit py-8 mx-auto")}>
            <div className="text-3xl text-black px-3 py-3">
              لم يتم العثور على أي جريدة
            </div>
          </div>
        )}
        {numberOfPages > 1 && (
          <Pagination
            currentPage={page}
            setPage={setPage}
            numberOfPages={numberOfPages}
            handlePrev={handlePrev}
            handleNext={handleNext}
            className={"py-4"}
          />
        )}
      </div>
    </SectionWrap>
  );
};
