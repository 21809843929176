import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { useState, useRef, useEffect } from "react";
import { SwiperSlide } from "swiper/react";
import SwipperWrapper from "@/components/SwiperWrapper/Index";
import { cn } from "@pulsex/utils";
import { InsideBanner } from "@/components/InsideBanner/Index";
import { SlimArrowRightIcon } from "@/icons/SlimArrowRight";
import {
  MEDIUM_CARD_RESOLUTION_FACTOR,
  SMALL_CARD_RESOLUTION_FACTOR,
} from "@/constants";
import { getViewport } from "@/utils/getViewPort";

export const MediaHomeWidget = ({
  data,
  isMobile,
  insideBanners,
}: WidgetProps) => {
  const isVideo = data?.code.includes("video") ? true : false;

  const [swiperInstance, setSwiperInstance] = useState<any>();
  const [carouselEnd, setCarouselEnd] = useState(false);
  const [carouselStart, setCarouselStart] = useState(true);
  const paginationRef = useRef(null);

  const { viewWidth } = getViewport();
  const [deskWidth, setDeskWidth] = useState(0);

  useEffect(() => {
    if (viewWidth && viewWidth > 1520) {
      setDeskWidth(650);
    }
  }, [viewWidth]);
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} forceTitleBlack={true} />

      <div className="flex flex-col relative flex-1 overflow-hidden">
        <div className="max-md:flex-col flex">
          <div className="flex flex-col md:w-1/2">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              data={data?.posts[0]}
              resolution={deskWidth > 0 ? 2.2 : MEDIUM_CARD_RESOLUTION_FACTOR}
              cropSize="wide"
              cardOrientation="v"
              isMobile={isMobile}
              showSummary={false}
              customTitle="line-clamp-1"
              showAuthor={false}
              showMediaIcon={true}
              mediatype={isVideo ? "video" : undefined}
              customMetaFlex={"pt-6 2xl:pt-4"}
            />
            {isVideo &&
              insideBanners &&
              insideBanners?.videoWidgetInsideBanners &&
              insideBanners?.videoWidgetInsideBanners.map(
                (banner: any, index: number) => (
                  <InsideBanner
                    isMobile={isMobile}
                    data={banner}
                    type="wide"
                    className="md:mt-4 max-md:mt-2"
                    key={index}
                    magnifyBanner={true}
                  />
                )
              )}
            {!isVideo &&
              insideBanners &&
              insideBanners?.photoWidgetInsideBanners &&
              insideBanners?.photoWidgetInsideBanners.map(
                (banner: any, index: number) => (
                  <InsideBanner
                    isMobile={isMobile}
                    data={banner}
                    type="wide"
                    className="md:mt-4 max-md:mt-2"
                    key={index}
                    magnifyBanner={true}
                  />
                )
              )}
          </div>

          <div className="flex-col flex md:w-1/2 md:mr-4 max-md:mt-2">
            <div className="flex">
              {data.posts.slice(1, 3).map((post: any, index: number) => (
                <Card
                  widgetTitle={data?.title}
                  widgetColor={data?.widgetColor}
                  key={index}
                  className={cn("w-1/2", index > 0 && "mr-2 md:mr-4")}
                  data={post}
                  resolution={SMALL_CARD_RESOLUTION_FACTOR}
                  cardOrientation="v"
                  cropSize="wide"
                  isMobile={isMobile}
                  showSummary={false}
                  showAuthor={false}
                  showMediaIcon={true}
                  mediatype={isVideo ? "video" : undefined}
                  customTitle="min-h-[48px]"
                  customMetaFlex={"pt-2.5 2xl:pt-6"}
                />
              ))}
            </div>
            <div className="flex md:mt-4 max-md:mt-2">
              {data.posts.slice(3, 5).map((post: any, index: number) => (
                <Card
                  widgetTitle={data?.title}
                  widgetColor={data?.widgetColor}
                  key={index}
                  className={cn("w-1/2", index > 0 && "mr-2 md:mr-4")}
                  data={post}
                  resolution={SMALL_CARD_RESOLUTION_FACTOR}
                  cardOrientation="v"
                  cropSize="wide"
                  isMobile={isMobile}
                  showSummary={false}
                  showAuthor={false}
                  showMediaIcon={true}
                  mediatype={isVideo ? "video" : undefined}
                  customTitle="min-h-[48px]"
                  customMetaFlex={"pt-2.5 2xl:pt-6"}
                />
              ))}
            </div>
          </div>
        </div>
        {data?.posts?.length > 7 ? (
          <div className="max-md:mt-2 md:mt-4 swipe-widget w-full relative max-w-full block overflow-hidden">
            <div className="flex items-center justify-between w-full h-fit z-[1]">
              <div
                className={cn(
                  "p-1.5 select-none z-10",
                  "absolute top-[43%] max-sm:top-[40%] -translate-y-1/2 rtl:-right-0 ltr:-left-0",
                  carouselStart
                    ? "bg-gray-300 hidden"
                    : "bg-black/85 hover:bg-black/95 cursor-pointer"
                )}
                onClick={() => swiperInstance.slidePrev()}
              >
                <SlimArrowRightIcon
                  className={cn(
                    "fill-white ltr:-rotate-90",
                    carouselStart && "fill-gray-500/80"
                  )}
                  size={24}
                />
              </div>
              {/* <div
                ref={paginationRef}
                className="hidden md:flex justify-center _c1-swiper-pagination-container _white-pagination"
              ></div> */}
              <div
                className={cn(
                  "p-1.5 select-none z-10",
                  "absolute top-[43%] max-sm:top-[40%] -translate-y-1/2 rtl:-left-0 ltr:-right-0",
                  carouselEnd
                    ? "bg-gray-300 hidden"
                    : "bg-black/85 hover:bg-black/95 cursor-pointer"
                )}
                onClick={() => swiperInstance.slideNext()}
              >
                <SlimArrowRightIcon
                  className={cn(
                    "fill-white rtl:-rotate-90",
                    carouselEnd && "fill-gray-500/80"
                  )}
                  size={24}
                />
              </div>
            </div>
            <div className="w-full max-w-full">
              <SwipperWrapper
                paginationRef={paginationRef}
                swiperInstance={swiperInstance}
                setSwiperInstance={setSwiperInstance}
                setCarouselEnd={setCarouselEnd}
                setCarouselStart={setCarouselStart}
                breakpoints={{
                  768: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 20,
                  },
                }}
              >
                {data?.posts?.slice(5).map((item: any, index: any) => (
                  <SwiperSlide key={index} className="select-none !h-auto">
                    <Card
                      widgetTitle={data?.title}
                      widgetColor={data?.widgetColor}
                      key={index}
                      data={item}
                      cardOrientation="v"
                      resolution={SMALL_CARD_RESOLUTION_FACTOR}
                      cropSize="wide"
                      isMobile={isMobile}
                      showSummary={false}
                      showAuthor={false}
                      showMediaIcon={true}
                      mediatype={isVideo ? "video" : undefined}
                      className="h-full"
                    />
                  </SwiperSlide>
                ))}
              </SwipperWrapper>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
