import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { InsideBanner } from "@/components/InsideBanner/Index";
import DailyPdf from "@/components/DailyPdf/Index";
import { cn } from "@pulsex/utils";
import { PlayIcon } from "@/icons/PlayIcon";
import Link from "next/link";
import { useState } from "react";
import { getNewspaperDate } from "@/utils/getNewspaperDate";
import { locale } from "@/locale";
import pulseConfig from "@/pulsex.config";
const extractDayAndDate = (
  dateStr: string
): { dayName?: string; remainingDate: string } => {
  if (!dateStr || typeof dateStr !== "string") {
    return { dayName: "", remainingDate: "" };
  }
  const arabicDays = [
    "الأحد",
    "الاثنين",
    "الإثنين",
    "الثلاثاء",
    "الأربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];
  const parts = dateStr.split(" ");
  const isDayName = arabicDays.includes(parts[0]);
  const dayName = isDayName ? parts[0] : "";
  const remainingDate = isDayName ? parts.slice(1).join(" ") : dateStr;

  return { dayName, remainingDate };
};
export const TextCardsListWidget = ({
  data,
  isMobile,
  pdfDetailsData,
  insideBanners,
  dailyPdf,
}: WidgetProps) => {
  const [todaysNumber, setTodaysNumber] = useState<any>();
  const showPdf = data?.style.includes("pdf") ? true : false;
  if (!data?.posts?.length) return;
  const pdfContent = pdfDetailsData?.mainPost?.medias.filter(
    (item: { type: string; placement: string; url: string }) => {
      const isFileMain = item.type === "file" && item.placement === "main";
      const hasValidUrl = item.url && item.url.endsWith(".pdf");

      return isFileMain && hasValidUrl;
    }
  );
  const pdfText = locale.pdfPages;
  const PdfDate =
    getNewspaperDate(pdfDetailsData?.mainPost) ||
    pdfDetailsData?.mainPost?.displayDate;

  const isToday = pdfDetailsData?.mainPost?.title === todaysNumber;
  const { dayName, remainingDate } = extractDayAndDate(PdfDate);

  return (
    <>
      {showPdf ? (
        <>
          <div
            className={cn(
              "flex md:space-x-2.5 rtl:md:space-x-reverse ",
              "max-md:flex-col max-md:space-y-2 max-md:items-center max-lg:pr-0",
              "px-4 py-8 max-md:py-4 mx-auto justify-center"
            )}
          >
            <p className="lg:text-[42px] md:text-4xl text-3xl leading-7">
              {"جريدة يوم " + dayName}
            </p>

            {remainingDate ? (
              <p className="lg:text-[42px] md:text-4xl text-3xl leading-7">
                {remainingDate}
              </p>
            ) : (
              ""
            )}
            {pdfDetailsData?.mainPost?.title ? (
              <p className="lg:text-[42px] md:text-4xl text-3xl leading-7">
                العدد {pdfDetailsData?.mainPost?.title}
              </p>
            ) : (
              ""
            )}
          </div>

          {/* current pdf */}
          <div
            className={cn(
              "flex-col max-w-full h-fit hidden max-lg:flex",
              " max-md:w-3/4 max-lg:w-2/4 mb-6",
              "w-full border-2 border-black max-lg:mx-auto"
            )}
          >
            {data?.mainPost ? (
              pdfContent?.[0]?.url ? (
                <Link
                  href={pdfContent?.[0]?.url}
                  target={"_blank"}
                  aria-label="daily pdf"
                >
                  <img
                    loading="lazy"
                    src={
                      pdfDetailsData?.mainPost?.image
                        ? pdfDetailsData?.mainPost?.image + "?width=400"
                        : "/svgs/fallback/default-paper.svg"
                    }
                    alt="daily pdf"
                    className={cn("max-w-full w-full")}
                  />
                </Link>
              ) : (
                <div>
                  <img
                  loading="lazy"
                    src={
                      pdfDetailsData?.mainPost?.image
                        ? pdfDetailsData?.mainPost?.image + "?width=400"
                        : "/svgs/fallback/default-paper.svg"
                    }
                    alt="pdf"
                    className={cn("max-w-full w-full")}
                  />
                </div>
              )
            ) : (
              ""
            )}

            {pdfContent?.[0]?.url ? (
              <Link
                href={pdfContent?.[0]?.url}
                target={"_blank"}
                className={cn(
                  "bg-black p-2 flex items-center space-x-1  rtl:space-x-reverse  text-white font-amine-light max-md:text-2xl text-3xl"
                )}
              >
                <PlayIcon size={28} className="fill-masterRed rotate-180" />
                <p>{pdfText}</p>
              </Link>
            ) : (
              <div
                className={cn(
                  "bg-black p-2 flex items-center space-x-1  rtl:space-x-reverse  text-white font-amine-light max-md:text-2xl text-3xl"
                )}
              >
                <PlayIcon size={28} className="fill-masterRed rotate-180" />
                <p>{pdfText}</p>
              </div>
            )}
          </div>
        </>
      ) : (
        ""
      )}
      <SectionWrap className="relative md:flex ltr:md:flex-row-reverse">
        <AsideTitleColumn
          data={data}
          pdf={!isToday && showPdf}
          forceTitleBlack={true}
          template="today"
          dailyPdf={dailyPdf}
          customPdf="w-[90%] max-lg:w-full max-md:hidden max-lg:block"
          showMobileMoreBtn={false}
        />
        <div
          className="grid grid-cols-1 w-full lg:w-1/2"
          dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
        >
          {data?.posts?.map((item: any, index: any) => (
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor || data?.mainPost?.tag?.color}
              key={index}
              data={item}
              className={cn(index > 0 && "max-md:mt-3 md:mt-4")}
              cardOrientation="h"
              customTitle="line-clamp-2 mt-2 max-lg:text-2xl"
              showSummary={true}
              customSummary="line-clamp-1"
              isMobile={isMobile}
              showImage={false}
            />
          ))}
        </div>
        {showPdf ? (
          <div
            className="md:hidden lg:grid grid-cols-2 max-md:grid-cols-1 gap-4 w-full  max-lg:mt-4 lg:w-1/2"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            {/* current pdf large screen */}
            <div
              className={cn(
                "flex flex-col max-w-full h-fit max-lg:hidden",
                "w-full border-2 border-black md:border-t-0"
              )}
            >
              {data?.mainPost ? (
                pdfContent?.[0]?.url ? (
                  <Link
                    href={pdfContent?.[0]?.url}
                    target={"_blank"}
                    aria-label="daily pdf"
                  >
                    <img
                      loading="lazy"
                      src={
                        pdfDetailsData?.mainPost?.image
                          ? pdfDetailsData?.mainPost?.image + "?width=400"
                          : "/svgs/fallback/default-paper.svg"
                      }
                      alt="daily pdf"
                      className={cn("max-w-full w-full")}
                    />
                  </Link>
                ) : (
                  <div>
                    <img
                      loading="lazy"
                      src={
                        pdfDetailsData?.mainPost?.image
                          ? pdfDetailsData?.mainPost?.image + "?width=400"
                          : "/svgs/fallback/default-paper.svg"
                      }
                      alt="pdf"
                      className={cn("max-w-full w-full")}
                    />
                  </div>
                )
              ) : (
                ""
              )}

              {pdfContent?.[0]?.url ? (
                <Link
                  href={pdfContent?.[0]?.url}
                  target={"_blank"}
                  className={cn(
                    "bg-black p-2 flex items-center space-x-1  rtl:space-x-reverse  text-white font-amine-light max-md:text-xl max-lg:text-2xl text-3xl"
                  )}
                >
                  <PlayIcon size={28} className="fill-masterRed rotate-180" />
                  <p>{pdfText}</p>
                </Link>
              ) : (
                <div
                  className={cn(
                    "bg-black p-2 flex items-center space-x-1  rtl:space-x-reverse  text-white font-amine-light max-md:text-xl max-lg:text-2xl text-3xl"
                  )}
                >
                  <PlayIcon size={28} className="fill-masterRed rotate-180" />
                  <p>{pdfText}</p>
                </div>
              )}
            </div>
            {/* daily pdf ipad and mobile */}
            {!isToday ? (
              <DailyPdf
                template="today"
                pdfData={dailyPdf}
                className={cn("max-lg:block hidden max-md:w-4/5")}
                customSize={20}
                customTitle="max-md:text-3xl"
                setTodaysNumber={setTodaysNumber}
              />
            ) : (
              ""
            )}
            {insideBanners &&
              insideBanners?.pdfWidgetInsideBanners &&
              insideBanners?.pdfWidgetInsideBanners.map(
                (banner: any, index: number) => (
                  <InsideBanner
                    isMobile={isMobile}
                    data={banner}
                    type="tall"
                    className=""
                    key={index}
                  />
                )
              )}
          </div>
        ) : (
          <div className="max-lg:hidden lg:w-1/2" />
        )}

        {/* <MoreWrap moreUrl={data?.moreLink} /> */}
      </SectionWrap>
    </>
  );
};
