import { IconProps } from "../types/types";

export const CopyIcon = ({ className, size = 20 }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 1.2 1.2"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M.15.9h.006A.05.05 0 0 0 .2.856V.2h.656a.05.05 0 0 0 0-.099L.85.1H.144A.05.05 0 0 0 .1.144v.712A.05.05 0 0 0 .144.9zm.9.2h.006a.05.05 0 0 0 .044-.044V.344a.05.05 0 0 0-.038-.043L1.056.3H.344A.05.05 0 0 0 .3.344v.712a.05.05 0 0 0 .038.043l.006.001H.35zM.4 1V.4H1V1z" />
    </svg>
  );
};
