"use client";
import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { extractSection } from "@/utils/extractSection";
import { usePathname } from "next/navigation";
import pulseConfig from "@/pulsex.config";
import { cn } from "@pulsex/utils";
export const ThreeCardsGridOneTextAsideWidget = ({
  data,
  isMobile,
  hideCategory,
}: WidgetProps) => {
  const pathname = usePathname();
  const colorTheme = extractSection(pathname);
  if (!data?.posts?.length) return;
  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} colorTheme={colorTheme || ""} />

      <div className="max-md:flex-col max-md:space-y-2 flex md:space-x-3 lg:space-x-4   rtl:md:!space-x-reverse ltr:md:flex-row-reverse   ">
        <Card
          widgetTitle={data?.title}
          widgetColor={data?.widgetColor || colorTheme}
          className={"md:w-1/2"}
          data={data.posts[0]}
          resolution={3}
          cardOrientation="v"
          isMobile={isMobile}
          cropSize="rectangle"
          customSummary="line-clamp-1"
          customTitle={
            data.posts[0]?.summary || data.posts[0]?.textContent
              ? "line-clamp-1"
              : "line-clamp-2"
          }
          showCategory={hideCategory}
        />
        <div className="flex flex-col space-y-4 max-md:space-y-2 md:w-1/2">
          <div
            className="flex w-full"
            dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
          >
            {data.posts.slice(1, 3).map((post: any, index: number) => (
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor || colorTheme}
                key={index}
                className={cn(index>0 && "mr-2 md:mr-4","w-1/2")}
                data={post}
                resolution={2}
                cardOrientation="v"
                cropSize="wide"
                isMobile={isMobile}
                showSummary={true}
                customSummary="line-clamp-1"
                showCategory={hideCategory}
              />
            ))}
          </div>
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor || colorTheme}
            className={""}
            data={data.posts[3]}
            isMobile={isMobile}
            customSummary="line-clamp-1"
            customTitle="line-clamp-1"
            showImage={false}
            showCategory={hideCategory}
            customMetaFlex={"pt-[22px]"}
          />
        </div>
      </div>

      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
