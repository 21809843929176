"use client";
import { cn } from "@pulsex/utils";
import React, { Suspense } from "react";
import { TitleSection } from "../Title/Index";
import SearchInput from "@/components/SearchInput/Index";
import { locale } from "@/locale";

type AsideProps = {
  data: any;
  className?: string;
  template?: string;
  setIsLoading?: any;
  setResponse?: any;
  newspaper?:boolean
};

export const AsideSearchColumn = ({
  data,
  className,
  template,
  setIsLoading,
  setResponse,
  newspaper=false
}: AsideProps) => {
  let subMenus: Array<any> | undefined;

  try {
    subMenus = data?.subMenus ? JSON.parse(data?.subMenus) : undefined;
  } catch (error) {
    console.error("Failed to parse subMenus:", error);
    subMenus = undefined;
  }

  if (!data && !template) return;

  return (
    <div
      className={cn(
        "max-md:mt-4 flex w-[200px] min-w-[200px] flex-col items-start justify-start max-md:w-full max-md:mb-3",
        className
      )}
    >
      {data?.title ? (
        <TitleSection
          title={data?.title}
          moreUrl={data?.moreLink}
          tabsArray={subMenus}
          customTitle=""
          colorTheme={data?.widgetColor || data?.mainPost?.tag?.color}
        />
      ) : template === "search" ? (
        <div
          className={cn(
            "text-2xl lg:text-4xl text-[#242424] max-sm:text-4xl font-semibold"
          )}
        >
          البحث
        </div>
      ) : (
        ""
      )}

      {template !== "search" ? (
        <label
          className={cn(
            "text-2xl lg:text-3xl cursor-pointer text-[#858588] max-sm:text-2xl font-semibold"
          )}
        >
          شاهد نتائج الأسبوع
        </label>
      ) : (
        ""
      )}

      <>
        <div className="mt-10 max-md:mt-3 text-2xl font-amine-light">
          <div>{locale.searchIndicatorMessage}</div>
          <div>{locale.searchSubIndicatorMessage}</div>
        </div>
        <Suspense>
          <SearchInput
            setData={setResponse}
            setIsLoading={setIsLoading}
            className={"my-0 rounded-none"}
            newspaper={newspaper}
          />
        </Suspense>
      </>
    </div>
  );
};
