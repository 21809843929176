import useLoadMore from "./lib/queries/useLoadMore";
import useBlockPage from "./lib/queries/useBlockPage";
import useWidgetPage from "./lib/queries/useWidgetPage";
import useSearch from "./lib/queries/useSearch";
import useInfiniteScroll from "./lib/queries/useInfiniteScroll";
import usePageViewCollector from "./lib/queries/usePageViewCollector";
import useMangopulseTracker from "./lib/queries/useManoPulseTracker";
import useSubscribe from "./lib/queries/useSubscribe";
import useTrending from "./lib/queries/useTrending";
import useWindowDimensions from "./lib/useWindowDimensions";
import useMenus from "./lib/queries/useMenus";
import useResponsive from "./lib/useResponsive";
import useRelatedPosts from "./lib/queries/useRelatedPosts";
import useScroll from "./lib/useScroll";
import usePostDetails from "./lib/queries/usePostDetails";
export { useBlockPage, useWidgetPage, useSearch, useLoadMore, useInfiniteScroll, usePageViewCollector, useMangopulseTracker, useSubscribe, useTrending, useWindowDimensions, useMenus, useResponsive, useRelatedPosts, useScroll, usePostDetails, };
