import { useEffect, useState } from "react";
export default function useScroll() {
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [bodyOffset, setBodyOffset] = useState();
    const [scrollY, setScrollY] = useState(bodyOffset && bodyOffset.top);
    const [scrollDirection, setScrollDirection] = useState();
    const listener = (e) => {
        setBodyOffset(document.body.getBoundingClientRect());
        bodyOffset && setScrollY(-bodyOffset.top);
        bodyOffset &&
            setScrollDirection(lastScrollTop > -(bodyOffset === null || bodyOffset === void 0 ? void 0 : bodyOffset.top) ? "down" : "up");
        bodyOffset && setLastScrollTop(-bodyOffset.top);
    };
    useEffect(() => {
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        };
    });
    return {
        scrollY,
        scrollDirection,
    };
}
