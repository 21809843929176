import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { InsideBanner } from "@/components/InsideBanner/Index";
import { SMALL_CARD_RESOLUTION_FACTOR } from "@/constants";
import pulseConfig from "@/pulsex.config";

export const SupplementHomeWidget = ({
  data,
  isMobile,
  insideBanners,
}: WidgetProps) => {
  if (!data?.posts?.length) return;
  return (
    <SectionWrap className="   relative">
      <AsideTitleColumn data={data} />

      <div className="w-full max-md:flex-col max-md:space-y-2 flex md:space-x-3 lg:space-x-4   rtl:md:!space-x-reverse ltr:md:flex-row-reverse   ">
        {isMobile ? (
          <>
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              className={"md:w-1/4"}
              data={data.posts[0]}
              resolution={isMobile ? 1.3 : SMALL_CARD_RESOLUTION_FACTOR}
              cardOrientation="v"
              cropSize="wide"
              isMobile={isMobile}
              customSummary="line-clamp-4"
            />
            <div
              className="grid grid-cols-2 gap-4 max-md:gap-2 md:w-1/2"
              dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
            >
              {data.posts
                .slice(1)
                .slice(0, 2)
                .map((post: any, index: number) => (
                  <Card
                    widgetTitle={data?.title}
                    widgetColor={data?.widgetColor}
                    key={index}
                    className={""}
                    data={post}
                    resolution={3}
                    cardOrientation="v"
                    cropSize="wide"
                    isMobile={isMobile}
                    customSummary="line-clamp-4"
                  />
                ))}
            </div>
          </>
        ) : (
          <>
            {data.posts.slice(0, 3).map((post: any, index: number) => (
              <Card
                widgetTitle={data?.title}
                widgetColor={data?.widgetColor}
                key={index}
                className={"md:w-1/4"}
                data={post}
                resolution={isMobile ? 1.3 : SMALL_CARD_RESOLUTION_FACTOR}
                cardOrientation="v"
                cropSize="wide"
                isMobile={isMobile}
                customSummary="line-clamp-4"
              />
            ))}
          </>
        )}
        <div className="flex-col space-y-4 max-md:space-y-2 flex md:w-1/4">
          {insideBanners &&
            insideBanners?.supplementsWidgetInsideBanners &&
            insideBanners?.supplementsWidgetInsideBanners.map(
              (banner: any, index: number) => (
                <InsideBanner
                  isMobile={isMobile}
                  data={banner}
                  type="medium"
                  className=""
                  key={index}
                />
              )
            )}
        </div>
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
