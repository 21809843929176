import { IconProps } from "../types/types";

export const SearchIcon = ({ className, size }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 0.85 0.85"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="m0.83 0.793 -0.259 -0.259A0.324 0.324 0 0 0 0.65 0.324c0 -0.179 -0.146 -0.325 -0.325 -0.325a0.324 0.324 0 0 0 -0.23 0.095A0.323 0.323 0 0 0 0 0.324c0 0.179 0.146 0.325 0.325 0.325a0.324 0.324 0 0 0 0.211 -0.078l0.259 0.259zM0.325 0.599a0.275 0.275 0 0 1 -0.275 -0.275 0.273 0.273 0 0 1 0.081 -0.194A0.273 0.273 0 0 1 0.325 0.049c0.152 0 0.275 0.123 0.275 0.275s-0.123 0.275 -0.275 0.275"
        fill=""
      />
    </svg>
  );
};
