import { SuccessIcon } from "@/icons/SuccessIcon";
import { cn } from "@pulsex/utils";
import React, { useEffect } from "react";

type Props = {
  message?: string;
  isVisible?: boolean;
  onHide: () => void;
  className?: string;
};

export const Toast = ({
  message,
  isVisible = true,
  onHide,
  className,
}: Props) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onHide();
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [isVisible, onHide]);

  return (
    <>
      {isVisible ? (
        <div
          id="toast-simple"
          className={cn(
            "z-[99] fixed bottom-6 rtl:right-28 rtl:max-md:right-1/2 ltr:left-28 ltr:max-md:left-1/2",
            "max-md:transform max-md:translate-x-1/2 flex ltr:flex-row-reverse items-center w-full",
            "max-w-xs p-3 space-x-3  rtl:space-x-reverse  text-gray-600 bg-greyBackground",
            "border border-grey divide-x rtl:divide-x-reverse divide-greyBackgroundDim shadow",
            className
          )}
          role="alert"
        >
          <SuccessIcon className="fill-green-700 w-7 h-7" />
          <div className="rtl:pr-3 ltr:pl-4 text-[22px]">
            {message ? message : "operation done successfully"}
          </div>
        </div>
      ) : (
        ""
      )}{" "}
    </>
  );
};
