import { Page, Widget } from "@pulsex/types";

const findWidgetByStyle = (
  widgets: Widget[],
  style: string | string[]
): Widget | null => {
  if (Array.isArray(style)) {
    return widgets.find((item: Widget) => style.includes(item.style)) || null;
  } else {
    return widgets.find((item: Widget) => item.style === style) || null;
  }
};
function groupWidgetsByPattern(widgetsData: any) {
  const groupedWidgets: Record<string, Widget[]> = {};

  widgetsData?.widgets.map((item: any) => {
    const match =
      item?.style?.match(/^(\w+)-inside-banner-(\w+)$/) ||
      item?.style?.match(/^(\w+)-inside-banner/);

    if (match) {
      const x = match[1];
      const y = match[2] || "";
      const style = y ? `${x}-inside-banner-${y}` : `${x}-inside-banner`;

      if (!groupedWidgets[x]) {
        groupedWidgets[x] = [];
      }

      const widget = findWidgetByStyle(widgetsData.widgets, style);
      if (widget) groupedWidgets[x].push(widget);
    }
  });

  const result: Record<string, Widget[]> = {};
  Object.entries(groupedWidgets).forEach(([key, widgets]) => {
    result[`${key}WidgetInsideBanners`] = widgets;
  });

  return result;
}
export const getWidgetGrouping = (widgetsData: Page) => {
  // @ts-ignore
  widgetsData.widgets = widgetsData.widgets.map((widget) => {
    if (widget.code === "recommended")
      return {
        ...widget,
        style: "aside/recommended",
      };
    return widget;
  });

  if (widgetsData?.widgets?.length) {
    const TabbedWidgets = widgetsData?.widgets?.filter(
      (widget: any) => widget?.group !== undefined
    );

    const groupedByGroup =
      TabbedWidgets?.reduce((acc: any, widget: any) => {
        const group = widget?.group?.trim();
        const hasTitleAndPosts = widget?.title && widget?.posts;

        if (group && hasTitleAndPosts) {
          let groupObject = acc?.find((item: any) => item["tabKey"] === group);

          if (!groupObject) {
            groupObject = { tabKey: group, tabData: [] };
            acc.push(groupObject);
          }

          groupObject["tabData"].push(widget);
        }
        return acc;
      }, [] as { tabKey: string; tabData: Widget[] }[]) || [];

    let articleGroupedArray: any;
    let multimediaGroupedArray: any;

    if (groupedByGroup) {
      groupedByGroup?.map((item: any) => {
        if (item?.tabKey === "Article-Tabs") {
          return (articleGroupedArray = item?.tabData);
        }
        if (item?.tabKey === "Multimedia-Tabs") {
          return (multimediaGroupedArray = item?.tabData);
        }
      });
    }
    const insideBanners = groupWidgetsByPattern(widgetsData);

    return {
      asidePostsWidget: findWidgetByStyle(
        widgetsData.widgets,
        "aside-posts-widget"
      ),
      authorWork: findWidgetByStyle(widgetsData.widgets, "author-work"),
      pdfDetailsContent: findWidgetByStyle(
        widgetsData.widgets,
        "pdf-details-content"
      ),
      insideBanners,
      urgentNewsAside: findWidgetByStyle(
        widgetsData.widgets,
        "urgent-news-widget"
      ),
    };
  }
};
