"use client";
import SectionWrap from "@/components/SectionWrap/Index";
import { UrgentWidget } from "@/components/Urgent/Index";
import { WidgetProps } from "@/types/types";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import { extractSection } from "@/utils/extractSection";
import { HeroSlider } from "@/widgets/HeroSlider/Index";
import { cn } from "@pulsex/utils";
import { usePathname } from "next/navigation";

export const FeaturedSliderWidget = ({
  data,
  isMobile,
  urgentNewsAside,
  dailyPdf,
}: WidgetProps) => {
  const pathname = usePathname();
  const colorTheme = extractSection(pathname);
  if (!data?.posts?.length) return;

  const withUrgentAside = data?.mobileWidgetKey === "urgentAside";

  return (
    <SectionWrap className="max-md:pt-3 pt-5 md:pb-5 border-t-transparent">
      <AsideTitleColumn
        data={data}
        pdf={true}
        customTitle={""}
        className="lg:pt-0"
        colorTheme={colorTheme || ""}
        dailyPdf={dailyPdf}
      />
      {urgentNewsAside || withUrgentAside ? (
        <div className="w-full flex flex-col space-y-2 md:space-x-3 lg:space-x-4  rtl:md:!space-x-reverse ltr:md:flex-row-reverse md:flex-row md:space-y-0">
          <div className="md:w-2/3 w-full">
            <HeroSlider
              data={data}
              isMobile={isMobile}
              template="section"
              colorTheme={colorTheme || ""}
              widgetTitle={data?.title}
              customText="md:text-3xl"
            />
          </div>
          <div
            className={cn(
              "md:w-1/3 w-full max-md:min-h-[350px]",
              !urgentNewsAside && "bg-gray-100"
            )}
          >
            {urgentNewsAside ? (
              <UrgentWidget
                urgentNewsAside={urgentNewsAside}
                template={"sections"}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <HeroSlider
          data={data}
          isMobile={isMobile}
          template="section"
          widgetTitle={data?.title}
          colorTheme={data?.widgetColor || colorTheme}
        />
      )}
    </SectionWrap>
  );
};
