"use client";

import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import { IPulseXConfig } from "@pulsex/types";

export default function useSearchFilter(
  config: IPulseXConfig,
  searchText: string,
  initialData?: any,
  type?: string
) {
  const query: QueryObserverResult<any, Error> = useQuery(
    ["AUTHOR", searchText, type],
    async () => {
      if (!searchText && initialData) {
        return initialData;
      }
      const response = await fetch(
        `https://mdn-search.mangopulse.net/mangopulse/${
          type === "author"
            ? "authors-complete"
            : type === "category" && "category-complete"
        }?text=${encodeURIComponent(searchText)}&id=${config.CLIENT_ID}`
      );

      if (!response.ok) console.log("Failed to fetch filters");

      const data = await response.json();

      return data?.data || [];
    },
    {
      cacheTime: 1000 * 60 * 30,
      retry: 1,
      refetchOnWindowFocus: false,
      initialData,
      enabled: !!type,
    }
  );

  return {
    isLoading: query.isLoading,
    isError: query.isError,
    data: query?.data,
    error: query.error,
  };
}
