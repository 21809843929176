export default function menuItemModel(rawMenu) {
    function getTitle() {
        return rawMenu === null || rawMenu === void 0 ? void 0 : rawMenu.title;
    }
    function getUrl() {
        return rawMenu === null || rawMenu === void 0 ? void 0 : rawMenu.url;
    }
    function getColor() {
        return rawMenu === null || rawMenu === void 0 ? void 0 : rawMenu.icon;
    }
    function getSubmenus() {
        var _a;
        if (rawMenu === null || rawMenu === void 0 ? void 0 : rawMenu.subMenus)
            return (_a = rawMenu === null || rawMenu === void 0 ? void 0 : rawMenu.subMenus) === null || _a === void 0 ? void 0 : _a.map((menuItem) => menuItemModel(menuItem));
    }
    return {
        title: getTitle(),
        url: getUrl(),
        color: getColor(),
        subMenus: getSubmenus(),
    };
}
