import { format } from "date-fns";
import { ar } from "date-fns/locale/ar";

interface Props {
  arabicDate: string;
  needDay?: boolean;
}

export function formatArabicDate({ arabicDate, needDay = false }: Props) {
  const dateWithTimeRegex = /^(\d{4})-(\d{2})-(\d{2})\s(\d{2}):(\d{2})$/;
  const match = arabicDate?.match(dateWithTimeRegex);

  if (match) {
    const year = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1;
    const day = parseInt(match[3], 10);

    const date = new Date(year, month, day);

    const formattedDate = format(date, "dd.MM.yyyy", {
      locale: ar,
    });

    return formattedDate;
  }

  if (
    arabicDate &&
    (arabicDate.includes("today") || arabicDate.includes("اليوم"))
  ) {
    return "اليوم";
  }

  const monthMap: { [key: string]: number } = {
    "كانون الثاني": 1,
    "كانون ثاني": 1,
    شباط: 2,
    آذار: 3,
    اذار: 3,
    أذار: 3,
    نيسان: 4,
    أيار: 5,
    ايار: 5,
    حزيران: 6,
    تموز: 7,
    آب: 8,
    اب: 8,
    أيلول: 9,
    ايلول: 9,
    "تشرين أول": 10,
    "تشرين اول": 10,
    "تشرين الاول": 10,
    "تشرين الأول": 10,
    "تشرين ثاني": 11,
    "تشرين الثاني": 11,
    "كانون أول": 12,
    "كانون الأول": 12,
    "كانون اول": 12,
  };

  const dateRegex =
    /^(?:([\u0600-\u06FF]+)\s+)?(\d{1,2})\s+([\u0600-\u06FF\s]+)\s+(\d{4})$/;
  const matchArabic = arabicDate.match(dateRegex);

  if (!matchArabic) {
    console.log("Invalid date format");
    return arabicDate;
  }

  const dayName = matchArabic[1];
  const dayNumber = parseInt(matchArabic[2], 10);
  const monthName = matchArabic[3].trim();
  const year = parseInt(matchArabic[4], 10);
  const month = monthMap[monthName];

  if (isNaN(dayNumber) || month === undefined) {
    console.log("Invalid day or month in the date.");
    return arabicDate;
  }

  const date = new Date(year, month - 1, dayNumber);
  if (isNaN(date.getTime())) {
    console.log("Invalid date constructed from the components.");
    return arabicDate;
  }

  const customArLebaneseLocale = {
    ...ar,
    localize: {
      ...ar.localize,
      day: (n: number) => {
        const daysArabic = [
          "الأحد",
          "الإثنين",
          "الثلاثاء",
          "الأربعاء",
          "الخميس",
          "الجمعة",
          "السبت",
        ];
        return daysArabic[n];
      },
      month: (n: number) => {
        return (n + 1).toString();
      },
    },
  };

  const formatString = needDay ? "EEEE dd.MM.yyyy" : "dd.MM.yyyy";
  const formattedDate = format(date, formatString, {
    locale: customArLebaneseLocale,
  });

  return dayName
    ? formattedDate
    : formattedDate.replace(/^.*?(\d{1,2}\.\d{1,2}\.\d{4})$/, "$1");
}
