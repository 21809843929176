import { WidgetProps } from "@/types/types";

export const VideoPlayer = ({ data }: WidgetProps) => {
  const videoItem = data?.mainPost?.medias?.find(
    (media: any) => media?.type === "video"
  );
  return videoItem?.embedUrl ? (
    <>
      <div className="relative pt-[56.25%] w-full overflow-hidden bg-greyBackground">
        {(videoItem?.embedUrl && videoItem?.embedUrl.includes("youtube")) ||
        videoItem?.embedUrl.includes("dailymotion") ? (
          <iframe
            src={videoItem?.embedUrl}
            title={data?.mainPost?.title}
            frameBorder="0"
            allowFullScreen
            className="absolute left-0 top-0 w-full h-full"
          />
        ) : (
          ""
        )}
      </div>
    </>
  ) : (
    ""
  );
};
