import { IconProps } from "../types/types";

const WhatsAppIcon = ({ className, size }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 0.75 0.75"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M.477.413a.2.2 0 0 1 .048.022L.524.434A.2.2 0 0 1 .568.46l.001.006v.001a.1.1 0 0 1-.008.037V.503a.07.07 0 0 1-.034.032.1.1 0 0 1-.049.013.3.3 0 0 1-.093-.031l.001.001A.3.3 0 0 1 .304.461a1 1 0 0 1-.07-.087L.233.372A.17.17 0 0 1 .199.278V.274A.1.1 0 0 1 .235.198.04.04 0 0 1 .26.187l.009.001.009.001H.28l.011.003.007.013.016.043.012.034v.002a.05.05 0 0 1-.017.028Q.292.33.292.334l.003.007a.2.2 0 0 0 .049.066.3.3 0 0 0 .071.048l.002.001.011.003q.007 0 .026-.024T.479.411zM.379.67H.38A.3.3 0 0 0 .499.645L.497.646a.3.3 0 0 0 .161-.16L.659.484A.3.3 0 0 0 .658.246l.001.002a.3.3 0 0 0-.16-.161L.497.086a.3.3 0 0 0-.238.001L.261.086A.3.3 0 0 0 .1.246L.099.248a.3.3 0 0 0-.024.118.3.3 0 0 0 .059.179L.133.544.095.657.212.62a.3.3 0 0 0 .166.05zm0-.67H.38q.077 0 .143.03L.521.029a.36.36 0 0 1 .194.192l.001.002a.36.36 0 0 1-.001.286L.716.507a.36.36 0 0 1-.192.194L.522.702a.4.4 0 0 1-.141.029H.38A.36.36 0 0 1 .203.685l.002.001L0 .75.066.554A.36.36 0 0 1 .014.366q0-.077.03-.145L.043.223A.36.36 0 0 1 .235.03L.237.029A.4.4 0 0 1 .378 0z" />
    </svg>
  );
};

export default WhatsAppIcon;
