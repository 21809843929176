"use client";
import { useQuery } from "@tanstack/react-query";
import { getAssets } from "@pulsex/services";
import { Menu } from "@pulsex/models";
export default function useMenus(config) {
    var _a;
    const query = useQuery({
        queryKey: ["GET_ASSETS"],
        queryFn: () => getAssets(config),
        refetchOnWindowFocus: false,
    });
    let menus;
    if (query.isSuccess && query.data) {
        menus = new Menu((_a = query.data) === null || _a === void 0 ? void 0 : _a.menus);
    }
    return {
        isLoading: query.isLoading,
        status: query.status,
        menus,
    };
}
