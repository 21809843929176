"use client";
import React, { useState } from "react";
import { WidgetProps } from "@/types/types";
import { cn, getCleanUrl } from "@pulsex/utils";
import SectionWrap from "@/components/SectionWrap/Index";
import SocialsBox from "@/components/SocialsBox/Index";
import Link from "next/link";
import { locale } from "@/locale";

export const DetailsPDFHeader = ({ data, isMobile }: WidgetProps) => {
  const [error, setError] = useState(false);

  if (
    !data?.mainPost?.medias ||
    data?.mainPost.medias.length === 0 ||
    !data?.mainPost
  ) {
    return null;
  }

  const pdfUrl = data?.mainPost?.medias.filter(
    (item: { type: string; placement: string; url: string }) => {
      const isFileMain = item.type === "file" && item.placement === "main";
      const hasValidUrl = item.url && item.url.endsWith(".pdf");

      return isFileMain && hasValidUrl;
    }
  );

  if (pdfUrl.length === 0) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <SectionWrap className="pt-2 pb-5 min-w-[unset] border-t-0">
          <div className="details-content">
            <div className=" flex flex-col space-y-5 max-md:space-y-2">
              {data?.mainPost?.image ? (
                <div className="max-w-full py-2 max-md:pt-0">
                  <Link target="_blank" href={pdfUrl[0]?.url || ""}>
                    <img
                      alt={data?.mainPost?.title}
                      src={
                        !error
                          ? getCleanUrl(data?.mainPost?.image) +
                            `?width=${800}`
                          : "/svgs/fallback/default1.4.svg"
                      }
                      // width={400}
                      // height={400}
                      onError={() => {
                        if (!error) {
                          setError(true);
                        }
                      }}
                      className="max-w-full mb-2"
                    />
                  </Link>

                  {data?.mainPost?.imageCaption ? (
                    <span className="text-sm text-gray-500">
                      {data?.mainPost?.imageCaption}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="leading-relaxed text-3xl max-md:leading-snug lg:text-5xl lg:flex-1">
                العدد {data?.mainPost?.title}
              </div>
              <div className="leading-relaxed text-2xl">
                لتحميل العدد بكامله،
                <Link
                  target="_blank"
                  href={pdfUrl[0]?.url || ""}
                  className="text-masterRed underline"
                >
                  انقر هنا
                </Link>
              </div>

              {data?.mainPost?.summary ? (
                <p className="mt-4 max-md:mt-0 text-xl max-md:font-amine-light lg:text-2xl">
                  {data?.mainPost?.summary}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="mb-10 pt-6 md:border-b-2 max-md:mb-0 max-md:pt-4 md:hidden">
              <div className="hidden  sm:block  md:block lg:block"></div>
              <div className="flex w-full justify-between border-b-2 border-b-black max-md:border-t-2  max-md:border-y-[#616161] py-2 md:py-5 lg:p-4">
                <div className="block gap-2 sm:hidden  md:hidden lg:hidden">
                  <Link
                    href={
                      data?.mainPost?.author?.id
                        ? `/${data?.mainPost?.author?.id}`
                        : ""
                    }
                    className="cursor-pointer text-xl leading-5 font-amine-light"
                  >
                    {data?.mainPost?.author?.name}
                  </Link>
                  <p className="text-lg font-black max-md:text-base max-md:leading-4">
                    {data?.mainPost?.displayDate}
                  </p>
                </div>
                <div className="flex flex-col  items-center justify-start gap-2 text-start sm:block max-md:flex-row   md:block md:items-start lg:block lg:items-start">
                  <p className="mb-2 max-md:mb-0 rtl:ml-auto ltr:mr-auto flex justify-start text-start text-lg font-bold md:text-xl lg:text-2xl max-md:font-amine-light">
                   {locale.sharePost}
                  </p>
                  <SocialsBox
                    theme="dark"
                    data={data?.mainPost}
                    className={"max-sm:ml-auto"}
                    withCopyBtn={true}
                    isMobile={isMobile}
                  />
                </div>
              </div>
            </div>
          </div>
        </SectionWrap>
      ) : (
        <SectionWrap className="pt-0 pb-5 min-w-[unset] border-t-0">
          <div className="details-content">
            <div className=" flex flex-col space-y-4 max-md:space-y-2">
              {/* <div className="mt-1 max-md:mt-0 leading-relaxed text-3xl max-md:leading-snug lg:text-5xl lg:flex-1">
                جريدة {data?.mainPost?.displayDate}
              </div> */}
              <div className="mt-1 max-md:mt-0 leading-relaxed text-3xl max-md:leading-snug lg:text-5xl lg:flex-1">
                العدد {data?.mainPost?.title}
              </div>
              <div className="leading-relaxed text-2xl">
                لتحميل العدد بكامله،
                <Link
                  target="_blank"
                  href={pdfUrl[0]?.url || ""}
                  className="text-masterRed underline"
                >
                  {" "}
                  انقر هنا{" "}
                </Link>
              </div>
              {data?.mainPost?.summary ? (
                <p className="mt-4 max-md:mt-0 text-xl font-amine-light lg:text-2xl">
                  {data?.mainPost?.summary}
                </p>
              ) : (
                ""
              )}

              {data?.mainPost?.image ? (
                <div className="max-w-full py-2 max-md:pt-0">
                  <Link target="_blank" href={pdfUrl[0]?.url || ""}>
                    <img
                      alt={data?.mainPost?.title}
                      src={
                        !error
                          ? getCleanUrl(data?.mainPost?.image) +
                            `?width=${1000}`
                          : "/svgs/fallback/default1.4.svg"
                      }
                      // width={400}
                      // height={400}
                      onError={() => {
                        if (!error) {
                          setError(true);
                        }
                      }}
                      className="max-w-full mb-2"
                    />
                  </Link>

                  {data?.mainPost?.imageCaption ? (
                    <span className="text-sm text-gray-500">
                      {data?.mainPost?.imageCaption}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </SectionWrap>
      )}
    </>
  );
};
