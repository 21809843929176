"use client";
import { WidgetProps } from "@/types/types";
import { Card } from "@/components/Card/Index";
import { MoreWrap } from "@/ui/MoreWrap/Index";
import SectionWrap from "@/components/SectionWrap/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import DailyPdf from "@/components/DailyPdf/Index";
import { InsideBanner } from "@/components/InsideBanner/Index";
import {
  MEDIUM_CARD_RESOLUTION_FACTOR,
  SMALL_CARD_RESOLUTION_FACTOR,
} from "@/constants";
import { useEffect, useState } from "react";
import { getViewport } from "@/utils/getViewPort";
import { cn } from "@pulsex/utils";

export const ChosenHomeWidget = ({
  data,
  isMobile,
  insideBanners,
  dailyPdf,
}: WidgetProps) => {

 
  const { viewWidth } = getViewport();
  const [deskWidth, setDeskWidth] = useState(0);

  useEffect(() => {
    if (viewWidth && viewWidth > 1520) {
      setDeskWidth(650);
    }
  }, [viewWidth]);
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="relative">
      <AsideTitleColumn data={data} forceTitleBlack={true} />

      <div className="flex flex-col w-full">
        <div className="max-md:flex-col flex">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor}
            className={"md:w-1/2"}
            data={data.posts[0]}
            cropSize="wide"
            resolution={deskWidth > 0 ? 2.2 : MEDIUM_CARD_RESOLUTION_FACTOR}
            cardOrientation="v"
            isMobile={isMobile}
            customSummary="line-clamp-1"
            customTitle="line-clamp-1"
            customMetaFlex={"md:pt-2 lg:pt-4"}
          />
          <div className="max-md:flex-col flex md:w-1/2 md:mr-4 max-md:mt-2">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              className={"md:w-1/2"}
              cropSize="wide"
              data={data.posts[1]}
              resolution={isMobile ? 1.3 : SMALL_CARD_RESOLUTION_FACTOR}
              cardOrientation="v"
              isMobile={isMobile}
              customSummary="line-clamp-3 lg:line-clamp-4"
              customTitle="line-clamp-2 lg:line-clamp-3"
            />

            <DailyPdf
              pdfData={dailyPdf}
              className="md:w-1/2 lg:border-t-0 max-md:w-3/4 max-md:mx-auto max-md:min-h-[430px] md:mr-4 max-md:mt-2"
            />
          </div>
        </div>
        <div className="max-md:flex-col flex md:mt-4 mt-2">
          <Card
            widgetTitle={data?.title}
            widgetColor={data?.widgetColor}
            className={"md:w-1/2 max-md:flex-row"}
            data={data.posts[2]}
            cardOrientation="h"
            isMobile={isMobile}
            cropSize="square"
            resolution={1}
            customSummary="max-lg:line-clamp-2 lg:line-clamp-4"
            customTitle="line-clamp-3 max-md:line-clamp-2"
            customImage="md:min-w-[58%] md:max-w-[58%] lg:min-w-[56%] lg:max-w-[56%]"
          />
          <div className="max-md:flex-col flex md:w-1/2 md:mr-4 max-md:mt-2">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              className={"md:w-1/2"}
              data={data.posts[3]}
              cardOrientation="v"
              isMobile={isMobile}
              cropSize="wide"
              resolution={isMobile ? 1.3 : SMALL_CARD_RESOLUTION_FACTOR}
              customTitle={"md:line-clamp-2 lg:line-clamp-2"}
              customSummary="max-md:block max-lg:hidden line-clamp-2 lg:line-clamp-1 2xl:line-clamp-2"
              showSummary={true}
              customMetaFlex={"lg:pt-4 md:pt-2"}
              // showMediaIcon={true}
            />
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              className={"md:w-1/2 md:mr-4 max-md:mt-2"}
              data={data.posts[4]}
              cardOrientation="h"
              isMobile={isMobile}
              customSummary="line-clamp-3 lg:line-clamp-5"
              customTitle="line-clamp-2 lg:line-clamp-3"
              showImage={false}
            />
          </div>
        </div>
        {data.posts[5] ? (
          <div className="max-md:flex-col flex md:mt-4 mt-2">
            <Card
              widgetTitle={data?.title}
              widgetColor={data?.widgetColor}
              className={"md:w-1/2"}
              data={data.posts[5]}
              cardOrientation="h"
              isMobile={isMobile}
              customSummary="line-clamp-1 lg:block md:hidden lg:line-clamp-1"
              customTitle={
                data.posts[6]?.summary || data.posts[6]?.textContent
                  ? "line-clamp-1"
                  : "line-clamp-2"
              }
              showImage={false}
              customMetaFlex={data.posts[5]?.summary && "pt-3"}
            />

            <div className="max-md:flex-col flex md:w-1/2 md:mr-4 max-md:mt-2">
              {insideBanners &&
                insideBanners?.chosenWidgetInsideBanners &&
                insideBanners?.chosenWidgetInsideBanners.map(
                  (banner: any, index: number) => (
                    <InsideBanner
                      isMobile={isMobile}
                      data={banner}
                      type="medium"
                      className={cn("md:w-1/2", index > 0 && "max-md:mt-2 md:mr-4")}
                      key={index}
                    />
                  )
                )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <MoreWrap moreUrl={data?.moreLink} />
    </SectionWrap>
  );
};
