"use client";
import { CopyIcon } from "@/icons/CopyIcon";
import { cn } from "@pulsex/utils";
import { useEffect, useState } from "react";
import { Toast } from "../Toast/Index";
import { locale } from "@/locale";

const CopyButton = ({ theme = "dark", className, size, shortUrl }: any) => {
  const [isToastVisible, setIsToastVisible] = useState(false);
  useEffect(() => {
    try {
      if (typeof navigator !== "undefined" && navigator.clipboard) {
        console.log("Clipboard API is available.");
      } else {
        console.log("Clipboard API is not available.");
      }
    } catch (error) {
      console.error("Error checking Clipboard API availability:", error);
    }
  }, []);

  const handleCopyToClipboard = () => {
    try {
      const currentUrl = window.location.href;

      if (navigator?.clipboard) {
        navigator?.clipboard
          .writeText(shortUrl || currentUrl)
          .then(() => {
            setIsToastVisible(true);
          })
          .catch((err) => {
            console.error("Failed to copy the URL: ", err);
          });
      } else {
        console.error("Clipboard API not supported.");
      }
    } catch (error) {
      console.error("Error during clipboard operation:", error);
    }
  };

  return (
    <>
      <div
        onClick={handleCopyToClipboard}
        className={cn(
          "flex h-fit w-fit items-center justify-center rounded-full p-1.5 cursor-pointer",
          theme === "dark" ? "bg-black hover:bg-black/90" : "bg-white",
          className
        )}
      >
        <CopyIcon
          size={size ? size : 20}
          className={cn(theme === "dark" ? "fill-white" : "fill-black")}
        />
      </div>
      <Toast
        message={locale.copyMessage}
        isVisible={isToastVisible}
        onHide={() => setIsToastVisible(false)}
      />
    </>
  );
};

export default CopyButton;
