import { postModel } from "@pulsex/models";
import { getLoadMore } from "@pulsex/services";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
export default function useInfiniteScroll(config, id) {
    const [items, setItems] = useState([]);
    const query = useInfiniteQuery(["LOAD_NEXT", id], ({ pageParam = 0 }) => getLoadMore(config, `loadnextpost/${id}/${pageParam}?platform=headless`), {
        enabled: !!id,
        initialData: { pageParams: [undefined], pages: [] },
        retry: 1,
        getNextPageParam: (lastPage, pages) => {
            if ((lastPage === null || lastPage === void 0 ? void 0 : lastPage.widgets) &&
                (lastPage === null || lastPage === void 0 ? void 0 : lastPage.widgets[0]) &&
                !(lastPage === null || lastPage === void 0 ? void 0 : lastPage.widgets[0].posts)) {
                return undefined;
            }
            return pages.length + 1;
        },
        refetchOnWindowFocus: false,
    });
    useEffect(() => {
        if (query.isSuccess && query.data) {
            let updatedItems = [];
            query.data.pages.forEach((responseData) => {
                let mainWidget;
                let relatedWidget;
                if (responseData === null || responseData === void 0 ? void 0 : responseData.widgets) {
                    responseData.widgets.forEach((widgetData) => {
                        var _a;
                        const posts = ((_a = widgetData === null || widgetData === void 0 ? void 0 : widgetData.posts) === null || _a === void 0 ? void 0 : _a.map((post) => postModel(config, post))) ||
                            [];
                        if (posts.length) {
                            if (widgetData.code === "infinite-scroll") {
                                mainWidget = posts[0];
                            }
                            if (widgetData.style === "aside/mostRead") {
                                relatedWidget = posts;
                            }
                        }
                    });
                }
                if (mainWidget || relatedWidget) {
                    updatedItems.push({ mainWidget, relatedWidget });
                }
            });
            // Update state once after processing all data
            setItems((prevItems) => [...prevItems, ...updatedItems]);
        }
    }, [query.isSuccess, query.data]); // Only re-run when query.isSuccess or query.data changes
    return {
        isLoading: query.isLoading,
        data: items,
        fetchNextData: query.fetchNextPage,
        isFetching: query.isFetching,
        isFetchingNextPage: query.isFetchingNextPage,
        hasNextPage: query.hasNextPage,
    };
}
