"use client";
import React, { useState } from "react";
import { WidgetProps } from "@/types/types";
import { cn, getCleanUrl } from "@pulsex/utils";
import SectionWrap from "@/components/SectionWrap/Index";
import SocialsBox from "@/components/SocialsBox/Index";
import Link from "next/link";
import ImageUI from "@/ui/Image/Index";
import { VideoPlayer } from "@/components/VideoPlayer/Index";
import { locale } from "@/locale";

export const DetailsHeader = ({ data, isMobile }: WidgetProps) => {
  const [error, setError] = useState(false);
  const isVideo =
    data?.code.includes("video") || data?.mainPost?.postType === "video"
      ? true
      : false;
  if (!data?.posts) return;
  const dateMeta = data?.mainPost?.metas?.find(
    (media: { type: string; url: string; key?: string; value?: string }) => {
      const isDateKey = media.key === "edition-date" || media.key === "date";
      return isDateKey && media.value;
    }
  );
  const AnimatorMeta = data?.mainPost?.metas?.find(
    (item: { type: string; url: string; key?: string; value?: string }) => {
      const isAnimatorKey = item.key === "animator";
      return isAnimatorKey && item.value;
    }
  );
  const Medias = data?.mainPost?.medias?.find(
    (item: {
      placement: string;
      url: string;
      key?: string;
      value?: string;
    }) => {
      const isMain = item?.placement === "main";
      return isMain && item?.url;
    }
  );

  const dateValue =
    (data?.mainPost?.postType === "newspaper" ||
      data?.mainPost?.postType === "Newspaper Articles") &&
    dateMeta?.value;

  const showMediaImage =
    data?.mainPost?.postType === "opinion-caricature" ||
    data?.mainPost?.postType === "comics";

  return (
    <>
      {isMobile ? (
        <SectionWrap className="pt-2 pb-5 min-w-[unset] border-t-0">
          <div className="details-content">
            <div className="flex flex-col space-y-5 max-md:space-y-2">
              <h1 className="title leading-relaxed text-3xl max-md:leading-snug lg:text-5xl lg:flex-1">
                {data?.mainPost?.title}
              </h1>

              {data?.mainPost?.summary ? (
                <h2 className="mt-4 max-md:mt-0 text-xl max-md:font-amine-light lg:text-2xl">
                  {data?.mainPost?.summary}
                </h2>
              ) : (
                ""
              )}

              {(data?.mainPost?.image || Medias) && !isVideo ? (
                <div className="w-full py-2 max-md:pt-0 image">
                  {showMediaImage && Medias ? (
                    <img
                      alt={
                        data?.mainPost?.imageCaption || data?.mainPost?.title
                      }
                      src={
                        !error
                          ? getCleanUrl(Medias?.url) +
                            `?width=${1000}`
                          : "/svgs/fallback/default2.svg"
                      }
                      width={400}
                      height={400}
                      onError={() => {
                        if (!error) {
                          setError(true);
                        }
                      }}
                      className="max-w-full w-full mb-2"
                    />
                  ) : (
                    <ImageUI
                      data={data?.mainPost}
                      cropSize={"wide"}
                      resolution={3}
                      className="rounded-none w-full"
                    />
                  )}
                  {data?.mainPost?.imageCaption ? (
                    <div className="covercaption">
                      <span className="text-sm text-gray-400">
                        {data?.mainPost?.imageCaption}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {isVideo ? (
                <>
                  <VideoPlayer data={data} />
                  <div className="covercaption">
                    {data?.mainPost?.imageCaption ? (
                      <span className="text-[15px] leading-8 text-gray-400">
                        {data?.mainPost?.imageCaption}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {data?.mainPost?.postType === "opinion-caricature" &&
              AnimatorMeta ? (
                <div className="flex space-x-1  rtl:space-x-reverse   w-fit py-4">
                  <p className="text-xl leading-8 text-black">
                    {AnimatorMeta?.title}
                  </p>
                  <p className="text-xl leading-8 text-black">
                    {AnimatorMeta?.value}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="mb-10 pt-6 md:border-b-2 max-md:mb-0 max-md:pt-4 md:hidden">
              <div className="hidden sm:block md:block lg:block"></div>
              <div
                className={cn(
                  "flex flex-col space-y-2.5",
                  "w-full justify-between  border-b-black max-md:border-t-2",
                  "max-md:border-y-[#616161] py-2 md:py-5 lg:p-4",
                  data?.mainPost?.content && "border-b-2"
                )}
              >
                <ul className="flex ltr:flex-row-reverse justify-between items-center sm:hidden md:hidden lg:hidden">
                  {data?.mainPost?.author?.name ? (
                    <li className="author">
                      <Link
                        href={
                          data?.mainPost?.author?.id
                            ? `/${data?.mainPost?.author?.id}`
                            : ""
                        }
                        className="cursor-pointer text-lg max-md:font-amine-light md:text-2xl lg:text-3xl"
                      >
                        {data?.mainPost?.author?.name}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {!data?.mainPost?.author?.name ? (
                    <li className="author">
                      <p className="cursor-pointer text-lg max-md:font-amine-light md:text-2xl lg:text-3xl">
                        {locale.akhbar}
                      </p>
                    </li>
                  ) : (
                    ""
                  )}
                  {data?.mainPost?.displayDate && !dateValue ? (
                    <li className="date">
                      <p className="text-black text-lg md:text-lg font-amine-light">
                        {data?.mainPost?.displayDate}
                      </p>
                    </li>
                  ) : (
                    ""
                  )}
                  {dateValue ? (
                    <li className="date">
                      <p className="text-black text-lg md:text-lg font-amine-light">
                        {dateValue}
                      </p>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
                <div className="flex flex-col items-center justify-start gap-2 text-start sm:block max-md:flex-row ltr:max-md:flex-row-reverse  md:block md:items-start lg:block lg:items-start">
                  <p className="mb-2 max-md:mb-0 rtl:ml-auto ltr:mr-auto flex justify-between text-start text-2xl font-bold lg:text-2xl max-md:font-amine-light">
                    {locale.sharePost}
                  </p>
                  <SocialsBox
                    theme="dark"
                    data={data?.mainPost}
                    className={"max-md:mr-auto"}
                    withCopyBtn={true}
                    isMobile={isMobile}
                  />
                </div>
              </div>
            </div>
          </div>
        </SectionWrap>
      ) : (
        <SectionWrap className="pt-0 pb-5 min-w-[unset] border-t-0 lg:w-full">
          <div className="details-content w-full">
            <div className="flex flex-col space-y-4 max-md:space-y-2">
              <h1 className="title mt-1 max-md:mt-0 leading-relaxed text-3xl max-md:leading-snug lg:text-5xl lg:flex-1">
                {data?.mainPost?.title}
              </h1>

              {data?.mainPost?.summary ? (
                <h2 className="mt-4 max-md:mt-0 text-xl font-amine-light lg:text-2xl">
                  {data?.mainPost?.summary}
                </h2>
              ) : (
                ""
              )}

              {(data?.mainPost?.image || Medias) && !isVideo ? (
                <div className="max-w-full py-2 max-md:pt-0 image">
                  {showMediaImage && Medias ? (
                    <img
                      alt={
                        data?.mainPost?.imageCaption || data?.mainPost?.title
                      }
                      src={
                        !error
                          ? getCleanUrl(Medias?.url) +
                            `?width=${1000}`
                          : "/svgs/fallback/default2.svg"
                      }
                      width={400}
                      height={400}
                      onError={() => {
                        if (!error) {
                          setError(true);
                        }
                      }}
                      className="max-w-full w-full mb-2"
                    />
                  ) : (
                    <ImageUI
                      data={data?.mainPost}
                      cropSize="wide"
                      resolution={4}
                      className="rounded-none w-full"
                    />
                  )}
                  {data?.mainPost?.imageCaption ? (
                    <div className="covercaption">
                      <span className="text-[15px] leading-8 text-gray-400">
                        {data?.mainPost?.imageCaption}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {data?.mainPost?.postType === "opinion-caricature" &&
                  AnimatorMeta ? (
                    <div className="flex space-x-1  rtl:space-x-reverse   w-fit py-1">
                      <p className="text-xl leading-8 text-black">
                        {AnimatorMeta?.title}
                      </p>
                      <p className="text-xl leading-8 text-black">
                        {AnimatorMeta?.value}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {isVideo ? (
                <>
                  <VideoPlayer data={data} />
                  {data?.mainPost?.imageCaption ? (
                    <span className="text-[15px] leading-8 text-gray-400">
                      {data?.mainPost?.imageCaption}
                    </span>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </SectionWrap>
      )}
    </>
  );
};
