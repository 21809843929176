import { cn } from "@pulsex/utils";
import { TitleSection } from "../Title/Index";
import DailyPdf from "@/components/DailyPdf/Index";

type AsideTitleProps = {
  data: any;
  className?: string;
  subTitle?: boolean;
  pdf?: boolean;
  customPdf?: string;
  hideStaticTitle?: boolean;
  forceTitleBlack?: boolean;
  customTitle?: string;
  template?: string;
  colorTheme?: string;
  dailyPdf?: any;
  showMobileMoreBtn?: any;
};

export const AsideTitleColumn = ({
  data,
  className,
  subTitle = false,
  pdf = false,
  hideStaticTitle = false,
  forceTitleBlack = false,
  customPdf,
  customTitle,
  template,
  colorTheme,
  showMobileMoreBtn = true,
  dailyPdf,
}: AsideTitleProps) => {
  if (!data) return;
  let subMenus: Array<any> | undefined;

  try {
    subMenus = data?.subMenus ? JSON.parse(data?.subMenus) : undefined;
  } catch (error) {
    console.error("Failed to parse subMenus:", error);
    subMenus = undefined;
  }

  return (
    <div
      className={cn(
        "max-md:mt-2 max-md:mb-5 flex w-[188px] min-w-[188px] max-md:w-full flex-col rtl:items-start ltr:items-end justify-start max-lg:mb-3 lg:pt-3",
        showMobileMoreBtn && "max-lg:w-[unset]",
        className
      )}
    >
      {data?.title ? (
        <TitleSection
          title={data?.title}
          moreUrl={data?.moreLink}
          tabsArray={subMenus}
          customTitle={customTitle}
          colorTheme={
            (!forceTitleBlack &&
              (data?.widgetColor || data?.mainPost?.tag?.color)) ||
            colorTheme
          }
          showMobileMoreBtn={showMobileMoreBtn}
        />
      ) : (
        ""
      )}
      {data?.title && pdf ? (
        <>
          {/* {!hideStaticTitle ? (
            <label
              className={cn(
                "text-2xl lg:text-3xl cursor-pointer text-black max-sm:text-2xl font-semibold"
              )}
            >
              عناوين رئيسية
            </label>
          ) : (
            ""
          )} */}

          <DailyPdf
            className={cn("mt-auto w-full max-lg:hidden", customPdf)}
            template={template ? template : "details"}
            customSize={20}
            customTitle={cn(template === "today" && "text-2xl")}
            pdfData={dailyPdf}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};
