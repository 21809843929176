"use client";
import React from "react";
import { WidgetProps } from "@/types/types";
import SectionWrap from "@/components/SectionWrap/Index";
import { Card } from "@/components/Card/Index";
import { AsideTitleColumn } from "@/ui/AsideTitleColumn/Index";
import usePaginate from "@/hooks/usePaginate";
import pulseConfig from "@/pulsex.config";
import { cn } from "@pulsex/utils";
import { AsideSearchColumn } from "@/ui/AsideSearchColumn/Index";
import { DetailsAsideColumn } from "@/widgets/Details/Aside/Index";
import ImageUI from "@/ui/Image/Index";
import Link from "next/link";
import { useLoadMore } from "@pulsex/hooks";
import SpinnerIcon from "@/icons/SpinnerIcon";
import { locale } from "@/locale";

export const AuthorDetailsWidget = ({ data, relatedData }: WidgetProps) => {
  const LoadQ = useLoadMore(
    pulseConfig,
    relatedData?.loadMoreLink,
    relatedData?.posts
  );
  const fetchNext = () => {
    if (LoadQ.hasNextPage && !LoadQ.isFetchingNextPage) LoadQ.fetchNextData();
    else console.log("no more data");
  };
  if (!data?.posts?.length) return;

  return (
    <SectionWrap className="pt-4 pb-14 items-stretch md:flex ltr:md:flex-row-reverse">
      <div className="md:min-w-[200px]">
        <div className=" flex flex-col ltr:items-center space-y-4 md:pt-10 w-full max-md:mt-4">
          <Link
            href={data?.mainPost?.url || " "}
            aria-label={data?.mainPost?.author?.name}
            className={cn(
              "overflow-hidden box-content",
              "h-[180px] w-[180px] md:h-[140px] md:w-[140px] max-md:mx-auto",
              "rounded-full border-[3px] border-minorTheme/80"
            )}
          >
            <ImageUI
              data={data?.mainPost}
              cropSize={"square"}
              className="rounded-none w-full"
            />
          </Link>
          <Link
            aria-label={data?.mainPost?.title}
            href={data?.mainPost?.url || " "}
            className={cn(
              "cursor-pointer line-clamp-2 relative max-sm:my-4 max-md:mx-auto",
              "text-minorTheme text-4xl md:text-4xl font-amine-light leading-7"
            )}
          >
            {data?.mainPost?.title}
          </Link>
        </div>
      </div>

      <div className="flex flex-col ltr:items-end max-md:py-6 w-full">
        {data?.title ? (
          <div
            className={cn(
              " w-fit text-4xl text-[#242424]",
              "font-semibold pb-2 border-b-2 border-masterRed mb-8 mt-4"
            )}
          >
            {data?.title}
          </div>
        ) : (
          ""
        )}
        <div
          className="grid grid-cols-2 lg:grid-cols-4 w-full"
          dir={pulseConfig.LANGUAGE === "ar" ? "rtl" : "ltr"}
        >
          {LoadQ?.data &&
            LoadQ?.data?.map((item: any, index: any) => (
              <Card
                widgetColor={data?.widgetColor}
                className={cn(
                  "mr-4 max-md:mr-3 max-md:mb-3 mb-4",

                )}
                cardOrientation="v"
                data={item}
                customSummary="line-clamp-5"
                cropSize="wide"
                key={index}
              />
            ))}
          {LoadQ?.isLoading
            ? Array.from({ length: 8 }).map((_, index) => (
                <div
                  key={index}
                  className="h-[253px] w-full bg-greyBackground animate-pulse"
                />
              ))
            : ""}
        </div>
        {LoadQ.hasNextPage && (
          <div className="pb-6 pt-10 w-full">
            <div
              className={cn(
                "flex space-x-2.5 w-fit cursor-pointer mx-auto",
                "text-lg text-minorTheme px-3 py-2",
                "border border-minorTheme",
                LoadQ?.isFetching
                  ? "border-transparent cursor-auto"
                  : "lg:hover:border-masterRed lg:hover:text-masterRed"
              )}
              onClick={() => fetchNext()}
            >
              {LoadQ?.isFetching ? (
                <SpinnerIcon className={"fill-masterRed"} />
              ) : (
                locale.showMore
              )}
            </div>
          </div>
        )}
        {!LoadQ.isLoading && !LoadQ.hasNextPage && relatedData?.posts && (
          <div className="pb-6 pt-10 w-full">
            <div
              className={cn(
                "flex space-x-2.5 w-fit cursor-pointer mx-auto",
                "text-lg text-minorTheme px-3 py-2"
              )}
            >
              {locale.noMore}
            </div>
          </div>
        )}
        {!LoadQ.isLoading && !LoadQ.hasNextPage && !relatedData?.posts && (
          <div className="pb-6 pt-10 w-full">
            <div
              className={cn(
                "flex space-x-2.5 w-fit mx-auto",
                "text-3xl text-black px-3 py-2"
              )}
            >
              {locale.noAuthorData}
            </div>
          </div>
        )}
      </div>
    </SectionWrap>
  );
};
