"use client";
import { locale } from "@/locale";
import { useRouter, useSearchParams } from "next/navigation";
import React, { useEffect, useRef, useState } from "react";
import DynamicSelect from "./dynamicSelect/Index";
import pulseConfig from "@/pulsex.config";
interface SearchInputProps {
  className?: string;
  setIsLoading?: any;
  setData?: any;
  newspaper?: boolean;
  withFallbackDate?: boolean;
}
const SearchInput = ({
  className,
  setIsLoading,
  setData,
  newspaper = false,
  withFallbackDate = true,
}: SearchInputProps) => {
  const [selectedCategory, setSelectedCategory] = useState<
    string | undefined
  >();
  const [selectedAuthor, setSelectedAuthor] = useState<string | undefined>();

  const searchInputRef = useRef<HTMLInputElement>(null);
  const dateFromInputRef = useRef<HTMLInputElement>(null);
  const dateToInputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();

  const currentDate = new Date();
  const startFallbackDate = new Date();
  startFallbackDate.setDate(currentDate.getDate() - 10);

  const fromFallbackDate = startFallbackDate.toISOString().split("T")[0];
  const toFallbackDate = currentDate.toISOString().split("T")[0];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const searchQuery = searchInputRef.current?.value?.trim() || "";
    const dateFrom = dateFromInputRef.current?.value?.trim();
    const dateTo = dateToInputRef.current?.value?.trim();

    if (!newspaper && (!searchQuery || searchQuery.length <= 2)) {
      console.log("Please enter a search term with more than 2 characters.");
      return;
    }

    let query = newspaper
      ? `/newspaper?`
      : `${newspaper ? `newspaper` : `search`}/${
          searchQuery ? `?q=${encodeURIComponent(searchQuery)}` : undefined
        }`;

    query += `&From=${
      dateFrom
        ? encodeURIComponent(dateFrom)
        : withFallbackDate
        ? fromFallbackDate
        : undefined
    }`;
    query += `&To=${
      dateTo
        ? encodeURIComponent(dateTo)
        : withFallbackDate
        ? toFallbackDate
        : undefined
    }`;

    !newspaper && selectedAuthor
      ? (query += `&Author=${selectedAuthor}`)
      : undefined;

    !newspaper && selectedCategory
      ? (query += `&Category=${selectedCategory}`)
      : undefined;

    router.push(query);
  };

  const searchParams = useSearchParams();
  const searchDateFrom = searchParams.get("From");
  const searchDateTo = searchParams.get("To");
  const searchQuery = searchParams.get("q");

  const [relatedData, setRelatedData] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://mdn-search.mangopulse.net/mangopulse/filters?id=${pulseConfig.CLIENT_ID}`
        );

        if (!response.ok) console.log("Failed to fetch initial filters");

        const result = await response.json();
        if (result?.data) setRelatedData(result?.data);
      } catch (error) {
        console.log("elastic related error", error);
      }
    };

    fetchData();
  }, []);

  const handleCategorySelect = (option: string) => {
    setSelectedCategory(option);
  };
  const handleAuthorSelect = (option: string) => {
    setSelectedAuthor(option);
  };

  return (
    <>
      <div className="mt-5 w-full">
        <div className="bg-[#221f20] px-4 py-2 text-2xl text-white ltr:text-left">
          {locale.advancedSearch}
        </div>
        <div className="bg-[#f0f0eb] px-3 py-3">
          <form onSubmit={handleSubmit} className="space-y-2">
            {!newspaper ? (
              <div className="flex flex-col space-y-1 w-full max-w-full">
                <label htmlFor="" className="block text-xl ltr:text-left">
                  {locale.searchKeyword}
                </label>
                <input
                  type="text"
                  id="tags"
                  ref={searchInputRef}
                  className="h-[38px] px-2 w-full bg-white ltr:text-left"
                  placeholder=""
                  required
                  defaultValue={searchQuery || undefined}
                  // defaultValue={""}
                  // onChange={handleInputChange}
                />
              </div>
            ) : (
              ""
            )}
            <div className="flex flex-col max-w-full space-y-3 max-md:space-y-0 max-md:flex-row">
              <div className="flex flex-col space-y-1 w-full max-w-full max-md:w-1/2">
                <label htmlFor="from" className="text-xl">
                  {locale.dateFrom}
                </label>
                <input
                  type="date"
                  name="from"
                  id="from"
                  ref={dateFromInputRef}
                  placeholder={locale.chooseDate}
                  className="max-md:min-w-[85%] h-[38px] px-2 w-full max-w-full bg-white"
                  defaultValue={searchDateFrom || undefined}
                />
              </div>
              <div className="flex flex-col space-y-1 w-full max-w-full max-md:w-1/2 max-md:mr-3">
                <label htmlFor="to" className="text-xl ltr:text-left">
                  {locale.dateTo}
                </label>
                <input
                  type="date"
                  name="to"
                  id="to"
                  ref={dateToInputRef}
                  placeholder={locale.chooseDate}
                  className="max-md:min-w-[85%] h-[38px] px-2 w-full max-w-full bg-white"
                  defaultValue={searchDateTo || undefined}
                />
              </div>
            </div>
            {!newspaper ? (
              <div className="flex flex-col max-w-full space-y-3 max-md:space-y-0 max-md:flex-row">
                <div className="flex flex-col space-y-1 w-full max-w-full max-md:w-1/2 relative">
                  <label htmlFor="" className="block text-xl">
                    إسم الكاتب
                  </label>
                  <DynamicSelect
                    type={"author"}
                    className=""
                    initialData={relatedData?.author}
                    onSelect={handleAuthorSelect}
                  />
                </div>

                <div className="flex flex-col space-y-1 w-full max-w-full max-md:w-1/2 max-md:mr-3 relative">
                  <label htmlFor="" className="block text-xl">
                    إبحث في القسم
                  </label>
                  <DynamicSelect
                    type={"category"}
                    initialData={relatedData?.category}
                    onSelect={handleCategorySelect}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <button
              onClick={() => handleSubmit}
              type="submit"
              className="!mt-5 w-full bg-[#c80f1e] px-8 py-1 text-center text-xl text-white"
            >
              {locale.search}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default SearchInput;
